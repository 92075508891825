import packageJSON from '../package.json';

const config = {
  VERSION_FRONT: packageJSON?.version,
  ENV: process.env.REACT_APP_ENV || 'LOCAL',
  BFF_HOST: process.env.REACT_APP_HOST_BFF_ADMIN || 'http://localhost:3106',
  BFF_WS_HOST: process.env.REACT_APP_HOST_BFF_EVENTS || 'http://localhost:3333',
  BFF_TOKEN:
    process.env.REACT_APP_BFF_TOKEN ||
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBLZXkiOiIxIiwiYXBwTmFtZSI6ImZyb250LWFkbWluIiwiYXBwVmVyc2lvbiI6InYxIiwiaWF0IjoxNzAxOTUzMjIxfQ.CvFORMZzRlTpjLYIrYba3EfSBzQ-2DRZQcBva6LqJH4',
};

export default config;
