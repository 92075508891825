export const searchRender = ({ list, paging }) => {
  const formatedList = list.map(({ credentials, ...rest }) => ({
    username: credentials.find(({ type }) => type === 'EMAIL').value,
    ...rest,
  }));

  return {
    list: formatedList,
    paging,
  };
};
