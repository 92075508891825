import { Form, requestGQL } from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { DatePicker } from '@gimlite/watermelon/components/datepicker/datepicker.component';
import { FilterExpanded } from '@gimlite/watermelon/components/filter/filter.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parkingRightsGql } from '../gql/parkingRights.gql';
import { searchRender } from '../projection/parkingRights.render';

export const ParkingRightsList = () => {
  const navigate = useNavigate();
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();
  const [search, setSearch] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: {
            limit: 20,
            ...getParamsUrl,
          },
          gql: parkingRightsGql,
          render: (res) => searchRender(res),
        });

        setSearch(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl]);

  return (
    <Page>
      <Widget config={{ title: 'Liste des droits de stationnement' }}>
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'plate',
                    label: 'Plaque',
                  }}
                >
                  <Input data={{ defaultValue: getParamsUrl?.plate }} />
                </Form.Item>
                <Form.Item
                  config={{
                    name: 'beginEnd',
                    label: 'Début du stationnement',
                  }}
                >
                  <DatePicker
                    config={{
                      mode: 'range',
                      format: 'datetime',
                    }}
                    data={{ defaultValue: getParamsUrl?.beginEnd }}
                  />
                </Form.Item>
              </FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Rechercher', type: { value: 'submit' } }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>
          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(`/onstreet/parking-rights/${value._id}`);
                },
              }}
              data={search as any}
              config={{
                columns: [
                  {
                    title: 'State',
                    key: 'state',
                  },
                  {
                    title: 'Plaque',
                    key: 'plate',
                  },
                  {
                    title: 'Montant',
                    key: 'amount',
                  },
                  {
                    title: 'Date de début',
                    key: 'startDate',
                  },
                  {
                    title: 'Date de fin',
                    key: 'endDate',
                  },
                  {
                    title: 'Durée',
                    key: 'calculatedDuration',
                  },
                  {
                    title: 'Ups',
                    key: 'ups',
                  },
                  {
                    title: 'Consumer',
                    key: 'consumer',
                  },
                  {
                    title: 'Error code',
                    key: 'errorCode',
                  },
                  {
                    title: 'Error message',
                    key: 'errorMessage',
                  },
                ],
                actions: {
                  read: true,
                },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
