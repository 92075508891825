import { gql } from '@apollo/client';

export const contractsGql = gql`
  query paymentContracts($page: Int, $limit: Int, $order: String) {
    paymentContracts(page: $page, limit: $limit, order: $order) {
      list {
        _id
        consumer
        tag
        psp
        updatedAt
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
