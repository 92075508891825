import { gql } from '@apollo/client';
import { feedbacksEntity } from '../entity/feedbacks.gql';

export const siteFeedbacksGql = gql`
  query site($siteId: String!, $startDate: String, $stopDate: String) {
    site(siteId: $siteId) {
      _id
      ${feedbacksEntity}
    }
  }
`;
