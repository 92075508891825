import { wizardService } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useSelector } from '@xstate/react';
import { createClientService } from './createClient.machine';
import { ClientStep } from './steps/client.step';

export type CreateClientWizardContext = any;
export const CreateClientWizard = () => {
  const result = useSelector(
    createClientService,
    ({ context }: any) => context.result,
  );

  return (
    <Wizard<CreateClientWizardContext>
      config={{
        title: '',
        withValidation: {
          state: result,
          success: 'Ca marche nickel !',
          error: 'Ca marche pas !',
        },
      }}
      data={{
        defaultContext: undefined,
      }}
      handleEvent={{
        done: async (value: any) => {
          createClientService.send('CREATE_CLIENT', { payload: value });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item config={{ title: 'Client', component: ClientStep }} />
    </Wizard>
  );
};
