import { Empty, Pie } from '@gimlite/watermelon';

const feedbackSort: Array<keyof FeedbackType.Point> = [
  'good',
  'average',
  'bad',
];

export declare namespace FeedbackType {
  type Props = {
    data: FeedbackType.Point;
  };

  type Point = {
    good: number;
    average: number;
    bad: number;
  };
}

export const FeedbackChart = ({ data }: FeedbackType.Props) => {
  if (!data)
    return (
      <Empty
        config={{
          mode: {
            name: 'noData',
          },
        }}
      ></Empty>
    );

  const sortedData = feedbackSort.reduce((acc, key) => {
    acc[key] = data[key];
    return acc;
  }, {} as FeedbackType.Point);

  return (
    <Pie
      options={{
        responsive: true,
        plugins: {
          tooltip: { position: 'nearest' },
        },
      }}
      data={{
        labels: Object.keys(sortedData),
        datasets: [
          {
            data: Object.values(sortedData),
          },
        ],
      }}
    ></Pie>
  );
};
