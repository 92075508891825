import { DateTime } from 'luxon';

export const searchRender = ({ list, paging }) => {
  return {
    list: list.map(({ to, createdAt, ...rest }) => ({
      ...rest,
      to: to.toString().replaceAll(',', ' / '),
      diff: DateTime.fromISO(createdAt).toRelative(),
    })),
    paging,
  };
};
