import { gql } from '@apollo/client';

export const readOperatorGql = gql`
  query operator($operatorId: String!) {
    operator(operatorId: $operatorId) {
      _id
      operatorCode
      operatorContractCount
      name
      email
      phone
      code
      display {
        logoMain
        logoSecondary
      }
      translation
    }
  }
`;
