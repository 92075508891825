import { useRef } from 'react';
import VanillaTilt from 'vanilla-tilt';
import React from 'react';

export declare namespace Card3DType {
  type Props = {
    config: {
      options: Record<string, string | number | boolean>;
    };
    children: React.ReactNode;
  };
}

export const Card3D = ({ config, children }: Card3DType.Props) => {
  const { options } = config;
  const tilt = useRef<(HTMLDivElement & { vanillaTilt: any }) | null>(null);

  const init = () => {
    if (tilt.current && !tilt.current?.vanillaTilt) {
      VanillaTilt.init(tilt.current, options);
    }
  };

  return (
    <div onMouseOver={() => init()} ref={tilt}>
      {children}
    </div>
  );
};
