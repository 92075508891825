import { gql } from '@apollo/client';

export type CreateCameraResponse = {
  camera: {
    _id: string;
    code: string;
    position: string;
    ups: string;
    description: string | null;
  };
};

export type CreateCameraParams = {
  input: {
    ups: string;
    code: string;
    position: string;
    description: string | null;
  };
};

export const createCameraGql = gql`
  mutation createCamera($input: CreateCameraDto!) {
    createCamera(input: $input) {
      camera {
        _id
        code
        position
        ups
        description
      }
    }
  }
`;
