import { requestGQL, Space, Table, useMyUrl } from '@gimlite/watermelon';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { siteFragmentsGql } from '../../gql/tab/fragments.gql';
import { readFragmentsRender } from '../../projection/fragments.render';
import { siteDetailsDefaultParams, SitesDetailsFilter } from '../sites.details';
import {
  siteFragmentsDebugPaging,
  siteFragmentsPaging,
} from '../../gql/entity/fragments.gql';

export const SitesDetailsFragment = () => {
  const [readFragment, setReadFragment] = useState<any>();
  const [readDebugFragment, setReadDebugFragment] = useState<any>();
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const { siteId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { ...siteDetailsDefaultParams, ...getParamsUrl, siteId },
          gql: siteFragmentsPaging,
          render: (res) => readFragmentsRender(res),
        });

        setReadFragment(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, siteDetailsDefaultParams, siteId]);

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: {
            ...siteDetailsDefaultParams,
            ...getParamsUrl,
            siteId,
            type: 'dbg',
          },
          gql: siteFragmentsDebugPaging,
          render: (res) => readFragmentsRender(res),
        });

        console.log({ response });

        setReadDebugFragment(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, siteDetailsDefaultParams, siteId]);

  return (
    <Col>
      <SitesDetailsFilter />
      <Space config={{ count: 2 }} />

      <Widget config={{ title: 'Fragments' }}>
        <Table<any>
          handleEvent={{
            paging: (value: any) => {
              const { page: fragmentsPage, limit: fragmentsLimit } = value;
              setParamsUrl({
                ...getParamsUrl,
                fragmentsPage,
                fragmentsLimit,
              });
            },
          }}
          data={readFragment?.fragments}
          config={{
            columns: [
              {
                title: 'Bucket',
                key: 'bucketId',
              },
              {
                title: 'Ref.',
                key: 'fragmentRef',
              },
              {
                title: 'Date',
                key: 'logDate',
              },
              {
                title: 'type',
                key: 'type',
              },
              {
                title: 'state',
                key: 'state',
              },
            ],
          }}
        />
      </Widget>
      <Space config={{ count: 2 }} />

      <Widget config={{ title: 'Debug' }}>
        <Table<any>
          handleEvent={{
            paging: (value: any) => {
              const { page: fragmentsDebugPage, limit: fragmentsDebugLimit } =
                value;
              setParamsUrl({
                ...getParamsUrl,
                fragmentsDebugPage,
                fragmentsDebugLimit,
              });
            },
          }}
          data={readDebugFragment?.fragments}
          config={{
            columns: [
              {
                title: 'bucketId',
                key: 'bucketId',
              },
              {
                title: 'Ref.',
                key: 'fragmentRef',
              },
              {
                title: 'logDate',
                key: 'logDate',
              },
              {
                title: 'type',
                key: 'type',
              },
              {
                title: 'state',
                key: 'state',
              },
            ],
          }}
        />
      </Widget>
    </Col>
  );
};
