import { gql } from '@apollo/client';

export const clientGql = gql`
  query client($clientId: String!, $recordsPage: Int, $recordsLimit: Int) {
    client(clientId: $clientId) {
      _id
      slug
      siret
      ups {
        list {
          _id
          code
          config {
            yoonite {
              maximumAmount
              maximumDuration
            }
          }
        }
        paging {
          current
          count
          limit
        }
      }
      paymentRecords(page: $recordsPage, limit: $recordsLimit) {
        list {
          _id
          consumer
          segment
          type
          subtype
          amount
          currency
          paymentDate
          externalRef
          internalRef
        }
      }
    }
  }
`;
