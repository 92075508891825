import { requestGQL, wizardService } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useState } from 'react';
import { useSelector } from '@xstate/react';
import {
  UpdateCameraParams,
  UpdateCameraResponse,
  updateCameraGql,
} from './gql/updateCamera.gql';
import { UpsEntity } from './gql/upsList.gql.js';
import { CameraStep } from './steps/camera.step';
import {
  UpdateCameraServiceContextType,
  updateCameraService,
} from './updateCamera.machine';

export type UpdateCameraWizardContext = {
  camera: {
    _id: string | null;
    code: string | null;
    position: 'inside' | 'outside';
    ups: string | null;
    upsCode: string | null;
    description: string | null;
  };
  listParking: Array<{
    code: UpsEntity['code'];
    _id: UpsEntity['_id'];
  }>;
  selectParking: {
    _id: string | null;
  };
};

export const UpdateCameraWizard = () => {
  const [result, setResult] = useState<boolean>();

  const { camera } = useSelector(
    updateCameraService,
    ({ context }: { context: UpdateCameraServiceContextType }) => context,
  );

  return (
    <Wizard<UpdateCameraWizardContext>
      config={{
        title: 'Modifier une caméra',
        withValidation: {
          state: result,
          success: 'Ca marche nickel !',
          error: 'Ca marche pas !',
        },
      }}
      data={{
        defaultContext: {
          camera: {
            ...camera,
            ups: camera.upsCode,
          },
          listParking: [
            {
              code: camera.upsCode!,
              _id: camera.ups!,
            },
          ],
          selectParking: {
            _id: camera.ups,
          },
        },
      }}
      handleEvent={{
        done: async ({ camera, selectParking }) => {
          try {
            (await requestGQL({
              params: {
                input: {
                  cameraId: camera._id,
                  code: camera.code!,
                  description: camera.description!,
                  position: camera.position,
                  ups: selectParking._id,
                },
              } as UpdateCameraParams,
              gql: updateCameraGql,
            })) as UpdateCameraResponse;

            setResult(() => true);
          } catch (error) {
            setResult(() => false);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item config={{ title: 'Caméra', component: CameraStep }} />
    </Wizard>
  );
};
