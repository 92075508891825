import {
  DeleteOutlined,
  LinkOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Upload as UploadAnt } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { Size } from '../../types/component.type';
import { FormItemType } from '../form/form.component';
import { Popover } from '../popover/popover.component';
import { Write } from '../write/write.component';
import './upload.component.scss';
import { useTranslation } from '../../hook/useTranslation.hook';

class Static {
  static fileToBase64 = async (file: UploadFile): Promise<string> => {
    const value = file.originFileObj
      ? await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj as RcFile);
          reader.onload = () => resolve(reader.result as string);
        })
      : file.url;

    return value as string;
  };
}

export declare namespace UploadType {
  type Props = {
    className?: string;
    data?: {
      value?: UploadType.Data.Value;
      defaultValue?: UploadType.Data.Value;
    };
    config?: {
      maxFileUpload?: number;
      acceptFileType?: string;
      height?: Extract<
        Size,
        'initial' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'full'
      >;
      width?: Extract<
        Size,
        'initial' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'full'
      >;
      disabled?: boolean;
    };
    handleEvent?: {
      upload?: (value: [string, string][]) => void;
    };
  };

  namespace Data {
    type Value = string | string[];
  }
}

export const Upload = ({
  data: { defaultValue = [] } = {},
  handleEvent: { upload = undefined } = {},
  config: {
    width = 'medium',
    height = 'medium',
    maxFileUpload = 1,
    acceptFileType = '*',
    disabled = false,
  } = {},
  className = '',
  onChange: parentOnChange,
  ...formItem
}: FormItemType.Legacy<UploadType.Props>) => {
  const { t, lang } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const removeFile = useCallback(
    (uidToRemove: string) => {
      setFileList((current: UploadFile[]) =>
        current.filter(({ uid }: UploadFile) => uid !== uidToRemove),
      );
    },
    [fileList],
  );

  useEffect(() => {
    if (defaultValue) {
      const defaultValueFormatted = Array.isArray(defaultValue)
        ? defaultValue
        : [defaultValue];

      const list: UploadFile[] = defaultValueFormatted.map((url) => {
        const name = url.split('/').pop() ?? t('watermelon-noFileName');

        return {
          uid: uuidv4(),
          name,
          status: 'done',
          url,
        };
      });

      setFileList(() => list);
    }
  }, [defaultValue, lang]);

  useEffect(() => {
    const updateFileSend = async () => {
      const fileListPromise = await Promise.all(
        fileList.map(
          async (file: UploadFile): Promise<[string, string]> => [
            file.name,
            await Static.fileToBase64(file),
          ],
        ),
      );

      parentOnChange?.(fileListPromise);
      upload?.(fileListPromise);
    };

    updateFileSend();
  }, [fileList]);

  return (
    <div className="upload">
      <UploadAnt
        customRequest={(data) => {
          if (data.onSuccess) data.onSuccess('');
        }}
        {...formItem}
        className={`
          upload__item 
          formItemTargetWidth--${width}
          upload__item--width--${width}
          upload__item--height--${height}
          ${className}`}
        accept={acceptFileType}
        listType={'text'}
        fileList={fileList}
        maxCount={maxFileUpload}
        multiple={true}
        itemRender={() => <></>}
        onChange={async ({
          file,
          fileList,
        }: {
          file: UploadFile;
          fileList: UploadFile[];
        }) => {
          setFileList(fileList);
        }}
        disabled={disabled}
      >
        <button type="button" className="upload__item__button">
          <span className="upload__item__button__icon">
            {<UploadOutlined />}
          </span>
          <span className="upload__item__button__text">Click to Upload</span>
        </button>
      </UploadAnt>
      {fileList && Array.isArray(fileList) && fileList.length > 0 && (
        <Popover
          config={{ placement: 'bottomRight' }}
          data={
            <div className="upload__list">
              {fileList.map(
                ({ uid, name, size, ...rst }: UploadFile, index: number) => (
                  <div
                    onClick={(e) => {
                      console.log('futur preview');
                    }}
                    key={index}
                    className="upload__list__item"
                  >
                    <div className="upload__list__item__file">
                      <div className="upload__list__item__file__icon">
                        <LinkOutlined />
                      </div>
                      <span className="upload__list__item__file__value">
                        {name} {size && `(${(size / 1000).toFixed(2)} ko)`}
                      </span>
                    </div>
                    {!disabled && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          removeFile(uid);
                        }}
                        className="upload__list__item__delete"
                      >
                        {<DeleteOutlined />}
                      </span>
                    )}
                  </div>
                ),
              )}
            </div>
          }
        >
          <Write
            data={{
              item: `${t('watermelon-files')} (${fileList.length})`,
            }}
            config={{
              mode: 'key-small-light',
            }}
          />
        </Popover>
      )}
    </div>
  );
};
