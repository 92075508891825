import { wizardService } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useSelector } from '@xstate/react';
import { useEffect } from 'react';
import { createOperatorService } from './createOperator.machine';
import { OperatorStep } from './steps/operator.step';

export type CreateOperatorWizardContext = any;
export const CreateOperatorWizard = () => {
  // const [terminate, setTerminate] = useState<boolean>();

  const result = useSelector(
    createOperatorService,
    ({ context }: any) => context.result,
  );

  useEffect(() => {
    if (result) {
      console.log('RESULT', { result });
    }
  }, [result]);

  return (
    <Wizard<CreateOperatorWizardContext>
      config={{
        title: '',
        withValidation: {
          state: result,
          success: 'Ca marche nickel !',
          error: 'Ca marche pas !',
        },
      }}
      data={{
        defaultContext: undefined,
      }}
      handleEvent={{
        done: async ({ name, logoMain, logoSecondary, ...rest }: any) => {
          const payload = {
            ...rest,
            name,
            translation: [
              {
                lang: 'fr_FR',
                name,
              },
              {
                lang: 'en_EN',
                name,
              },
              {
                lang: 'it_IT',
                name,
              },
              {
                lang: 'de_DE',
                name,
              },
            ],
            display: {
              logoMain,
              logoSecondary,
            },
          };

          createOperatorService.send('CREATE_OPERATOR', {
            payload,
          });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item config={{ title: 'Opérateur', component: OperatorStep }} />
    </Wizard>
  );
};
