import { Form, requestGQL, wizardService } from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { FilterExpanded } from '@gimlite/watermelon/components/filter/filter.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clientsGql } from '../gql/clients.gql';

export const ClientsSearch = () => {
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();
  const [search, setSearch] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { limit: 15, ...getParamsUrl },
          gql: clientsGql,
          render: (res) => res,
        });

        setSearch(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl]);

  return (
    <Page>
      <Widget config={{ title: 'Liste des clients' }}>
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          {/* FILTERS */}
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'slug',
                    label: 'Nom du client',
                  }}
                >
                  <Input data={{ defaultValue: getParamsUrl?.name }} />
                </Form.Item>
              </FilterExpanded.Fields>

              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Rechercher', type: { value: 'submit' } }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
                <Button
                  config={{
                    text: 'Créer un client',
                    type: { value: 'button' },
                  }}
                  handleEvent={{
                    click: () =>
                      wizardService.send('SHOW_WIZARD', { id: 'createClient' }),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          {/* TABLE */}
          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(`/onstreet/clients/${value._id}/global`);
                },
              }}
              data={search}
              config={{
                columns: [
                  {
                    title: 'Name',
                    key: 'slug',
                  },
                  {
                    title: 'Siret',
                    key: 'siret',
                  },
                ],
                actions: { read: true },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
