import { Form, requestGQL } from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { FilterExpanded } from '@gimlite/watermelon/components/filter/filter.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { mailsGql } from '../gql/mails.gql';
import { searchRender } from '../projection/mails.render';

export const MailsSearch = () => {
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();

  const [search, setSearch] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { limit: 15, ...getParamsUrl },
          gql: mailsGql,
          render: (res) => searchRender(res),
        });

        setSearch(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl]);

  return (
    <Page>
      <Widget config={{ title: 'Liste des mails' }}>
        <Zone
          config={{
            zones: [['filter'], ['search']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'state',
                    label: 'State',
                  }}
                >
                  <Input
                    data={{ defaultValue: getParamsUrl?.state }}
                    config={{ width: 'large' }}
                  />
                </Form.Item>
                <Form.Item config={{ name: 'from', label: 'From' }}>
                  <Input
                    data={{ defaultValue: getParamsUrl?.from }}
                    config={{ width: 'large' }}
                  />
                </Form.Item>
                <Form.Item config={{ name: 'to', label: 'To' }}>
                  <Input
                    data={{ defaultValue: getParamsUrl?.to }}
                    config={{ width: 'large' }}
                  />
                </Form.Item>
              </FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Rechercher', type: { value: 'submit' } }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>
          <Zone.Area config={{ area: 'search' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
              }}
              data={search}
              config={{
                pagination: 'click',
                columns: [
                  {
                    title: 'State',
                    key: 'state',
                  },
                  {
                    title: 'From',
                    key: 'from',
                  },
                  {
                    title: 'To',
                    key: 'to',
                  },
                  {
                    title: 'System',
                    key: 'system',
                  },
                  {
                    title: 'Processed At',
                    key: 'diff',
                  },
                ],
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
