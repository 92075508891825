import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Select } from '@gimlite/watermelon/components/select/select.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import {
  CreatePrestoScanWizardContext,
  spaceBeetwenInput,
  spaceBeetwenTitle,
} from '../createPrestoScan.wizard';
import './general.scss';

export const FpsStep = ({
  context: { FPS },
  validate,
  submit,
}: WizardItemType.Config.Component<CreatePrestoScanWizardContext>) => {
  const { lang } = useTranslation();

  return (
    <Form
      data={{ defaultValue: FPS }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value) => {
          submit({ FPS: value });
        },
        validate: (value) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Write
          data={{ item: "Identité l'organisme de recours" }}
          config={{
            mode: 'title-small',
          }}
        ></Write>

        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'notificationAuthority',
              label: 'Type',
              rules: [{ required: true }],
            }}
          >
            <Select
              data={{
                items: [{ value: 'LOCAL', label: 'LOCAL' }],
              }}
              config={{
                height: 'xlarge',
                width: 'small',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'name',
              label: 'Nom',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                height: 'xlarge',
                width: 'full',
                placeholder: '',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'organizationId',
              label: 'Identifiant',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                height: 'xlarge',
                width: 'large',
              }}
            />
          </Form.Item>
        </Row>
        <Space config={{ count: 1, way: 'vertical' }}></Space>

        <Write
          data={{ item: "Adresse de l'organisme de recours" }}
          config={{
            mode: 'title-small',
          }}
        ></Write>

        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'streetNumber',
              label: 'N° de voie',
            }}
          >
            <Input
              config={{
                type: { name: 'number', min: 1 },
                height: 'xlarge',
                width: 'xsmall',
                placeholder: '',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'streetNumberBis',
              label: 'N° de voie bis',
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'xsmall',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'streetType',
              label: 'Type de rue',
            }}
          >
            <Input
              config={{
                height: 'xlarge',
                width: 'xsmall',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'streetName',
              label: 'Nom de la voie',
            }}
          >
            <Input
              config={{
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'specialPlace',
              label: 'Dénomination spéciale',
            }}
          >
            <Input
              config={{
                height: 'xlarge',
                width: 'full',
                placeholder: 'La Tour Eiffel',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'postOfficeBoxNumber',
              label: 'Boîte postale',
            }}
          >
            <Input
              config={{
                height: 'xlarge',
                width: 'full',
                placeholder: '',
              }}
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'postalCode',
              label: 'Code postal',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                height: 'xlarge',
                width: 'small',
                placeholder: '',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'addressLocality',
              label: 'Ville',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'addressSubRegion',
              label: 'Département',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>

          <Form.Item
            config={{
              way: 'vertical',
              name: 'addressCountry',
              label: 'Pays ISO 3166-1 alpha-2',
              rules: [{ required: true, max: 2, min: 2 }],
            }}
          >
            <Input
              config={{
                height: 'xlarge',
                width: 'full',
                placeholder: 'FR',
              }}
            />
          </Form.Item>
        </Row>
        <Space config={{ count: spaceBeetwenTitle, way: 'vertical' }}></Space>
        <Write
          data={{ item: "Moyen de contact de l'organisme de recours" }}
          config={{
            mode: 'title-small',
          }}
        ></Write>

        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'url',
              label: 'Lien du site web',
            }}
          >
            <Input
              config={{
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Space config={{ count: spaceBeetwenTitle, way: 'vertical' }}></Space>

        <Write
          data={{ item: 'Caractéristique des contraventions' }}
          config={{
            mode: 'title-small',
          }}
        ></Write>

        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'reducedPriceDuration',
              label: 'Durée de minoration (en minutes)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'reducedFinePrice',
              label: 'Montant minorée (en centimes)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'finePrice',
              label: 'Montant du FPS (en centimes)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
      </Form.Structure>
    </Form>
  );
};
