const formattedData = ([key, value], date, valueRender = (value) => value) => ({
  date: new Date(date).getTime(),
  value: valueRender(value),
  group: key,
});

const consumable = ['secbat', 'pribat'];

export const statsEnergie = (energiesGraph) => {
  const energiesMean = energiesGraph?.reduce(
    (acc, { p: { sol, ...pRest } }) => {
      const allObject = { ...pRest };
      delete allObject.sol;

      const newObject = { ...allObject, ...acc };

      Object.entries(allObject).forEach(([key, value]) => {
        const exist = acc?.[key];
        if (exist || exist === 0) newObject[key] = newObject[key] + value;
        else newObject[key] = value;
      });

      return newObject;
    },
    {},
  );

  const energiesAvg = Object.entries(energiesMean).map(([key, value]) => {
    return [key, value / energiesGraph.length];
  });

  const energiesGroup = energiesAvg.reduce(
    (acc, [key, value]) => {
      if (consumable.includes(key)) acc.prod = acc.prod + value;
      else acc.conso = acc.conso + value;
      acc.balance = acc.prod - acc.conso;

      return acc;
    },
    { prod: 0, conso: 0, balance: 0 },
  );

  energiesGroup.conso = energiesGroup.conso * -1;

  return energiesGroup;
};

export const readEnergieRender = ({ energies, ...rest }) => {
  const energiesGraph = energies?.graph;

  const puissanceFormatted = energiesGraph?.map(({ logDate, p }) => ({
    date: new Date(logDate),
    ...p,
  }));

  const tensionsFormatted = energiesGraph?.map(({ logDate, u }) => ({
    date: new Date(logDate),
    ...Object.fromEntries(
      Object.entries(u).map(([key, value]) => [key, value / 1000]),
    ),
  }));

  const tPeriphFormatted = energiesGraph?.map(({ logDate, stats }) => ({
    date: new Date(logDate),
    ...Object.fromEntries(
      Object.entries(stats.tp).map(([key, value]) => [key, value * 1000]),
    ),
  }));

  const tMachineFormatted = energiesGraph?.map(
    ({ logDate, p: { pribat, secbat, sol, ...restIsperiph } }) => {
      const periph =
        Object.values(restIsperiph).reduce((acc, value) => acc + value, 0) * -1;

      const consume = pribat + secbat;

      return {
        date: new Date(logDate),
        pribat: pribat,
        secbat: secbat,
        periph,
        balance: consume + periph,
      };
    },
  );

  const meteoFormatted = energiesGraph?.map(({ logDate, stats }) => ({
    date: new Date(logDate),
    hum: stats.hum,
    temp: stats.temp,
  }));

  return {
    stats: statsEnergie(energiesGraph),
    meteo: meteoFormatted,
    tensions: tensionsFormatted,
    puissance: puissanceFormatted,
    tPeriph: tPeriphFormatted,
    tMachine: tMachineFormatted,
    ...rest,
  };
};
