import { gql } from '@apollo/client';

export const upsGql = gql`
  query ups($upsId: String!, $sitesPage: Int, $sitesLimit: Int) {
    ups(upsId: $upsId) {
      _id
      code
      maximumDuration
      shape
      client {
        _id
        slug
      }
      sites(page: $sitesPage, limit: $sitesLimit) {
        list {
          _id
          name
        }
        paging {
          current
          count
          limit
        }
      }
    }
  }
`;
