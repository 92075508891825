import { gql } from '@apollo/client';

export type ControlUserResponse = {
  _id: string;
  username: string | null;
  lastName: string | null;
  firstName: string | null;
  fpsAgentName: string | null;
  fpsAgentId: string | null;
  ups: string[] | null;
};

export type ControlUserParams = {
  userId: string;
};

export const controlUserGql = gql`
  query controlUser($userId: String!) {
    controlUser(userId: $userId) {
      _id
      username
      lastName
      firstName
      fpsAgentName
      fpsAgentId
      ups
    }
  }
`;
