import { requestGQL, wizardService } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useState } from 'react';
import {
  CreateControlUserParams,
  CreateControlUserResponse,
  createControlUserGql,
} from './gql/createControlUser.gql';
import { UpsListResponse } from './gql/upsList.gql';
import { CredentialsStep } from './steps/credentials.step';
import { DetailsStep } from './steps/details.step';

export const timeoutOnSearch = 200;

export type CreateControlUserWizardContext = {
  credentials: {
    username: string | null;
    password: string | null;
  };
  listParking: UpsListResponse['list'];
  selectParking: {
    _id: string | null;
  };
  user: {
    firstName: string | null;
    lastName: string | null;
    fpsAgentId: number | null;
    fpsAgentName: string | null;
    upsId: string | null;
    lang: string;
  };
};

export const CreateControlUserWizard = () => {
  const [result, setResult] = useState<boolean>();

  return (
    <Wizard<CreateControlUserWizardContext>
      config={{
        title: 'Créer un agent',
        withValidation: {
          state: result,
          success: 'Ça marche nickel !',
          error: 'Ca marche pas !',
        },
      }}
      data={{
        defaultContext: {
          credentials: {
            username: null,
            password: null,
          },
          listParking: [],
          selectParking: {
            _id: null,
          },
          user: {
            firstName: null,
            lastName: null,
            fpsAgentId: null,
            fpsAgentName: null,
            lang: 'fr_FR',
            upsId: null,
          },
        },
      }}
      handleEvent={{
        done: async ({ credentials, listParking, selectParking, user }) => {
          try {
            (await requestGQL({
              params: {
                input: {
                  firstName: user.firstName,
                  fpsAgentId: `${user.fpsAgentId}`,
                  fpsAgentName: user.fpsAgentName,
                  lang: user.lang,
                  lastName: user.lastName,
                  upsId: selectParking._id,
                  password: credentials.password,
                  username: credentials.username,
                },
              } as CreateControlUserParams,
              gql: createControlUserGql,
            })) as CreateControlUserResponse;

            setResult(() => true);
          } catch (error) {
            setResult(() => false);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item
        config={{ title: 'Credentials', component: CredentialsStep }}
      />
      <Wizard.Item config={{ title: 'Details', component: DetailsStep }} />
    </Wizard>
  );
};
