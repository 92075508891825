import { gql } from '@apollo/client';

export const fragmentsEntity = `
    fragments(
      page: $fragmentsPage
      limit: $fragmentsLimit
      startDate: $startDate
      stopDate: $stopDate
    ) {
      list {
        _id
        consumer
        type
        state
        bucketId
        terminalId
        fragmentRef
        logDate
        batchId
      }
      paging {
        current
        count
        limit
      }
    }
`;

export const siteFragmentsPaging = gql`
  query site($siteId: String!, $fragmentsPage: Int, $fragmentsLimit: Int, $startDate: String, $stopDate: String) {
    site(siteId: $siteId) {
      ${fragmentsEntity}
    }
  }
`;

export const fragmentsEntityDebug = `
  fragments(type:$type, page: $fragmentsDebugPage, limit: $fragmentsDebugLimit) {
    list {
      _id
      consumer
      type
      state
      bucketId
      terminalId
      fragmentRef
      logDate
      batchId
    }
    paging {
      current
      count
      limit
    }
  }`;

export const siteFragmentsDebugPaging = gql`
  query site($siteId: String!, $type:String, $fragmentsDebugPage: Int, $fragmentsDebugLimit: Int) {
    site(siteId: $siteId) {
      ${fragmentsEntityDebug}
    }
  }
`;
