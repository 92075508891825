import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import { assign, createMachine, interpret } from 'xstate';
import { createClientGql } from './gql/createClient.gql';

const initialContext = {
  fakePerson: undefined,
  error: undefined,
  name: undefined,
};

const machine = createMachine(
  {
    id: 'createClient',
    initial: 'off',
    context: initialContext,
    predictableActionArguments: true,
    states: {
      off: {
        on: {
          WAKEUP: {
            target: 'idle',
          },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
          },
          CREATE_CLIENT: {
            target: 'createClient',
          },
        },
      },
      createClient: {
        invoke: {
          id: 'createClient',
          src: 'createClient',
          onDone: {
            target: 'idle',
            actions: assign({
              result: true,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({
              result: false,
            }),
          },
        },
      },
      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },
  },
  {
    services: {
      createClient: (context, { payload }) => {
        return requestGQL({
          params: { input: payload },
          gql: createClientGql,
          render: (res) => res,
        });
      },
    },
  },
);

export const createClientService = interpret(machine).start();
