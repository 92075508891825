import { gql } from '@apollo/client';
import { energiesEntity } from '../entity/energies.gql';
import { peripheralsEntity } from '../entity/peripherals.gql';
import { versionsEntity } from '../entity/versions.entity';

export const siteGlobalGql = gql`
  query site($siteId: String!, $startDate: String, $stopDate: String) {
    site(siteId: $siteId) {
        _id
        monitoring {
          _id
          status
          lastPing
          currentAlarms {
            alarm
            effect
            extra
          }
        }
        ${peripheralsEntity}
        ${versionsEntity}
        ${energiesEntity}
    }
  }
`;
