import { gql } from '@apollo/client';

export const alarmsEntity = `
  alarms(page: $alarmsPage, limit: $alarmsLimit, startDate: $startDate, stopDate: $stopDate) {
    list {
      _id
      key
      type
      domain
      alarm
      extra
      startDate
      stopDate
      payload {
      fragmentRef
      }
    }
    paging {
      current
      count
      limit
    }
  }`;

export const alarmsPaging = gql`
  query site($siteId: String!, $alarmsPage: Int, $alarmsLimit: Int, $startDate: String, $stopDate: String) {
    site(siteId: $siteId) {
      ${alarmsEntity}
    }
  }
`;
