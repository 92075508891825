import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import axios from 'axios';
import { assign, createMachine, interpret } from 'xstate';
import config from '../../config';
import { readOperatorGql } from './gql/readOperator.gql';
import { updateOperatorGql } from './gql/updateOperator.gql';
import { read as readRender } from './projection/operators.render';

const initialContext = {
  operatorId: undefined,
  operator: undefined,
  error: undefined,
};

const dataToUrl = async ([fileName, dataUrl]) => {
  const base64 = await fetch(dataUrl);
  const blob = await base64.blob();

  const form = new FormData();
  form.append('file', blob, fileName);

  const { logoUrl, logoId } = await axios
    .post(`${config.BFF_HOST}/operators/logo`, form)
    .then((response) => {
      return response.data;
    });

  return { logoUrl, logoId };
};

const machine = createMachine(
  {
    predictableActionArguments: true,
    id: 'updateOperator',
    initial: 'off',
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            actions: assign({ operatorId: (_, { operatorId }) => operatorId }),
            target: 'read',
          },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
          },
          UPDATE_OPERATOR: {
            target: 'updateOperator',
          },
        },
      },
      read: {
        invoke: {
          id: 'read',
          src: 'read',
          onDone: {
            target: 'idle',
            actions: assign({ operator: (_, { data }) => data }),
          },
          onError: {
            target: 'failure',
            actions: assign({ error: (_, { data }) => data }),
          },
        },
      },
      updateOperator: {
        invoke: {
          id: 'updateOperator',
          src: 'updateOperator',
          onDone: {
            target: 'idle',
            actions: assign({
              result: true,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({
              result: false,
            }),
          },
        },
      },
      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },
  },
  {
    services: {
      read: async (context, params) =>
        requestGQL({
          params,
          gql: readOperatorGql,
          render: (res) => readRender(context, res),
        }),
      updateOperator: async (context, { payload }) => {
        const {
          operatorId,
          operatorCode,
          code,
          display,
          displayBucket,
          email,
          phone,
          ...rest
        } = payload;
        // TODO Detect logo removal and remove from bucket.

        // Upload logos to bucket if new data.
        if (display.logoMain && display.logoMain.length) {
          display.logoMain = display.logoMain[0][1].startsWith('data:')
            ? await dataToUrl(display.logoMain[0])
            : display.logoMain[0][1];
        } else if (display.logoMain) {
          delete display.logoMain;
        }

        if (display.logoSecondary && display.logoSecondary.length) {
          display.logoSecondary = display.logoSecondary[0][1].startsWith(
            'data:',
          )
            ? await dataToUrl(display.logoSecondary[0])
            : display.logoSecondary[0][1];
        } else if (display.logoSecondary) {
          delete display.logoSecondary;
        }

        const input = {
          operatorId,
          display,
          // Remove nullable fields.
          email: email ? email : undefined,
          phone: phone ? phone : undefined,
          ...rest,
        };

        return requestGQL({
          params: { input },
          gql: updateOperatorGql,
          render: (res) => res,
        });
      },
    },
  },
);

export const updateOperatorService = interpret(machine).start();
