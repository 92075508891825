import { Form, Input, WizardItemType } from "@gimlite/watermelon";
import { UpdateAdminUserWizardContext } from "../updateAdminUser.wizard";

export const UpdateCredentialsStep = ({
    validate,
    submit
}: WizardItemType.Config.Component<UpdateAdminUserWizardContext>) => {

    return (
        <Form
            config={{ validateOnChange: true }}
            handleEvent={{
                change: (value: any) => {
                    submit(value);
                },
                validate: (value: any) => {
                    validate(value);
                },
            }}
        >
            <Form.Structure>
                <Form.Item
                    config={{
                        way: 'vertical',
                        name: 'password',
                        label: 'password',
                        rules: [{ required: true, min: 8 }]
                    }}
                >
                    <Input config={{ placeholder: '' }} />
                </Form.Item>
            </Form.Structure>
        </Form>
    )

}