import { EventObject, assign, createMachine, interpret } from 'xstate';
import { UpdateControlUserWizardContext } from './updateControlUser.wizard';

export type UpdateControlUserServiceContextType = {
  credentials: UpdateControlUserWizardContext['credentials'];
  user: UpdateControlUserWizardContext['user'];
  error: string | null;
};

const initialContext: UpdateControlUserServiceContextType = {
  user: {
    firstName: null,
    lastName: null,
    fpsAgentId: null,
    fpsAgentName: null,
    lang: 'fr_FR',
    ups: [],
    _id: null,
  },
  credentials: {
    username: null,
    password: null,
  },
  error: null,
};

const machine = createMachine({
  predictableActionArguments: true,
  id: 'updateControlUser',
  initial: 'off',
  context: initialContext,
  states: {
    off: {
      on: {
        WAKEUP: {
          target: 'idle',
          actions: [
            assign({
              credentials: (
                _,
                {
                  credentials,
                }: EventObject & {
                  credentials: UpdateControlUserWizardContext['credentials'];
                },
              ) => credentials,
            }),
            assign({
              user: (
                _,
                {
                  user,
                }: EventObject & {
                  user: UpdateControlUserWizardContext['user'];
                },
              ) => user,
            }),
          ],
        },
      },
    },
    idle: {
      on: {
        KILL: {
          target: 'off',
        },
      },
    },
    failure: {
      on: {
        RETRY: {
          actions: assign({ error: undefined }),
          target: 'idle',
        },
      },
    },
  },
});

export const updateControlUserService = interpret(machine).start();
