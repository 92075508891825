import { gql } from '@apollo/client';

export const parkingRightsGql = gql`
  query parkingRights(
    $limit: Int
    $page: Int
    $order: String
    $plate: String
    $beginEnd: [DateTime!]
    $ups: [String!]
  ) {
    parkingRights(
      limit: $limit
      page: $page
      order: $order
      plate: $plate
      beginEnd: $beginEnd
      ups: $ups
    ) {
      list {
        _id
        consumer
        state
        startDate
        endDate
        amount
        error {
          code
          message
          payload
        }
        currency
        vehicle {
          lpn
        }
        ups
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
