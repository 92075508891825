import { EventObject, assign, createMachine, interpret } from 'xstate';
import { UpdateCameraWizardContext } from './updateCamera.wizard';

export type UpdateCameraServiceContextType = {
  camera: UpdateCameraWizardContext['camera'] & { upsCode: string | null };
  error: string | null;
};

const initialContext: UpdateCameraServiceContextType = {
  camera: {
    _id: null,
    code: null,
    description: null,
    position: 'inside',
    ups: null,
    upsCode: null,
  },
  error: null,
};

const machine = createMachine({
  predictableActionArguments: true,
  id: 'updateCamera',
  initial: 'off',
  context: initialContext,
  states: {
    off: {
      on: {
        WAKEUP: {
          target: 'idle',
          actions: assign({
            camera: (
              _,
              {
                camera,
              }: EventObject & { camera: UpdateCameraWizardContext['camera'] },
            ) => camera,
          }),
        },
      },
    },
    idle: {
      on: {
        KILL: {
          target: 'off',
        },
      },
    },
    failure: {
      on: {
        RETRY: {
          actions: assign({ error: undefined }),
          target: 'idle',
        },
      },
    },
  },
});

export const updateCameraService = interpret(machine).start();
