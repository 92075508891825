export const readRender = ({
  _id,
  code,
  position,
  ups,
  description,
  upsCode,
}) => {
  const details = [
    { key: '_id', label: 'ID', value: _id },
    { key: 'code', label: 'Code', value: code },
    {
      key: 'position',
      label: 'Position',
      value: position,
    },
    {
      key: 'description',
      label: 'Description',
      value: description,
    },
    {
      key: 'ups',
      label: 'upsID',
      value: ups,
    },
    {
      key: 'upsCode',
      label: 'upsCode',
      value: upsCode,
    },
  ];

  return { details };
};
