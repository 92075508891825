import { Badge } from '@gimlite/watermelon/components/badge/badge.component';

export const update = (context, { site }) => {
  return site;
};

const states = {
  down: <Badge config={{ color: 'error', text: 'DOWN' }} />,
  'not-responding': <Badge config={{ color: 'error', text: 'TIMEOUT' }} />,
  up: <Badge config={{ color: 'success', text: 'UP' }} />,
  degraded: <Badge config={{ color: 'warn', text: 'DEGRADED' }} />,
  default: <Badge config={{ color: 'error', text: 'UNKNOWN' }} />,
};

export const searchRender = ({ list, paging }) => {
  return {
    list: list.map((site) => ({
      ...site,
      clientSlug: site.client.slug,
      upsCode: site.ups.code,
      state: states[site.monitoring?.status || 'default'],
    })),
    paging,
  };
};
