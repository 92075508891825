import { requestGQL, wizardService } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useSelector } from '@xstate/react';
import { useState } from 'react';
import {
  AddParkingInControlUserContextType,
  addParkingInControlUserService,
} from './addParkingInControlUser.machine';
import {
  UpdateControlUsersParams,
  UpdateControlUsersResponse,
  updateControlUsersGql,
} from './gql/updateControlUser.gql';
import { UpsListResponse } from './gql/upsList.gql.js';
import { CameraStep } from './steps/parking.step';

export type AddParkingInControlUserWizardContext = {
  user: {
    _id: string | null;
    ups: string[];
  };
  listParking: UpsListResponse['list'];
  selectParking: {
    _id: string | null;
  };
};

export const AddParkingInControlUserWizard = () => {
  const [result, setResult] = useState<boolean>();

  const { user } = useSelector(
    addParkingInControlUserService,
    ({ context }: { context: AddParkingInControlUserContextType }) => context,
  );

  return (
    <Wizard<AddParkingInControlUserWizardContext>
      config={{
        title: "Ajouter un parking à l'agent",
        withValidation: {
          state: result,
          success: 'Ca marche nickel !',
          error: 'Ca marche pas !',
        },
      }}
      data={{
        defaultContext: {
          user,
          listParking: [],
          selectParking: {
            _id: null,
          },
        },
      }}
      handleEvent={{
        done: async ({ user, selectParking }) => {
          try {
            (await requestGQL({
              params: {
                input: {
                  ups: [...user.ups, selectParking._id],
                  userId: user._id!,
                },
              } as UpdateControlUsersParams,
              gql: updateControlUsersGql,
            })) as UpdateControlUsersResponse;
            setResult(() => true);
          } catch (error) {
            setResult(() => false);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item config={{ title: 'Camera', component: CameraStep }} />
    </Wizard>
  );
};
