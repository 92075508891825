import { gql } from '@apollo/client';

export type UpdateControlUsersResponse = {
  controlUser: {
    _id: string;
  };
};

export type UpdateControlUsersParams = {
  input: {
    userId: string;
    firstName: string | null;
    lastName: string | null;
    username: string;
    lang: string | null;
    password: string | null;
    fpsAgentId: string | null;
    fpsAgentName: string | null;
    ups: [string] | null;
  };
};

export const updateControlUsersGql = gql`
  mutation updateControlUsers($input: UpdateControlUserDto!) {
    updateControlUsers(input: $input) {
      controlUser {
        _id
      }
    }
  }
`;
