import { gql } from '@apollo/client';

export const createClientGql = gql`
  mutation createClient($input: CreateClientDto!) {
    createClient(input: $input) {
      client {
        _id
      }
    }
  }
`;
