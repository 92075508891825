import { gql } from '@apollo/client';

export const updateTranslationGql = gql`
  mutation updateTranslation(
    $dictionaryKey: String!
    $lang: String!
    $translations: Any!
  ) {
    updateTranslation(
      dictionaryKey: $dictionaryKey
      lang: $lang
      translations: $translations
    ) {
      _id
      key
      languages
      documents {
        lang
        key
        translations
        createdAt
      }
      createdAt
    }
  }
`;
