import { requestGQL, wizardService } from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { clientGql } from '../../gql/client.gql';
import { readRender } from '../../projection/clients.render';

export const ClientsDetailsUps = () => {
  const { clientId } = useParams();
  const { getParamsUrl } = useMyUrl();
  const [read, setRead] = useState<any>();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { ...getParamsUrl, clientId },
          gql: clientGql,
          render: (res) => readRender(res),
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, clientId]);

  return (
    <Zone
      config={{
        zones: [['temp'], ['details'], ['table']],
        rows: ['min-content', 'min-content', '1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'temp' }}>
        <Widget config={{ title: 'Actions (temporaire)' }}>
          <Button
            config={{ text: 'Add UPS', type: { value: 'button' } }}
            handleEvent={{
              click: () =>
                wizardService.send('SHOW_WIZARD', {
                  id: 'createUps',
                  clientId,
                }),
            }}
          />
        </Widget>
      </Zone.Area>
      <Zone.Area config={{ area: 'details' }}>
        <Widget config={{ title: 'Details' }}>
          <Description
            data={[
              {
                key: 'ID',
                label: 'ID',
                value: read?._id,
              },
            ]}
          ></Description>
        </Widget>
      </Zone.Area>
      <Zone.Area config={{ area: 'table' }}>
        <Widget config={{ title: 'Ups list' }}>
          <Table<any>
            handleEvent={{
              read: (value: any) => {
                navigate(`/onstreet/ups/${value._id}`);
              },
            }}
            data={read?.ups}
            config={{
              actions: { read: true },
              columns: [
                {
                  title: 'code',
                  key: 'code',
                },
                {
                  title: 'maximumDuration',
                  key: 'maximumDuration',
                },
                {
                  title: 'maximumAmount',
                  key: 'maximumAmount',
                },
              ],
            }}
          />
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
