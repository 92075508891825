import { gql } from "@apollo/client";

export const updateServiceCardGql = gql`
    mutation updateCard(
        $id: String!
        $comment: String
        $status: String
        $pincode: Boolean
        $type: String
    ) {
    
        updateCard(
            id: $id
            comment: $comment
            status: $status
            pincode: $pincode
            type: $type
        ) {
            _id
            cardId
            type
            customerId
            dt
            pincode
            status
            comment
        }
    
    }
`