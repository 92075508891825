import { Page } from '@gimlite/watermelon/components/page/page.component';
import { useParams } from 'react-router-dom';

export const AdminUsersDetails = () => {
  const { userId } = useParams();
  // const { getParamsUrl } = useMyUrl();

  // const [read, setRead] = useState<any>();

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const response = await requestGQL({
  //         params: { userId },
  //         gql: adminUserGql,
  //         render: (res) => res,
  //       });

  //       setRead(() => response);
  //     } catch (err) {
  //       console.log({ err });
  //     } finally {
  //       console.log('loading finish');
  //     }
  //   })();
  // }, [userId]);

  return (
    <Page>
      <span>Pas disponible</span>
      {/* <Zone
        config={{
          zones: [['details'], ['table']],
          rows: ['min-content', '1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'details' }}>
          <Widget config={{ title: 'Details' }}>
            <Description
              data={[
                {
                  key: 'pseudo',
                  label: "Nom d'utilisateur",
                  value: read?.username,
                },
              ]}
            ></Description>
          </Widget>
        </Zone.Area>
        <Zone.Area config={{ area: 'table' }}>
          <Widget config={{ title: 'Ups list' }}>
            <Table<any>
              data={read?.upsList}
              config={{
                columns: [
                  {
                    title: 'code',
                    key: 'code',
                  },
                  {
                    title: 'maximumDuration',
                    key: 'maximumDuration',
                  },
                  {
                    title: 'maximumAmount',
                    key: 'maximumAmount',
                  },
                ],
              }}
            />
          </Widget>
        </Zone.Area>
      </Zone> */}
    </Page>
  );
};
