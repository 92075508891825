import { requestGQL, Space, useMyUrl } from '@gimlite/watermelon';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Statistic } from '@gimlite/watermelon/components/statistic/statistic.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AveragePowerChart } from '../../../../charts/averagePower.chart';
import { BalanceAveragePowerChart } from '../../../../charts/balanceAveragePower.chart';
import { PeripheralTimeChart } from '../../../../charts/peripheralTime.chart';
import { TensionChart } from '../../../../charts/tension.chart';
import { WeatherChart } from '../../../../charts/weather.chart';
import { siteEnergieGql } from '../../gql/tab/energie.gql';
import { readEnergieRender } from '../../projection/energie.render';
import { siteDetailsDefaultParams, SitesDetailsFilter } from '../sites.details';
import { FeedbackChart } from '../../../../charts/feedback.chart';
import { siteFeedbacksGql } from '../../gql/tab/feedbacks.gql';
import { readFeedbacksRender } from '../../projection/feedbacks.render';

export const SitesDetailsFeedback = () => {
  const [read, setRead] = useState<any>();
  const { getParamsUrl } = useMyUrl();
  const { siteId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { ...siteDetailsDefaultParams, ...getParamsUrl, siteId },
          gql: siteFeedbacksGql,
          render: (res) => res,
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, siteDetailsDefaultParams, siteId]);

  return (
    <Col>
      <Row>
        <SitesDetailsFilter />
      </Row>
      <Space config={{ count: 2 }} />

      <Row>
        <Col>
          {read?.feedbacks?.list?.map(
            ({ title, items }: any, index: number) => {
              return (
                <>
                  <Widget config={{ title, yMax: '400px' }}>
                    <FeedbackChart data={items} />
                  </Widget>

                  {index !== read?.feedbacks?.list.length - 1 && (
                    <Space config={{ count: 2 }} />
                  )}
                </>
              );
            },
          )}
        </Col>
      </Row>
    </Col>
  );
};
