import {
  Badge,
  Blurb,
  Icon,
  ListFlatInfo,
  Popover,
  requestGQL,
  Space,
  useMyUrl,
} from '@gimlite/watermelon';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { Result } from '@gimlite/watermelon/components/result/result.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Statistic } from '@gimlite/watermelon/components/statistic/statistic.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useEffect, useState } from 'react';
import { readGlobalRender } from '../../projection/global.render';
import { siteDetailsDefaultParams } from '../sites.details';
import { siteGlobalGql } from '../../gql/tab/global.gql';
import { useParams } from 'react-router-dom';

export const SitesDetailsGlobal = () => {
  const [read, setRead] = useState<any>();
  const { getParamsUrl } = useMyUrl();
  const { siteId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { ...siteDetailsDefaultParams, ...getParamsUrl, siteId },
          gql: siteGlobalGql,
          render: (res) => readGlobalRender(res),
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, siteDetailsDefaultParams, siteId]);

  return (
    <Row>
      <Col>
        <Widget config={{ title: 'Etat du terminal' }}>
          <Result
            data={{
              status: read?.state?.status,
              title: read?.state?.title,
            }}
          />
        </Widget>
        <Space config={{ count: 2 }} />

        <Widget config={{ title: 'Alarmes courantes' }}>
          <ListFlatInfo<'icon' | 'blurb' | 'badge'>
            config={{
              pagination: 'none',
              columns: [
                { key: 'icon', label: '', size: 'min-content' },
                { key: 'blurb', label: '', size: '1fr' },
                { key: 'badge', label: '', size: 'min-content' },
              ],
            }}
            data={{
              list:
                read?.currentAlarms?.map(({ title, badge }: any) => {
                  return {
                    _id: title,
                    items: {
                      icon: (
                        <Icon
                          config={{
                            type: 'faLandMineOnSolid',
                            color: 'primary',
                          }}
                        ></Icon>
                      ),
                      blurb: (
                        <Blurb
                          data={{
                            title,
                          }}
                        ></Blurb>
                      ),
                      badge: <Badge config={{ ...badge }}></Badge>,
                    },
                  };
                }) || [],
            }}
          ></ListFlatInfo>
        </Widget>
        <Space config={{ count: 2 }} />

        <Widget config={{ title: 'Charge' }}>
          <Statistic
            config={{
              explain: 'Puissance moyenne sur les 7 derniers jours',
              precision: 2,
              way: true,
              suffix: 'mW',
            }}
            data={{ value: read?.energies?.prod }}
          />
        </Widget>
        <Space config={{ count: 2 }} />

        <Widget config={{ title: 'Consommation' }}>
          <Statistic
            config={{
              explain: 'Puissance moyenne sur les 7 derniers jours',
              precision: 2,
              way: true,
              suffix: 'mW',
            }}
            data={{ value: read?.energies?.conso }}
          />
        </Widget>
        <Space config={{ count: 2 }} />

        <Widget config={{ title: 'Balance' }}>
          <Statistic
            config={{
              explain: 'Puissance moyenne sur les 7 derniers jours',
              precision: 2,
              way: true,
              suffix: 'mW',
            }}
            data={{ value: read?.energies?.balance }}
          />
        </Widget>
      </Col>
      <Space config={{ way: 'horizontal', count: 2 }} />
      <Col>
        <Widget config={{ title: 'Périphériques' }}>
          <ListFlatInfo<'component'>
            config={{
              pagination: 'none',
              columns: [{ key: 'component', label: '', size: '1fr' }],
            }}
            data={{
              list: read?.peripherals?.map(
                ({ title, description, icon, $expandable }: any) => {
                  return {
                    _id: title,
                    items: {
                      component: (
                        <Popover
                          config={{
                            placement: 'bottomLeft',
                            trigger: 'hover',
                            maxWidth: 'large',
                          }}
                          data={$expandable}
                        >
                          <Zone
                            config={{
                              zones: [['icon', 'blurb']],
                              rows: ['1fr'],
                              columns: ['min-content', '1fr'],
                              horizontal: 'center',
                            }}
                          >
                            <Zone.Area config={{ area: 'icon' }}>
                              <Icon
                                config={{
                                  type: 'faPlugSolid',
                                  color: 'primary',
                                }}
                              ></Icon>
                            </Zone.Area>

                            <Zone.Area config={{ area: 'blurb' }}>
                              <Blurb
                                data={{
                                  title,
                                  description,
                                }}
                              ></Blurb>
                            </Zone.Area>
                          </Zone>
                          ,
                        </Popover>
                      ),
                    },
                  };
                },
              ),
            }}
          ></ListFlatInfo>
        </Widget>
        <Space config={{ count: 2 }} />
        <Widget config={{ title: 'Version Logiciels' }}>
          <ListFlatInfo<'icon' | 'blurb'>
            config={{
              pagination: 'none',
              columns: [
                { key: 'icon', label: '', size: 'min-content' },
                { key: 'blurb', label: '', size: '1fr' },
              ],
            }}
            data={{
              list: read?.versions?.map(({ title, description }: any) => {
                return {
                  _id: title,
                  items: {
                    icon: (
                      <Icon
                        config={{
                          type: 'faCloudArrowUpSolid',
                          color: 'primary',
                        }}
                      ></Icon>
                    ),
                    blurb: (
                      <Blurb
                        data={{
                          title,
                          description,
                        }}
                      ></Blurb>
                    ),
                  },
                };
              }),
            }}
          ></ListFlatInfo>
        </Widget>
      </Col>
    </Row>
  );
};
