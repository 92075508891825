import { gql } from '@apollo/client';

export const mailsGql = gql`
  query mails(
    $page: Int
    $limit: Int
    $order: String
    $state: String
    $from: String
    $to: String
  ) {
    mails(
      page: $page
      limit: $limit
      order: $order
      state: $state
      from: $from
      to: $to
    ) {
      list {
        _id
        state
        from
        to
        system
        createdAt
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
