import { gql } from '@apollo/client';

export const collectsEntity = `
  collects(page: $collectsPage, limit: $collectsLimit, startDate: $startDate, stopDate: $stopDate) {
    list {
      _id
      fragmentRef
      terminalId
      siteId
      logDate
      rejectedCoins
      inhibitedCoins
      totalAmount
      createdAt
      coins
      sumPerCurrency
      cardUID
    }
    paging {
      current
      count
      limit
    }
  }`;

export const collectsPaging = gql`
  query site($siteId: String!, $collectsPage: Int, $collectsLimit: Int, $startDate: String, $stopDate: String) {
    site(siteId: $siteId) {
      ${collectsEntity}
    }
  }
`;
