import { RouterType } from '@gimlite/router';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';

export const ProfilDetails = () => {
  return (
    <Page>
      <Zone
        config={{
          zones: [['group']],
          rows: ['1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'group' }}>
          <Widget.Group>
            <Zone
              config={{
                zones: [['auth']],
                rows: ['min-content'],
                columns: ['1fr'],
              }}
            >
              <Zone.Area config={{ area: 'auth' }}>
                <Widget config={{ title: 'Identifiant' }}>
                  <Form
                    handleEvent={{
                      change: (value: any) => {},
                      validate: (value: any) => {},
                    }}
                  >
                    <Form.Structure>
                      <Form.Item
                        config={{
                          name: 'email',
                          label: 'Email',
                        }}
                      >
                        <Input
                          data={{ defaultValue: 'testoff@mail.com' }}
                          config={{ disabled: true }}
                        />
                      </Form.Item>
                      <Form.Item
                        config={{
                          name: 'password',
                          label: 'Mot de passe',
                        }}
                      >
                        <Input data={{ defaultValue: 'testoff@mail.com' }} />
                      </Form.Item>
                    </Form.Structure>
                  </Form>
                </Widget>
              </Zone.Area>
            </Zone>
          </Widget.Group>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
