import { Button, Col, Empty, Line, Row, Space } from '@gimlite/watermelon';
import { SkeletonBlock } from '@gimlite/watermelon/components/skeleton/skeleton.component';
import { DateTime, Duration } from 'luxon';
import { useMemo, useState } from 'react';

export declare namespace PeripheralTimeType {
  type Props = {
    data: Array<PeripheralTimeType.Point>;
    config?: {
      startDate?: Date;
      endDate?: Date;
    };
  };

  type Point = {
    date: Date;
    coin_ps_coin: number | null;
    door_ps_door: number | null;
    eft_ps_eft: number | null;
    eft_ps_uart6: number | null;
    escrow_ps_coin: number | null;
    heater_ps_heater: number | null;
    motion_ps_motion: number | null;
    nfc_ps_nfc: number | null;
    plug1_ps_plug1: number | null;
    plug2_ps_plug2: number | null;
    printer_ps_printer: number | null;
    safe_ps_safe: number | null;
    shutter_ps_coin: number | null;
  };
}

const empty: Omit<PeripheralTimeType.Point, 'date'> = {
  coin_ps_coin: null,
  door_ps_door: null,
  eft_ps_eft: null,
  eft_ps_uart6: null,
  escrow_ps_coin: null,
  heater_ps_heater: null,
  motion_ps_motion: null,
  nfc_ps_nfc: null,
  plug1_ps_plug1: null,
  plug2_ps_plug2: null,
  printer_ps_printer: null,
  safe_ps_safe: null,
  shutter_ps_coin: null,
};

export const PeripheralTimeChart = ({
  data,
  config,
}: PeripheralTimeType.Props) => {
  const [pointRadius, setPointRadius] = useState(0);

  const dataFormatted = useMemo(() => {
    if (!data) return;

    const dataTransform = data.map(({ date, ...rest }) => ({
      ...rest,
      date: date.getTime(),
    }));

    if (config?.startDate) {
      const findSameDateStart = data.find(
        ({ date }) =>
          new Date(date).getTime() ===
          new Date(config.startDate as Date).getTime(),
      );

      if (!findSameDateStart) {
        dataTransform.unshift({
          date: new Date(config?.startDate).getTime(),
          ...empty,
        });
      }
    }

    if (config?.endDate) {
      const findSameDateEnd = data.find(
        ({ date }) =>
          new Date(date).getTime() ===
          new Date(config.endDate as Date).getTime(),
      );

      if (!findSameDateEnd) {
        dataTransform.push({
          date: new Date(config?.endDate).getTime(),
          ...empty,
        });
      }
    }

    return dataTransform.sort((a, b) => a.date - b.date);
  }, [data]);
  if (!data || !dataFormatted) {
    return <SkeletonBlock />;
  }

  if (data.length <= 1)
    return (
      <Empty
        config={{
          mode: {
            name: 'noData',
          },
        }}
      ></Empty>
    );

  return (
    <Col>
      <Row>
        <Button
          handleEvent={{ click: () => setPointRadius(2) }}
          config={{
            text: 'Avec point',
            color: pointRadius ? 'primary' : 'yang',
            size: 'small',
          }}
        ></Button>
        <Space config={{ way: 'horizontal' }} />
        <Button
          handleEvent={{ click: () => setPointRadius(0) }}
          config={{
            text: 'Sans point',
            color: !pointRadius ? 'primary' : 'yang',
            size: 'small',
          }}
        ></Button>
      </Row>
      <Line
        style={{
          maxHeight: '65vh',
          width: '100%',
        }}
        options={{
          plugins: {
            legend: {
              display: true,
            },
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                  modifierKey: 'ctrl',
                },
                drag: {
                  enabled: true,
                  modifierKey: 'ctrl',
                },
                mode: 'x',
                scaleMode: 'y',
              },
              limits: {
                x: {
                  max: dataFormatted[dataFormatted.length - 1].date,
                  min: dataFormatted[0].date,
                },
              },
            },
            tooltip: {
              intersect: false,
              mode: 'index',
              callbacks: {
                label: ({ formattedValue, dataset: { label } }) => {
                  const parseValue = Number(formattedValue.replace(/\s/g, ''));

                  return !Number.isNaN(parseValue)
                    ? `${label}: ${Duration.fromMillis(parseValue).toFormat(
                        "d'j' hh:mm:ss",
                      )}`
                    : '';
                },
              },
              position: 'nearest',
            },
          },
          responsive: true,
          scales: {
            x: {
              type: 'time',
              ticks: {
                callback: (value, index, ticks) =>
                  DateTime.fromJSDate(new Date(value)).toFormat(
                    'dd/MM/yy HH:mm',
                  ),
              },
              time: {
                tooltipFormat: 'DD/MM/YY HH:mm:ss',
              },
            },
            time: {
              ticks: {
                callback: (value, index, ticks) =>
                  Duration.fromMillis(value as number).toFormat(
                    "d'j' hh:mm:ss",
                  ),
              },
              position: 'left',
              // max: data.reduce((acc, { date, ...rest }) => {
              //   const max = Object.values(rest).reduce(
              //     (acc, value) => (acc < value ? value : acc),
              //     0,
              //   );

              //   return acc < max ? max : acc;
              // }, 0),
            },
          },
        }}
        data={{
          labels: dataFormatted.map(({ date }) => date),
          datasets: [
            {
              fill: false,
              yAxisID: 'time',
              label: 'Acceptor',
              data: dataFormatted.map(({ coin_ps_coin }) => coin_ps_coin),
              borderWidth: 1,
              order: 0,
              pointRadius,
            },
            {
              fill: false,
              yAxisID: 'time',
              label: 'Door',
              data: dataFormatted.map(({ door_ps_door }) => door_ps_door),
              borderWidth: 1,
              order: 1,
              pointRadius,
            },
            {
              fill: false,
              yAxisID: 'time',
              label: 'EFT',
              data: dataFormatted.map(({ eft_ps_eft }) => eft_ps_eft),
              borderWidth: 1,
              order: 2,
              pointRadius,
            },
            {
              fill: false,
              yAxisID: 'time',
              label: 'EFT (UART)',
              data: dataFormatted.map(({ eft_ps_uart6 }) => eft_ps_uart6),
              borderWidth: 1,
              order: 3,
              pointRadius,
            },
            {
              fill: false,
              yAxisID: 'time',
              label: 'Escrow',
              data: dataFormatted.map(({ escrow_ps_coin }) => escrow_ps_coin),
              borderWidth: 1,
              order: 4,
              pointRadius,
            },
            {
              fill: false,
              yAxisID: 'time',
              label: 'Motion',
              data: dataFormatted.map(
                ({ motion_ps_motion }) => motion_ps_motion,
              ),
              borderWidth: 1,
              order: 5,
              pointRadius,
            },
            {
              fill: false,
              yAxisID: 'time',
              label: 'NFC',
              data: dataFormatted.map(({ nfc_ps_nfc }) => nfc_ps_nfc),
              borderWidth: 1,
              order: 6,
              pointRadius,
            },
            {
              fill: false,
              yAxisID: 'time',
              label: 'Plug 1',
              data: dataFormatted.map(({ plug1_ps_plug1 }) => plug1_ps_plug1),
              borderWidth: 1,
              order: 7,
              pointRadius,
            },
            {
              fill: false,
              yAxisID: 'time',
              label: 'Plug 2',
              data: dataFormatted.map(({ plug2_ps_plug2 }) => plug2_ps_plug2),
              borderWidth: 1,
              order: 8,
              pointRadius,
            },
            {
              fill: false,
              yAxisID: 'time',
              label: 'Printer',
              data: dataFormatted.map(
                ({ printer_ps_printer }) => printer_ps_printer,
              ),
              borderWidth: 1,
              order: 9,
              pointRadius,
            },
            {
              fill: false,
              yAxisID: 'time',
              label: 'Safe',
              data: dataFormatted.map(({ safe_ps_safe }) => safe_ps_safe),
              borderWidth: 1,
              order: 10,
              pointRadius,
            },
            {
              fill: false,
              yAxisID: 'time',
              label: 'Shutter',
              data: dataFormatted.map(({ shutter_ps_coin }) => shutter_ps_coin),
              borderWidth: 1,
              order: 11,
              pointRadius,
            },
          ],
        }}
      ></Line>
    </Col>
  );
};
