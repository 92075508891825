import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useSelector } from '@xstate/react';
import { updateSiteService } from '../updateSite.machine';
import { UpdateSiteWizardContext } from '../updateSite.wizard';

export const SingleStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<UpdateSiteWizardContext>) => {
  const site = useSelector(
    updateSiteService,
    ({ context }: any) => context.site,
  );

  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      {site && (
        <Form.Structure>
          <Form.Item
            data={{ defaultValue: site.name ?? '' }}
            config={{
              name: 'name',
              label: 'Nom',
              rules: [{ required: true }],
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            data={{ defaultValue: site.address1 ?? '' }}
            config={{
              name: 'address1',
              label: 'Adresse (1/2)',
              rules: [],
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            data={{ defaultValue: site.address2 ?? '' }}
            config={{
              name: 'address2',
              label: 'Adresse (2/2)',
              rules: [],
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            data={{ defaultValue: site.zipcode ?? '' }}
            config={{
              name: 'zipcode',
              label: 'Code postal',
              rules: [],
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            data={{ defaultValue: site.city ?? '' }}
            config={{
              name: 'city',
              label: 'Ville',
              rules: [],
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            data={{ defaultValue: site.country ?? '' }}
            config={{
              name: 'country',
              label: 'Pays',
              rules: [],
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            data={{ defaultValue: site.terminalId ?? '' }}
            config={{
              name: 'terminalId',
              label: 'Terminal ID',
              rules: [],
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            data={{ defaultValue: site.coordinates?.[0] ?? '' }}
            config={{
              name: 'latitude',
              label: 'Lat',
              rules: [],
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            data={{ defaultValue: site.coordinates?.[1] ?? '' }}
            config={{
              name: 'longitude',
              label: 'Lon',
              rules: [],
            }}
          >
            <Input />
          </Form.Item>
        </Form.Structure>
      )}
    </Form>
  );
};
