import { requestGQL, useMyUrl } from '@gimlite/watermelon';
import { Code } from '@gimlite/watermelon/components/code/code.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fragmentGql, fragmentsGql } from '../gql/fragments.gql';
import { fragmentsRender } from '../projection/fragments.render';

export const FragmentDetails = () => {
  const { fragmentId, fragmentRef } = useParams();
  const { getParamsUrl } = useMyUrl();

  const [fragment, setFragment] = useState<any>();
  const [fragmentByRef, setFragmentByRef] = useState<any>();

  useEffect(() => {
    if (!!fragmentId) {
      (async () => {
        try {
          const response = await requestGQL({
            params: { fragmentId: fragmentId },
            gql: fragmentGql,
            render: (res) => res,
          });

          setFragment(() => response);
        } catch (err) {
          console.log({ err });
        } finally {
          console.log('loading finish');
        }
      })();
    }

    if (!!fragmentRef) {
      (async () => {
        try {
          const response = await requestGQL({
            params: {
              limit: 15,
              ...getParamsUrl,
            },
            gql: fragmentsGql,
            render: (res) => fragmentsRender(res),
          });

          setFragmentByRef(() => response);
        } catch (err) {
          console.log({ err });
        } finally {
          console.log('loading finish');
        }
      })();
    }
  }, [fragmentId, getParamsUrl, fragmentRef]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['code']],
          rows: ['1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'code' }}>
          <Widget config={{ title: 'Fragment debug' }}>
            <Code
              data={{
                defaultValue: JSON.stringify(
                  fragment || fragmentByRef,
                  null,
                  4,
                ),
              }}
              config={{ yMax: '100%', lang: 'json', readOnly: true }}
            />
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
