import { gql } from '@apollo/client';

export const bucketsGql = gql`
  query buckets($page: Int, $limit: Int, $order: String, $siteId: String) {
    buckets(page: $page, limit: $limit, order: $order, siteId: $siteId) {
      list {
        _id
        terminalId
        bucketId
        processed
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
