import { DateTime } from 'luxon';

export const sessionsRender = ({ list, paging }) => {
  return {
    list: list.map(({ exitDate, entryDate, ...rest }) => {
      return {
        entryDate: DateTime.fromISO(entryDate).toFormat('dd/MM/yy HH:mm:ss'),
        exitDate: DateTime.fromISO(exitDate).toFormat('dd/MM/yy HH:mm:ss'),
        ...rest,
      };
    }),
    paging,
  };
};
