import { gql } from '@apollo/client';

export const parkingsGql = gql`
  query parkings($limit: Int, $page: Int, $order: String) {
    parkings(limit: $limit, page: $page, order: $order) {
      list {
        _id
        name
        code
        coordinates
        zones {
          name
          code
        }
        accessPoints {
          _id
          type
          code
          shortCode
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
