import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { Image } from '@gimlite/watermelon/components/image/image.component';

export const searchRender = ({ list, paging }) => {
  return {
    list: list.map(({ display, ...rest }) => {
      return {
        ...rest,
        logo: display?.logoMain ? (
          <Image data={{ src: display.logoMain }} config={{ size: 'cell' }} />
        ) : (
          <Badge
            config={{
              color: 'disabled',
              text: 'Non spécifié',
            }}
          />
        ),
      };
    }),
    paging,
  };
};

export const readRender = (data) => {
  const {
    translation,
    email,
    phone,
    operatorCode,
    code,
    contracts,
    poolContracts,
    ...rest
  } = data;
  const translationFormatted = translation.map(({ lang, name }) => {
    return {
      title: name,
      description: lang.toUpperCase(),
    };
  });

  const contactFormatted = [
    {
      title: email,
      type: 'email',
    },
    {
      title: phone,
      type: 'phone',
    },
  ];

  const codeFormatted = [
    { key: 'code', label: 'Code', value: code },
    {
      key: 'op-code',
      label: "Code d'opérateur",
      value: operatorCode,
    },
  ];

  const contractsFormatted = readContracts(contracts);
  const poolContractsFormatted = readPoolContracts(poolContracts);

  return {
    translation: translationFormatted,
    contact: contactFormatted,
    code: codeFormatted,
    contracts: contractsFormatted,
    poolContracts: poolContractsFormatted,
    ...rest,
  };
};

export const readContracts = ({ list, paging }) => {
  return {
    list: list.map(({ isEnabled, category, ...rest }) => {
      return {
        ...rest,
        isEnabled: (
          <Badge
            config={{
              color: isEnabled ? 'success' : 'down',
              text: isEnabled ? 'Actif' : 'Inactif',
            }}
          />
        ),
        category: (
          <Badge
            config={{
              text: category,
            }}
          />
        ),
      };
    }),
    paging,
  };
};

export const readPoolContracts = ({ list, paging }) => {
  return {
    list: list.map(({ category, ...rest }) => {
      return {
        ...rest,
        category: (
          <Badge
            config={{
              text: category,
            }}
          />
        ),
      };
    }),
    paging,
  };
};
