import { gql } from '@apollo/client';

export type ControlUsersResponse = {
  list: Array<{
    _id: string;
    username: string;
    lastName: string | null;
    firstName: string | null;
    fpsAgentName: string | null;
    fpsAgentId: string | null;
    ups: string[] | null;
  }>;
  paging: {
    current: number;
    count: number;
    limit: number;
  };
};

export type ControlUsersParams = {
  page: number | null;
  limit: number | null;
  order: string | null;
  usernamePattern: string | null;
};

export const controlUsersGql = gql`
  query controlUsers(
    $page: Int
    $limit: Int
    $order: String
    $usernamePattern: String
  ) {
    controlUsers(
      page: $page
      limit: $limit
      order: $order
      usernamePattern: $usernamePattern
    ) {
      list {
        _id
        username
        lastName
        firstName
        fpsAgentName
        fpsAgentId
        ups
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
