import { wizardService } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useSelector } from '@xstate/react';
import { createAdminUsersService } from './createAdminUser.machine';
import { CredentialsStep } from './steps/credentials.step';

export type CreateAdminUserWizardContext = any;

export const CreateAdminUserWizard = () => {
  const result = useSelector(
    createAdminUsersService,
    ({ context }: any) => context.result,
  );

  return (
    <Wizard<CreateAdminUserWizardContext>
      config={{
        title: '',
        withValidation: {
          state: result,
          success: 'Ca marche nickel !',
          error: 'Ca marche pas !',
        },
      }}
      data={{
        defaultContext: undefined,
      }}
      handleEvent={{
        done: async (value: any) => {
          createAdminUsersService.send('CREATE_ADMIN_USER', {
            payload: value,
          });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item
        config={{ title: 'Credentials', component: CredentialsStep }}
      />
    </Wizard>
  );
};
