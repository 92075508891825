import { gql } from '@apollo/client';

export const safeInfosEntity = `
safeInfos(startDate: $startDate, stopDate: $stopDate ) {
    graph {
      _id
      logDate
      rejectedCoins
      inhibitedCoins
      sumPerCurrency
    }
  }`;

export const safeInfosPaging = gql`
  query site(
    $startDate: String, 
    $stopDate: String
    ) {
    site(siteId: $siteId) {
      ${safeInfosEntity}
    }
  }
`;
