import { gql } from '@apollo/client';

export const operatorsGql = gql`
  query operators($page: Int, $limit: Int, $order: String) {
    operators(page: $page, limit: $limit, order: $order) {
      list {
        _id
        operatorCode
        operatorContractCount
        name
        email
        phone
        code
        display {
          logoMain
          logoSecondary
        }
        translation
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
