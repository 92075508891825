import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { DateTime } from 'luxon';

export const searchRender = ({ list, paging }) => {
  // const authorizateDictionnaire = [
  //   'livio-terminal',
  //   'bff-operators-global',
  //   'bff-motorists-global',
  //   'bff-pool-admin-global',
  // ];

  // const listFiltered = list.filter(({ key }) =>
  //   authorizateDictionnaire.includes(key),
  // );

  // const itemDictionnary = listFiltered[1];

  // const allKeys = Object.keys(
  //   itemDictionnary.documents.reduce((acc, { translations }) => {
  //     return { ...acc, ...translations };
  //   }, {}),
  // );

  // const difference = (keys, translations) =>
  //   keys.filter((x) => !Object.keys(translations).includes(x));

  // const langs = itemDictionnary.documents.reduce(
  //   (acc, { lang, translations }) => {
  //     const diff = difference(allKeys, translations);
  //     const others = Object.fromEntries(diff.map((key) => [key, null]));
  //     return { ...acc, [lang]: { ...translations, ...others } };
  //   },
  //   {},
  // );

  return {
    list: list.map(({ key, languages, documents, createdAt, ...rest }) => {
      return {
        ...rest,
        createdAt: DateTime.fromISO(createdAt).toFormat('dd/MM/yy HH:mm:ss'),
        dictionaryKey: key,
        languagesLength: languages.length,
        translationsLength: documents.reduce((acc, { translations }) => {
          return acc + Object.keys(translations).length;
        }, 0),
      };
    }),
    paging,
  };
};

export const readRender = (dictionary) => {
  const allKeys = Object.keys(
    dictionary.documents.reduce((acc, { translations }) => {
      return { ...acc, ...translations };
    }, {}),
  );

  const difference = (keys, translations) => {
    const translateKey = Object.keys(translations);
    const results = keys.filter(
      (x) => !translateKey.includes(x) || !translations[x],
    );
    return results;
  };

  const toColumnTable = (object) => {
    return Object.entries(object).map(([key, value]) => {
      const base = {
        _id: key,
        state: !value ? (
          <Badge config={{ color: 'error', text: 'MISSING' }} />
        ) : (
          <Badge config={{ color: 'success', text: 'CORRECT' }} />
        ),
        translationKey: key,
        translationValue: value,
      };
      return base;
    });
  };

  const langs = dictionary.documents.reduce((acc, { lang, translations }) => {
    const emptyKeys = difference(allKeys, translations);
    const emptyTranslations = emptyKeys.reduce(
      (acc, cur) => ({ ...acc, [cur]: null }),
      {},
    );

    const otherTranslations = Object.entries(translations)
      .filter(([key, value]) => !emptyKeys.includes(key))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    return [
      ...acc,
      {
        label: lang,
        key: lang,
        list: [
          ...toColumnTable(emptyTranslations),
          ...toColumnTable(otherTranslations),
        ],
      },
    ];
  }, []);

  return langs;
};

export const updateRender = (context, dictionary) => {
  return dictionary;
};
