import { gql } from '@apollo/client';

export const monitoringQuery = gql`
  query monitoring(
    $msType: String, 
    $msDomain: String, 
    $msName: String, 
    $msStatus: String, 
    $order: String,
    $page: Int) {

    monitoring(
      msType: $msType, 
      msDomain: $msDomain, 
      msName: $msName, 
      msStatus: $msStatus, 
      order: $order,
      page: $page) {

      list {
        type
        key
        status
        lastPing
      }
    	paging {
        current
        count
        limit
      }
    }
  }
`;
