import {
  Blurb,
  Description,
  Icon,
  ListFlatInfo,
  requestGQL,
  Space,
  wizardService,
} from '@gimlite/watermelon';
import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { Image } from '@gimlite/watermelon/components/image/image.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Statistic } from '@gimlite/watermelon/components/statistic/statistic.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { operatorGql } from '../gql/operator.gql';
import { readRender } from '../projection/operators.render';

export const OperatorsDetails = () => {
  const { operatorId } = useParams();
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const [read, setRead] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { ...getParamsUrl, operatorId },
          gql: operatorGql,
          render: (res) => readRender(res),
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, operatorId]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['temp'], ['details']],
          rows: ['min-content', 'min-content'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'temp' }}>
          <Widget config={{ title: 'Actions (temporaire)' }}>
            <Button
              handleEvent={{
                click: () => {
                  wizardService.send('SHOW_WIZARD', {
                    id: 'updateOperator',
                    store: {
                      operatorId,
                    },
                  });
                },
              }}
              config={{
                text: "Modifier l'operateur",
                type: { value: 'button' },
              }}
            />
          </Widget>
        </Zone.Area>

        <Zone.Area config={{ area: 'details' }}>
          <Row>
            <Col>
              <Widget config={{ title: 'Logo principal' }}>
                {read?.display?.logoMain && (
                  <Image
                    data={{ src: read?.display?.logoMain }}
                    config={{ preview: true, size: 'medium' }}
                  />
                )}
                {!read?.display?.logoMain && (
                  <Badge
                    config={{
                      color: 'disabled',
                      text: 'Non spécifié',
                    }}
                  />
                )}
              </Widget>
              <Space config={{ count: 2 }} />

              <Widget config={{ title: 'Logo secondaire' }}>
                {read?.display?.logoSecondary && (
                  <Image
                    data={{ src: read?.display?.logoSecondary }}
                    config={{ preview: true, size: 'small' }}
                  />
                )}
                {!read?.display?.logoSecondary && (
                  <Badge
                    config={{
                      color: 'disabled',
                      text: 'Non spécifié',
                    }}
                  />
                )}
              </Widget>
              <Space config={{ count: 2 }} />

              <Widget config={{ title: 'Nom' }}>
                <ListFlatInfo<'icon' | 'blurb'>
                  config={{
                    pagination: 'none',
                    columns: [
                      { key: 'icon', label: '', size: 'min-content' },
                      { key: 'blurb', label: '', size: '1fr' },
                    ],
                  }}
                  data={{
                    list: read?.translation?.map(
                      ({ title, description }: any) => {
                        return {
                          _id: title,
                          items: {
                            icon: (
                              <Icon
                                config={{
                                  type: 'faLanguageSolid',
                                  color: 'primary',
                                }}
                              ></Icon>
                            ),
                            blurb: (
                              <Blurb data={{ title, description }}></Blurb>
                            ),
                          },
                        };
                      },
                    ),
                  }}
                ></ListFlatInfo>
              </Widget>
              <Space config={{ count: 2 }} />

              <Widget config={{ title: 'Codes' }}>
                <Description data={read?.code || []}></Description>
              </Widget>
              <Space config={{ count: 2 }} />

              <Widget config={{ title: 'Contact' }}>
                <ListFlatInfo<'icon' | 'blurb'>
                  config={{
                    pagination: 'none',
                    columns: [
                      { key: 'icon', label: '', size: 'min-content' },
                      { key: 'blurb', label: '', size: '1fr' },
                    ],
                  }}
                  data={{
                    list: read?.contact?.map(({ title, type }: any) => {
                      return {
                        _id: title,
                        items: {
                          icon: (
                            <Icon
                              config={{
                                type:
                                  type === 'email'
                                    ? 'faAtSolid'
                                    : 'faPhoneSolid',
                                color: 'primary',
                              }}
                            ></Icon>
                          ),
                          blurb: <Blurb data={{ title: title }}></Blurb>,
                        },
                      };
                    }),
                  }}
                ></ListFlatInfo>
              </Widget>
            </Col>
            <Space config={{ way: 'horizontal', count: 2 }} />
            <Col
              config={{
                flex: 2,
              }}
            >
              {read && (
                <>
                  <Widget config={{ title: 'Décompte des contrats' }}>
                    <Statistic
                      data={{ value: read.operatorContractCount || 0 }}
                      config={{
                        precision: 0,
                      }}
                    />
                  </Widget>
                  <Space config={{ count: 2 }} />
                </>
              )}

              {read?.contracts && (
                <>
                  <Widget
                    config={{ title: 'Liste des contrats', yMax: '350px' }}
                  >
                    <Table<any>
                      handleEvent={{
                        paging: (value: any) => {
                          setParamsUrl({
                            ...getParamsUrl,
                            contractsPage: value.page,
                            contractsLimit: value.limit,
                          });
                        },
                      }}
                      data={read?.contracts}
                      config={{
                        columns: [
                          {
                            title: 'ID',
                            key: 'ospContractId',
                          },
                          {
                            title: 'Catégorie',
                            key: 'category',
                          },
                          {
                            title: 'Start',
                            key: 'startDate',
                          },
                          {
                            title: 'Stop',
                            key: 'stopDate',
                          },
                          {
                            title: 'Activé',
                            key: 'isEnabled',
                          },
                        ],
                      }}
                    />
                  </Widget>
                  <Space config={{ count: 2 }} />
                </>
              )}

              {read?.poolContracts && (
                <>
                  <Widget
                    config={{ title: 'Liste des contrats pool', yMax: '350px' }}
                  >
                    <Table<any>
                      handleEvent={{
                        paging: (value: any) => {
                          setParamsUrl({
                            ...getParamsUrl,
                            poolContractsPage: value.page,
                            poolContractsLimit: value.limit,
                          });
                        },
                      }}
                      data={read?.poolContracts}
                      config={{
                        columns: [
                          {
                            title: 'ID',
                            key: 'ospContractId',
                          },
                          {
                            title: 'Catégorie',
                            key: 'category',
                          },
                          {
                            title: 'Name 1',
                            key: 'name1',
                          },
                          {
                            title: 'Name 2',
                            key: 'name2',
                          },
                          {
                            title: 'Spaces allocated',
                            key: 'spacesAllocated',
                          },
                          {
                            title: 'Spaces occupied',
                            key: 'spacesOccupied',
                          },
                        ],
                      }}
                    />
                  </Widget>
                </>
              )}
            </Col>
          </Row>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
