import { gql } from '@apollo/client';

export type DeleteCameraResponse = {
  camera: {
    _id: string;
  };
};

export type DeleteCameraParams = {
  input: {
    cameraId: string;
  };
};

export const deleteCameraGql = gql`
  mutation deleteCamera($input: DeleteCameraDto!) {
    deleteCamera(input: $input) {
      camera {
        _id
      }
    }
  }
`;
