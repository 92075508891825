export const energiesEntity = `
    energies (startDate: $startDate, stopDate: $stopDate) {
      graph {
        _id
        logDate
        u {
          secbat
          pribat
          sol
          alt
        }
        p {
          secbat
          pribat
          sol
          lpt
          core
          com
          misc
          eft
        }
        stats {
          temp
          hum
          tlife
          tusr
          tsys
          tsol
          talt
          tp
          nbwake
        }
      }
}`;
