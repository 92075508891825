import { gql } from '@apollo/client';

export const updateSiteGql = gql`
  mutation updateSite($input: UpdateSiteDto!) {
    updateSite(input: $input) {
      site {
        _id
      }
    }
  }
`;
