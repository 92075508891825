import { gql } from '@apollo/client';

export const siteConfigGql = gql`
  query site($siteId: String!) {
    site(siteId: $siteId) {
      _id
      computedConfig {
        ticketsHub {
          system
        }
        prestoOne {
          config
          gui
          tickets
          wizards
          translations
          tariffs
        }
        prestoScan {
          entryTolerancePeriod
          exitTolerancePeriod
          deletionDelay {
            incomplete
            complete
            toCheck
            unfined
            fined
          }
          OutrageTresholdConfig {
            danger
            warning
            valid
          }
        }
        fps {
          maximumDuration
          maximumAmount
        }
        prestoPark {
          cityId
        }
        p1000 {
          url
          port
          path
          user
          password
          multisite_tag_id
          web2token
          psp
          tag_id
        }
      }
    }
  }
`;
