import { Button, FilterExpanded, Form, Input, Page, requestGQL, Select, Table, useMyUrl, useSearchOf, Widget, Zone } from "@gimlite/watermelon"
import { useEffect, useMemo } from "react"
import { searchCardsGql } from "./gql/search-cards.gql"
import { updateServiceCardGql } from "./gql/update-card.gql"
import { ServiceCard } from "./types/service-card"

async function updateServiceCard(value: Partial<ServiceCard>) {
    await requestGQL({
        gql: updateServiceCardGql,
        params: {
            ...value,
            id: value._id,
            pincode: Boolean(value.pincode)
        }
    })
}

export const ServiceCards = () => {

    const { getParamsUrl, setParamsUrl } = useMyUrl()

    const { list, paging, setSearch } = useSearchOf<ServiceCard, any>({
        gql: searchCardsGql,
        limit: 15,
        searchParams: {}
    })

    const renderedList = useMemo(() => list?.map(card => ({
        ...card,
        pincode: card.pincode ? 'Yes' : 'No'
    })) || [], [list])

    useEffect(() => {
        const search = {
            limit: 15,
            ...getParamsUrl,
            cardIdSearch: getParamsUrl?.cardIdSearch ? getParamsUrl.cardIdSearch.toString() : undefined,
            customerId: getParamsUrl?.customerId ? getParamsUrl.customerId.toString() : undefined
        }

        setSearch({
            paging: {
                current: 1,
                limit: 15,
                count: 0
            },
            params: search
        })
    }, [getParamsUrl])

    return (<Page>
        <Widget config={{ title: 'Service Cards' }}>
            <Zone
                config={{
                    zones: [['filter'], ['table']],
                    rows: ['min-content', '1fr'],
                    columns: ['1fr'],
                }}
            >
                <Zone.Area config={{ area: 'filter' }} >
                    <FilterExpanded
                        data={{ value: getParamsUrl }}
                        handleEvent={{
                            submit: (data: any) => setParamsUrl({
                                ...getParamsUrl,
                                ...data,
                                page: 1
                            })
                        }}
                    >
                        <FilterExpanded.Fields>
                            <Form.Item
                                config={{
                                    name: 'cardIdSearch',
                                    label: 'ID',
                                }}
                            >
                                <Input data={{
                                    value: getParamsUrl?.cardIdSearch || '',
                                    defaultValue: ''
                                }} />
                            </Form.Item>

                            <Form.Item
                                config={{
                                    name: 'type',
                                    label: 'Type'
                                }}
                            >
                                <Select
                                    data={{
                                        defaultValue: '',
                                        value: getParamsUrl?.type,
                                        items: [
                                            { label: '', value: '' },
                                            { label: 'master', value: 'master' },
                                            { label: 'action', value: 'action' },
                                            { label: 'collect', value: 'collect' },
                                            { label: 'unknown', value: 'unknown' }
                                        ]
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                config={{
                                    name: 'customerId',
                                    label: 'Customer'
                                }}
                            >
                                <Input data={{
                                    value: getParamsUrl?.customerId || '',
                                    defaultValue: ''
                                }} />
                            </Form.Item>

                            <Form.Item
                                config={{
                                    name: 'commentSearch',
                                    label: 'Comment'
                                }}
                            >
                                <Input data={{
                                    value: getParamsUrl?.commentSearch
                                }} />
                            </Form.Item>
                        </FilterExpanded.Fields>

                        <FilterExpanded.Actions>
                            <Button
                                config={{
                                    text: 'Search',
                                    type: { value: 'submit' }
                                }}
                            />
                            <Button
                                handleEvent={{
                                    click: () => {
                                        setParamsUrl({})
                                        window.location.reload()
                                    }
                                }}
                                config={{
                                    text: 'Clear',
                                    disabled: !(getParamsUrl && Object.values(getParamsUrl).length > 0)
                                }}
                            />
                        </FilterExpanded.Actions>
                    </FilterExpanded>
                </Zone.Area>

                <Zone.Area config={{ area: 'table' }} >
                    <Table<ServiceCard>
                        handleEvent={{
                            paging: (value: any) => {
                                setParamsUrl({ ...getParamsUrl, ...value })
                            },
                            update: updateServiceCard,
                        }}
                        config={{
                            pagination: 'click',
                            columns: [
                                { title: 'ID', key: 'cardId' },
                                { title: 'Type', key: 'type' },
                                { title: 'Customer', key: 'customerId' },
                                { title: 'Host', key: 'host' },
                                { title: 'Status', key: 'status' },
                                { title: 'Comment', key: 'comment' },
                                { title: 'Pincode', key: 'pincode' },
                                { title: 'Creation date', key: 'dt' },
                            ],
                            actions: {
                                update: ['comment']
                            }
                        }}
                        data={{
                            list: renderedList || [],
                            paging: paging || {
                                count: 0,
                                current: 1,
                                limit: 10
                            }
                        }}
                    />
                </Zone.Area>
            </Zone>
        </Widget>
    </Page>)

}