import { gql } from '@apollo/client';

export type CreateUpsResponse = {
  ups: {
    _id: string;
  };
};

export type CreateUpsParams = {
  input: {
    code: string;
    center: number[];
    address: {
      streetNumber: string | null;
      streetNumberBis: string | null;
      streetType: string | null;
      streetName: string | null;
      specialPlace: string | null;
      postalCode: string;
      addressLocality: string;
      addressSubRegion: string;
      addressCountry: string;
      postOfficeBoxNumber: string | null;
    };
    clientId: string;
    groupId: string | null;
    shape: {
      type: string;
      coordinates: number[];
    } | null;
    translation: Array<{
      lang: string;
      name: string;
      description: string | null;
      pricing: string | null;
      opening: string | null;
      hint: string | null;
    }>;
    type: string;
    config: {
      prestoScan: {
        sitesLegacyId: string;
        rightsLegacyId: string;
        entryTolerancePeriod: number;
        exitTolerancePeriod: number;
        isVerbalizationAllowed: boolean;
        deletionDelay: {
          incomplete: number;
          complete: number;
          toCheck: number;
          unfined: number;
          fined: number;
        };
        outrageTreshold: {
          danger: number;
          warning: number;
          valid: number;
        };
        showOccupancy: boolean;
      };
      fps: {
        system: string;
        notificationAuthority: string;
        reducedPriceDuration: number;
        finePrice: number;
        reducedFinePrice: number;
        recourseOrganization: {
          address: {
            streetNumber: string;
            streetNumberBis: string | null;
            streetType: string;
            streetName: string;
            specialPlace: string | null;
            postalCode: string;
            addressLocality: string;
            addressSubRegion: string;
            addressCountry: string;
            postOfficeBoxNumber: string | null;
          };
          name: string;
          organizationId: string;
          url: string;
        };
      };
    };
    occupancy: number;
    capacity: number;
  };
};

export const createUpsGql = gql`
  mutation createUps($input: CreateUpsDto!) {
    createUps(input: $input) {
      ups {
        _id
      }
    }
  }
`;
