export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
    <path
      className="text"
      d="M19.23,313.54c11.9,0,16.38,6.1,16.38,14.6s-4.48,14.6-16.38,14.6H4.78v18.31h-2.78v-47.51h17.23ZM4.78,316.17v23.95h14.45c10.12,0,13.52-4.71,13.52-11.97s-3.4-11.97-13.52-11.97H4.78Z"
    />
    <path
      className="text"
      d="M42.56,325.9c1.39-.69,5.87-2.01,10.04-2.01,2.32,0,5.1.39,6.64.93l-.77,2.32c-1.62-.62-3.94-.77-5.64-.77-3.71,0-5.79.7-7.65,1.39v33.29h-2.63v-35.15Z"
    />
    <path
      className="text"
      d="M94.78,343.98h-30.51c0,11.05,7.72,15.3,16.53,15.3,4.48,0,9.35-1.08,12.05-2.7l.77,2.32c-3.55,1.78-8.27,2.86-13.05,2.86-10.35,0-19-5.1-19-19.24s8.42-18.77,17.23-18.77,15.99,5.25,15.99,17.38v2.86ZM92.08,341.51c0-11.43-5.79-15.3-13.13-15.3s-14.68,3.86-14.68,15.3h27.81Z"
    />
    <path
      className="text"
      d="M101.81,356.34c3.63,1.54,8.03,2.39,12.05,2.39,6.26,0,11.59-2.09,11.59-7.34,0-11.05-23.56-4.17-23.56-17.69,0-6.95,6.33-9.81,13.13-9.81,4.25,0,8.65,1.16,11.9,3.09l-.77,2.32c-3.24-1.93-7.26-2.94-11.05-2.94-5.64,0-10.5,2.24-10.5,7.26,0,11.05,23.56,4.02,23.56,17.54,0,7.26-6.88,10.12-14.45,10.12-4.4,0-9.04-1-12.67-2.63l.77-2.32Z"
    />
    <path
      className="text"
      d="M132.33,324.97l8.5-.46.62-9.81h2.01v9.81h14.68v2.47h-14.68v23.1c0,6.64,3.17,8.96,7.49,8.96,3.01,0,5.33-1.08,6.95-2.24l.77,2.32c-2.16,1.39-4.48,2.39-7.65,2.39-5.56,0-10.2-3.01-10.2-11.43v-23.1h-8.5v-2.01Z"
    />
    <path
      className="text"
      d="M180.22,323.74c8.73,0,18.93,5.1,18.93,19s-10.2,19-18.93,19-18.93-5.1-18.93-19,10.2-19,18.93-19ZM180.22,326.21c-7.42,0-16.22,4.09-16.22,16.53s8.81,16.53,16.22,16.53,16.22-4.09,16.22-16.53-8.81-16.53-16.22-16.53Z"
    />
    <path
      className="text"
      d="M236.31,345.83h-24.41l-6.41,15.22h-3.01l20.16-47.51h2.94l20.16,47.51h-3.01l-6.41-15.22ZM212.98,343.2h22.17l-11.05-26.34-11.12,26.34Z"
    />
    <path
      className="text"
      d="M282.27,359.74c-3.55,1.24-8.03,2.01-11.97,2.01-10.2,0-19.7-5.1-19.7-18.85s10.04-19.16,19.93-19.16c3.71,0,7.03.7,9.12,1.7v-14.6h2.63v48.9ZM270.38,359.27c2.47,0,6.33-.31,9.27-1.47v-29.59c-1.39-.69-4.33-2.01-9.19-2.01-8.5,0-17.15,3.94-17.15,16.76s8.27,16.3,17.07,16.3Z"
    />
    <path
      className="text"
      d="M292.01,326.91c2.7-1.55,7.26-3.17,13.67-3.17,4.25,0,8.58.7,11.36,4.17,3.71-2.55,7.72-4.17,13.98-4.17,6.8,0,13.9,1.93,13.9,14.52v22.79h-2.63v-22.79c0-11.12-5.79-12.05-11.2-12.05-4.71,0-8.73.7-12.75,3.94,1,2.01,1.47,4.71,1.47,8.11v22.79h-2.63v-22.79c0-11.12-5.72-12.05-11.28-12.05-5.02,0-8.5.77-11.28,2.55v32.29h-2.63v-34.14Z"
    />
    <path
      className="text"
      d="M355.28,309.06c1.24,0,2.09.93,2.09,2.16,0,1.16-.85,2.09-2.09,2.09s-2.16-.93-2.16-2.09c0-1.24.93-2.16,2.16-2.16ZM353.97,324.51h2.63v36.54h-2.63v-36.54Z"
    />
    <path
      className="text"
      d="M366.33,326.91c2.86-1.55,7.96-3.17,15.14-3.17,7.8,0,16.53,1.93,16.53,15.3v22.02h-2.63v-22.02c0-11.82-7.42-12.82-13.67-12.82-5.79,0-9.73.85-12.75,2.63v32.21h-2.63v-34.14Z"
    />
    <path
      className="primary"
      d="M61.23,294.49c-2.38,0-4.32-1.93-4.32-4.31v-115.64c0-2.38,1.93-4.31,4.32-4.31s4.32,1.93,4.32,4.31v115.64c0,2.38-1.93,4.31-4.32,4.31Z"
    />
    <path
      className="primary"
      d="M104.67,294.49c-2.38,0-4.32-1.93-4.32-4.31V109.82c0-2.38,1.93-4.31,4.32-4.31s4.32,1.93,4.32,4.31v180.36c0,2.38-1.93,4.31-4.32,4.31Z"
    />
    <path
      className="primary"
      d="M148.39,294.49c-2.38,0-4.32-1.93-4.32-4.31v-147.85c0-2.38,1.93-4.31,4.32-4.31s4.32,1.93,4.32,4.31v147.85c0,2.38-1.93,4.31-4.32,4.31Z"
    />
    <path
      className="primary"
      d="M191.83,294.49c-2.38,0-4.32-1.93-4.32-4.31v-69.33c0-2.38,1.93-4.31,4.32-4.31s4.32,1.93,4.32,4.31v69.33c0,2.38-1.93,4.31-4.32,4.31Z"
    />
  </svg>
);
