import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useState } from 'react';
import { CreateAdminUserWizardContext } from '../createAdminUser.wizard';

export const CredentialsStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreateAdminUserWizardContext>) => {
  const [passwordValue, setPasswordValue] = useState('');

  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'username',
            label: 'username',
            rules: [{ required: true }],
          }}
        >
          <Input
            config={{ placeholder: 'Jhon@snow.ch' }}
            onChange={(value: string) => setPasswordValue(value)}
            data={{ defaultValue: passwordValue }}
          />
        </Form.Item>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'password',
            label: 'password',
            rules: [{ required: true }],
          }}
        >
          <Input config={{ placeholder: 'password' }} />
        </Form.Item>
      </Form.Structure>
    </Form>
  );
};
