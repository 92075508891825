import { gql } from '@apollo/client';

export type DeleteControlUsersResponse = {
  controlUser: {
    _id: string;
  };
};

export type DeleteControlUsersParams = {
  input: {
    userId: string;
  };
};

export const deleteControlUsersGql = gql`
  mutation deleteControlUsers($input: DeleteControlUserDto!) {
    deleteControlUsers(input: $input) {
      controlUser {
        _id
      }
    }
  }
`;
