import { FilterExpanded, requestGQL } from '@gimlite/watermelon';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { contractsGql } from '../gql/contracts.gql';
import { searchRender } from '../projection/contracts.render';

export const ContractsSearch = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const [search, setSearch] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { limit: 15, ...getParamsUrl },
          gql: contractsGql,
          render: (res) => searchRender(res),
        });

        setSearch(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['filter'], ['table']],
          rows: ['min-content', '1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'filter' }}>
          <FilterExpanded
            data={{ value: getParamsUrl }}
            handleEvent={{
              submit: (data: any) => {
                setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
              },
            }}
          >
            <FilterExpanded.Fields></FilterExpanded.Fields>
            <FilterExpanded.Actions></FilterExpanded.Actions>
          </FilterExpanded>
        </Zone.Area>

        <Zone.Area config={{ area: 'table' }}>
          <Widget config={{ title: 'Liste des contrats bancaires' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  console.log({ value });
                },
              }}
              data={search}
              config={{
                columns: [
                  {
                    title: 'Consumer',
                    key: 'consumer',
                  },
                  {
                    title: 'Tag',
                    key: 'tag',
                  },
                  {
                    title: 'PSP',
                    key: 'psp',
                  },
                  {
                    title: 'Updated At',
                    key: 'updatedAt',
                  },
                ],
              }}
            />
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
