import {
  Button,
  FilterExpanded,
  Popover,
  Row,
  Space,
  notifService,
  requestGQL,
  useTranslation,
  wizardService,
} from '@gimlite/watermelon';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { camerasGql } from '../gql/cameras.gql';
import {
  DeleteCameraParams,
  DeleteCameraResponse,
  deleteCameraGql,
} from '../gql/deleteCamera.gql';

export const CameraSearch = () => {
  const navigate = useNavigate();
  const { t, lang } = useTranslation();
  const { getParamsUrl, setParamsUrl } = useMyUrl();

  const [search, setSearch] = useState<any>();
  const [refresh, setRefresh] = useState<string>();

  const deleteCameraFn = useCallback(
    async (id: string) => {
      try {
        (await requestGQL({
          params: {
            input: {
              cameraId: id,
            },
          } as DeleteCameraParams,
          gql: deleteCameraGql,
        })) as DeleteCameraResponse;

        notifService.send('NOTIF', {
          mode: 'success',
          content: t('watermelon-success'),
        });

        setRefresh(() => uuidv4());
      } catch {
        notifService.send('NOTIF', {
          mode: 'error',
          content: t('watermelon-SERVER_PROBLEM'),
        });
      }
    },
    [lang, getParamsUrl],
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { limit: 15, ...getParamsUrl },
          gql: camerasGql,
          render: (res) => res,
        });

        setSearch(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, refresh]);

  return (
    <Page>
      <Widget config={{ title: 'Liste des caméras' }}>
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields></FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{
                    text: 'Créer une caméra',
                    type: { value: 'button' },
                  }}
                  handleEvent={{
                    click: () =>
                      wizardService.send('SHOW_WIZARD', { id: 'createCamera' }),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(`/prestoscan/cameras/${value._id}`);
                },
              }}
              data={
                search
                  ? {
                      list: search.list.map((element: any) => ({
                        ...element,
                        action: (
                          <Row>
                            <Button
                              handleEvent={{
                                click: () => {
                                  wizardService.send('SHOW_WIZARD', {
                                    id: 'updateCamera',
                                    store: {
                                      camera: {
                                        _id: element._id,
                                        code: element.code,
                                        position: element.position,
                                        ups: element.ups,
                                        upsCode: element.upsCode,
                                        description: element.description,
                                      },
                                    },
                                  });
                                },
                              }}
                              config={{
                                text: 'Modifier',
                                color: 'warn',
                                size: 'small',
                                stopPropagation: true,
                              }}
                            ></Button>
                            <Space config={{ way: 'horizontal' }}></Space>

                            <Popover.Confirm
                              handleEvent={{
                                confirm: () => {
                                  deleteCameraFn(element._id);
                                },
                              }}
                              config={{
                                title: t('watermelon-importantAction'),
                                description: t(
                                  'watermelon-areYouSureAboutThis?',
                                ),
                              }}
                            >
                              <Button
                                config={{
                                  text: 'Supprimer',
                                  color: 'error',
                                  size: 'small',
                                }}
                              ></Button>
                            </Popover.Confirm>
                          </Row>
                        ),
                      })),
                      paging: search.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  {
                    key: '_id',
                    title: 'Id',
                  },
                  {
                    key: 'code',
                    title: 'Code',
                  },
                  {
                    key: 'position',
                    title: 'Position',
                  },
                  {
                    key: 'description',
                    title: 'Description',
                  },
                  {
                    key: 'upsCode',
                    title: 'UpsCode',
                  },
                  {
                    key: 'action',
                    title: 'Actions',
                  },
                ],
                actions: {
                  read: true,
                },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
