import { gql } from '@apollo/client';

export type CreateControlUserResponse = {
  controlUser: {
    _id: string;
  };
};

export type CreateControlUserParams = {
  input: {
    firstName: string;
    lastName: string;
    username: string;
    lang: string;
    password: string;
    fpsAgentId: string;
    fpsAgentName: string;
    upsId: string;
  };
};

export const createControlUserGql = gql`
  mutation createControlUsers($input: CreateControlUserDto!) {
    createControlUsers(input: $input) {
      controlUser {
        _id
      }
    }
  }
`;
