export const readConfigurationRender = (
  context,
  { computedConfig, ...rest },
) => {
  const computedConfigResolve = computedConfig ?? {};
  const computedConfigFormatted = JSON.stringify(
    computedConfigResolve,
    undefined,
    4,
  );

  return {
    computedConfig: computedConfigFormatted,
    ...rest,
  };
};
