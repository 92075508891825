import { requestGQL } from '@gimlite/watermelon';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { clientGql } from '../../gql/client.gql';
import { readRender } from '../../projection/clients.render';

export const ClientsDetailsFinance = () => {
  const { clientId } = useParams();
  const { getParamsUrl } = useMyUrl();
  const [read, setRead] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { ...getParamsUrl, clientId },
          gql: clientGql,
          render: (res) => readRender(res),
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, clientId]);

  return (
    <Zone
      config={{
        zones: [['table']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'table' }}>
        <Widget config={{ title: 'Payment records' }}>
          <Table<any>
            data={read?.paymentRecords}
            config={{
              columns: [
                {
                  title: 'consumer',
                  key: 'consumer',
                },
                {
                  title: 'amount',
                  key: 'amount',
                },
                {
                  title: 'currency',
                  key: 'currency',
                },
                {
                  title: 'type',
                  key: 'type',
                },
                {
                  title: 'paymentDate',
                  key: 'paymentDate',
                },
                {
                  title: 'segment',
                  key: 'segment',
                },
              ],
            }}
          />
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
