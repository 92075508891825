import { gql } from "@apollo/client";

export const searchCardsGql = gql`
    query cards(
        $type: String
        $commentSearch: String
        $cardIdSearch: String
        $customerId: String
        $page: Int
        $limit: Int
        $order: String
    ) {
        cards(
            type: $type
            commentSearch: $commentSearch
            cardIdSearch: $cardIdSearch
            customerId: $customerId
            page: $page
            limit: $limit
            order: $order
        ) {
            list {
                _id
                cardId
                type
                customerId
                dt
                pincode
                host
                status
                comment
            }
            paging {
                current
                count
                limit
            }
        }
    }
`