import { requestGQL } from '@gimlite/watermelon';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { cameraGql } from '../gql/camera.gql';
import { readRender } from '../projection/cameras.render';

export const CameraDetails = () => {
  const { cameraId } = useParams();
  const { getParamsUrl, setParamsUrl } = useMyUrl();

  const [read, setRead] = useState<any>();
  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { ...getParamsUrl, cameraId },
          gql: cameraGql,
          render: (res) => readRender(res),
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, cameraId]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['description']],
          rows: ['min-content'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'description' }}>
          <Widget config={{ title: 'Details' }}>
            <Description data={read?.details || []} />
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
