import { Link } from '@gimlite/router';
import { Description } from '@gimlite/watermelon';
import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { DateTime } from 'luxon';

const formatedRegistrationStatus = (reg) => {
  const status = {
    0: { text: 'Unregistered', status: 'error' },
    1: { text: 'Registered Home', status: 'success' },
    2: { text: 'Unregistered And Searching', status: 'error' },
    3: { text: 'Registration Denied', status: 'error' },
    4: { text: 'Registration Unknown', status: 'warn' },
    5: { text: 'Registered Roaming', status: 'success' },
  };

  return (
    <Badge
      config={{
        text: status[reg]?.text || '-',
        color: status[reg]?.status || 'basic',
      }}
    />
  );
};

export const formattedAlarms = (state) => {
  switch (state) {
    case 'up':
      return <Badge config={{ text: 'UP', color: 'success' }} />;
    case 'down':
      return <Badge config={{ text: 'DOWN', color: 'error' }} />;
    default:
      return state;
  }
};

export const readEventRender = ({
  alarms,
  modemSessions,
  serviceCardEvents,
  ...rest
}) => {
  const alarmsFormatted = alarms?.list.map(
    ({ key, alarm, startDate, extra, payload, stopDate, _id }) => {
      return {
        _id,
        $expandable:
          !!extra && extra.length ? (
            <Description
              data={extra?.map((data, index) => {
                return {
                  key: `extra-${index}`,
                  label: index + 1,
                  value: data,
                };
              })}
            />
          ) : undefined,
        terminalId: key,
        fragmentRef:
          payload && !!payload.fragmentRef ? (
            <Link to={`/adm/fragmentsByRef/${payload?.fragmentRef}`}>
              {payload?.fragmentRef?.split(':')?.[1]}
            </Link>
          ) : (
            <></>
          ),
        alarm: formattedAlarms(alarm),
        startDate: startDate
          ? DateTime.fromISO(startDate).toFormat('dd/MM/yy HH:mm:ss')
          : null,
        stopDate: stopDate
          ? DateTime.fromISO(stopDate).toFormat('dd/MM/yy HH:mm:ss')
          : null,
      };
    },
  );

  const modemSessionsFormatted = modemSessions?.list.map((element) => {
    const { _id, logDate, net, t } = element;

    return {
      _id,
      logDate: DateTime.fromISO(logDate).toFormat('dd/MM/yy HH:mm:ss'),
      technology: net?.rat ?? undefined,
      registrationStatus: formatedRegistrationStatus(net?.reg),
      operator:
        net?.opn && net?.oper
          ? `${net?.opn ?? '-'} / ${net?.oper ?? '-'}`
          : undefined,
      sim: net?.iccid,
      channel:
        net?.band && net?.chan
          ? `${net?.band ?? '-'} / ${net?.chan ?? '-'}`
          : undefined,
      rsrp: net?.rsrp ? `${net?.rsrp} dBm` : undefined,
      rsrq: net?.rsrq ? `${net?.rsrq} dB` : undefined,
      startup: t?.conn ? `${t?.conn} s` : undefined,
      transaction: t?.trx ? `${t?.trx} s` : undefined,
      suspending: t?.susp ? `${t?.susp} s` : undefined,
      quality: net?.rsrp ? (
        net?.rsrp >= 0 ? (
          <Badge config={{ color: 'disabled', text: 'Unknown' }} />
        ) : net?.rsrp <= -100 ? (
          <Badge config={{ color: 'error', text: 'Poor' }} />
        ) : net?.rsrp <= -90 ? (
          <Badge config={{ color: 'warn', text: 'Medium' }} />
        ) : net?.rsrp <= -80 ? (
          <Badge config={{ color: 'success', text: 'Good' }} />
        ) : (
          <Badge config={{ color: 'success', text: 'Excellent' }} />
        )
      ) : undefined,
    };
  });

  const serviceCardEventsFormatted = serviceCardEvents?.list.map(
    ({ logDate, UID, Status, ...other }) => {
      return {
        ...other,
        UID: <pre>{UID}</pre>,
        Status: Status ? (
          'ok' === Status ? (
            <Badge config={{ color: 'success', text: Status }} />
          ) : (
            <Badge config={{ color: 'warn', text: Status }} />
          )
        ) : undefined,
        logDate: DateTime.fromISO(logDate).toFormat('dd/MM/yy HH:mm:ss'),
      };
    },
  );

  return {
    alarms: alarmsFormatted
      ? { paging: alarms.paging, list: alarmsFormatted }
      : undefined,
    modemSessions: modemSessionsFormatted
      ? {
          paging: modemSessions.paging,
          list: modemSessionsFormatted,
        }
      : undefined,
    serviceCardEvents: serviceCardEventsFormatted
      ? {
          paging: serviceCardEvents.paging,
          list: serviceCardEventsFormatted,
        }
      : undefined,
    ...rest,
  };
};
