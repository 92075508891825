import { wizardService } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useSelector } from '@xstate/react';
import { useEffect } from 'react';
import { SingleStep } from './steps/single.step';
import { updateSiteService } from './updateSite.machine';

export type UpdateSiteWizardContext = any;

export const UpdateSiteWizard = () => {
  const result = useSelector(
    updateSiteService,
    ({ context }: any) => context.result,
  );

  const siteId = useSelector(
    updateSiteService,
    ({ context }: any) => context.siteId,
  );

  useEffect(() => {
    if (result) {
      console.log('RESULT', { result });
    }
  }, [result]);

  return (
    <Wizard<UpdateSiteWizardContext>
      config={{
        title: '',
        withValidation: {
          state: result,
          success: 'Ça marche nickel !',
          error: 'Ça marche pas !',
        },
      }}
      data={{
        defaultContext: undefined,
      }}
      handleEvent={{
        done: async (data: any) => {
          const payload = { siteId, ...data };
          updateSiteService.send('UPDATE_SITE', {
            payload,
          });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item config={{ title: 'Site', component: SingleStep }} />
    </Wizard>
  );
};
