import { AutoComplete, notifService, requestGQL } from '@gimlite/watermelon';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useCallback } from 'react';
import { AddParkingInControlUserWizardContext } from '../addParkingInControlUser.wizard';
import { UpsResponse, upsGql, upsParams } from '../gql/ups.gql';
import { UpsListResponse, upsListGql, upsListParams } from '../gql/upsList.gql';

export const CameraStep = ({
  context: { user, listParking, selectParking },
  validate,
  submit,
}: WizardItemType.Config.Component<AddParkingInControlUserWizardContext>) => {
  const searchParkingsFn = useCallback(
    async ({ codePattern }: { codePattern: string }) => {
      try {
        const response = (await requestGQL({
          params: { codePattern } as upsListParams,
          gql: upsListGql,
        })) as UpsListResponse;

        submit({ listParking: response.list });
      } catch (error) {
        notifService.send('NOTIF', {
          content: error,
          mode: 'error',
        });
        submit({ listParking: [] });
      }
    },
    [],
  );

  const readParkingFn = useCallback(async ({ upsId }: { upsId: string }) => {
    try {
      const { _id } = (await requestGQL({
        params: { upsId } as upsParams,
        gql: upsGql,
      })) as UpsResponse;

      submit({
        selectParking: {
          _id,
        },
      });
    } catch (error) {
      notifService.send('NOTIF', {
        content: 'SERVER_PROBLEM',
        mode: 'error',
      });

      submit({
        selectParking: {
          _id: null,
        },
      });
    }
  }, []);

  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {},
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure>
        <Form.Item
          config={{
            name: '_id',
            label: 'UPS',
            rules: [{ required: true }],
          }}
        >
          <AutoComplete
            handleEvent={{
              input: (value) => {
                searchParkingsFn({
                  codePattern: value,
                });
              },
              select(item) {
                if (item?.value) {
                  readParkingFn({ upsId: item.value });
                }
              },
            }}
            data={{
              items: listParking
                .filter(({ _id }) => !user.ups.includes(_id))
                .map(({ code, _id }) => ({
                  value: _id,
                  label: code,
                })),
            }}
            config={{
              search: {
                caseSensitive: false,
                accentSensitive: false,
                pattern: 'start',
              },

              placeholder: 'plan-les-ouates',
            }}
          />
        </Form.Item>
      </Form.Structure>
    </Form>
  );
};
