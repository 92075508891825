import { gql } from '@apollo/client';

export const transactionsGql = gql`
  query transactions(
    $reference: String
    $page: Int
    $limit: Int
    $order: String
  ) {
    transactions(
      reference: $reference
      page: $page
      limit: $limit
      order: $order
    ) {
      list {
        _id
        type
        consumer
        reference
        contractTag
        psp
        amount
        currency
        status
        pspTransactionDate
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
