import { Empty, useRootStore } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import MainUse from './main';
import { AdminUsersDetails } from './pages/admin-users/view/admin-users.details';
import { AdminUsersSearch } from './pages/admin-users/view/admin-users.search';
import { BucketDetails } from './pages/buckets/view/bucket.details';
import { BucketsSearch } from './pages/buckets/view/buckets.search';
import { FragmentDetails } from './pages/buckets/view/fragments.details';
import { FragmentSearch } from './pages/buckets/view/fragments.search';
import { CameraDetails } from './pages/cameras/view/cameras.details';
import { CameraSearch } from './pages/cameras/view/cameras.search';
import { ClientsDetails } from './pages/clients/view/clients.details';
import { ClientsSearch } from './pages/clients/view/clients.search';
import { ControlUsersDetails } from './pages/control-users/view/control-users.details';
import { ControlUsersSearch } from './pages/control-users/view/control-users.search';
import { MailsSearch } from './pages/mails/view/mails.search';
import { Monitoring } from './pages/monitoring/view/monitoring';
import { OperatorsDetails } from './pages/operators/view/operators.details';
import { OperatorsSearch } from './pages/operators/view/operators.search';
import { ParkingRightsDetails } from './pages/parking-rights/view/parking-rights.details';
import { ParkingRightsList } from './pages/parking-rights/view/parking-rights.search';
import { ParkingsSearch } from './pages/parkings/view/parkings.search';
import { ContractsSearch } from './pages/payment-contracts/view/contracts.search';
import { ProfilDetails } from './pages/profil/view/profil.details';
import { ServiceCards } from './pages/service-cards/service-cards';
import { SessionsSearch } from './pages/sessions/view/sessions.search';
import { SitesDetailsConfig } from './pages/sites/view/categorys/config.category';
import { SitesDetailsEnergy } from './pages/sites/view/categorys/energy.category';
import { SitesDetailsEvent } from './pages/sites/view/categorys/event.category';
import { SitesDetailsFeedback } from './pages/sites/view/categorys/feedback.category';
import { SitesDetailsFinance } from './pages/sites/view/categorys/finance.category';
import { SitesDetailsFragment } from './pages/sites/view/categorys/fragment.category';
import { SitesDetailsGlobal } from './pages/sites/view/categorys/global.category';
import { SitesDetails } from './pages/sites/view/sites.details';
import { SitesSearch } from './pages/sites/view/sites.search';
import { SmsSearch } from './pages/sms/view/sms.search';
import { TransactionsSearch } from './pages/transactions/view/transactions.search';
import { TranslationsDetails } from './pages/translations/view/translations.details';
import { TranslationsSearch } from './pages/translations/view/translations.search';
import { UpsDetails } from './pages/ups/view/ups.details';
import { UpsSearch } from './pages/ups/view/ups.search';
import { ClientsDetailsGlobal } from './pages/clients/view/category/global.category';
import { ClientsDetailsFinance } from './pages/clients/view/category/finance.category';
import { ClientsDetailsUps } from './pages/clients/view/category/ups.category';
import { LoginPage } from './pages/login/view/login.page';

export const CustomRouter = observer(() => {
  const { AuthStore } = useRootStore();

  const sitemap = useMemo((): RouteObject[] => {
    return AuthStore.token
      ? [
          {
            path: '*',
            element: <Navigate to={`/onstreet/parking-rights`} replace />,
          },
          {
            path: '/',
            element: <MainUse />,
            children: [
              {
                index: true,
                element: <Navigate to={`/onstreet/parking-rights`} replace />,
              },
              {
                path: 'onstreet',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate
                        to={`${window.location.pathname}/parking-rights`}
                        replace
                      />
                    ),
                  },
                  {
                    path: 'parking-rights',
                    element: <ParkingRightsList />,
                  },
                  {
                    path: 'parking-rights/:parkingRightId',
                    element: <ParkingRightsDetails />,
                  },
                  {
                    path: 'clients',
                    element: <ClientsSearch />,
                  },
                  {
                    path: 'clients/:clientId',
                    element: <ClientsDetails />,
                    children: [
                      {
                        index: true,
                        element: (
                          <Navigate
                            to={`${window.location.pathname}/global`}
                            replace
                          />
                        ),
                      },
                      {
                        path: 'global',
                        element: <ClientsDetailsGlobal />,
                      },
                      {
                        path: 'ups',
                        element: <ClientsDetailsUps />,
                      },
                      {
                        path: 'finance',
                        element: <ClientsDetailsFinance />,
                      },
                    ],
                  },
                  {
                    path: 'sites',
                    element: <SitesSearch />,
                  },
                  {
                    path: 'sites/:siteId',
                    element: <SitesDetails />,
                    children: [
                      {
                        index: true,
                        element: (
                          <Navigate
                            to={`${window.location.pathname}/global`}
                            replace
                          />
                        ),
                      },
                      {
                        path: 'global',
                        element: <SitesDetailsGlobal />,
                      },
                      {
                        path: 'finance',
                        element: <SitesDetailsFinance />,
                      },
                      {
                        path: 'energy',
                        element: <SitesDetailsEnergy />,
                      },
                      {
                        path: 'event',
                        element: <SitesDetailsEvent />,
                      },
                      {
                        path: 'fragment',
                        element: <SitesDetailsFragment />,
                      },
                      {
                        path: 'feedback',
                        element: <SitesDetailsFeedback />,
                      },
                      {
                        path: 'config',
                        element: <SitesDetailsConfig />,
                      },
                    ],
                  },
                  {
                    path: 'ups',
                    element: <UpsSearch />,
                  },
                  {
                    path: 'ups/:upsId',
                    element: <UpsDetails />,
                  },
                ],
              },
              {
                path: 'prestoscan',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate
                        to={`${window.location.pathname}/users`}
                        replace
                      />
                    ),
                  },
                  {
                    path: 'users',
                    element: <ControlUsersSearch />,
                  },
                  {
                    path: 'users/:userId',
                    element: <ControlUsersDetails />,
                  },
                  {
                    path: 'cameras',
                    element: <CameraSearch />,
                  },
                  {
                    path: 'cameras/:cameraId',
                    element: <CameraDetails />,
                  },
                ],
              },
              {
                path: 'offstreet',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate
                        to={`${window.location.pathname}/operators`}
                        replace
                      />
                    ),
                  },
                  {
                    path: 'operators',
                    element: <OperatorsSearch />,
                  },
                  {
                    path: 'operators/:operatorId',
                    element: <OperatorsDetails />,
                  },
                  {
                    path: 'parkings',
                    element: <ParkingsSearch />,
                  },
                  {
                    path: 'sessions',
                    element: <SessionsSearch />,
                  },
                ],
              },
              {
                path: 'payments',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate
                        to={`${window.location.pathname}/contracts`}
                        replace
                      />
                    ),
                  },
                  {
                    path: 'contracts',
                    element: <ContractsSearch />,
                  },
                  {
                    path: 'transactions',
                    element: <TransactionsSearch />,
                  },
                ],
              },
              {
                path: 'adm',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate
                        to={`${window.location.pathname}/translations`}
                        replace
                      />
                    ),
                  },
                  {
                    path: 'translations',
                    element: <TranslationsSearch />,
                  },
                  {
                    path: 'translations/:dictionaryKey',
                    element: <TranslationsDetails />,
                  },
                  {
                    path: 'buckets',
                    element: <BucketsSearch />,
                  },
                  {
                    path: 'buckets/:bucketId',
                    element: <BucketDetails />,
                  },
                  {
                    path: 'fragments',
                    element: <FragmentSearch />,
                  },
                  {
                    path: 'fragmentsByRef/:fragmentRef',
                    element: <FragmentDetails />,
                  },
                  {
                    path: 'fragments/:fragmentId',
                    element: <FragmentDetails />,
                  },
                  {
                    path: 'admin-users',
                    element: <AdminUsersSearch />,
                  },
                  {
                    path: 'admin-users/:userId',
                    element: <AdminUsersDetails />,
                  },
                  {
                    path: 'service-cards',
                    element: <ServiceCards />,
                  },
                  {
                    path: 'monitoring',
                    element: <Monitoring />,
                  },
                  {
                    path: 'monitoring/:userId',
                    element: <AdminUsersDetails />,
                  },
                ],
              },
              {
                path: 'mails',
                element: <MailsSearch />,
              },
              {
                path: 'sms',
                element: <SmsSearch />,
              },
              {
                path: 'settings',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate
                        to={`${window.location.pathname}/profil`}
                        replace
                      />
                    ),
                  },
                  {
                    path: 'profil',
                    element: <ProfilDetails />,
                  },
                ],
              },
            ],
          },
        ]
      : [
          {
            path: '*',
            element: <Navigate to={`/login`} replace />,
          },
          {
            path: '/login',
            element: <LoginPage />,
          },
        ];
  }, [AuthStore.token]);

  return (
    <RouterProvider router={createBrowserRouter(sitemap)}></RouterProvider>
  );
});
