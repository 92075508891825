import { requestGQL } from '@gimlite/watermelon';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Tab } from '@gimlite/watermelon/components/tab/tab.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { translationGql } from '../gql/translation.gql';
import { updateTranslationGql } from '../gql/updateTranslation.gql';
import { readRender } from '../projection/translations.render';

export const TranslationsDetails = () => {
  const { dictionaryKey } = useParams();
  const [read, setRead] = useState<any>();
  const [refresh, setRefresh] = useState<string>();
  const [defaultLang, setDefaultLang]: any = useState({ list: undefined });

  const UPDATE_TRANSLATION = useCallback(async (params: any) => {
    try {
      const response = await requestGQL({
        params,
        gql: updateTranslationGql,
        render: (res) => res,
      });

      setRefresh(() => uuidv4());

      return response;
    } catch (err) {
      console.log({ err });
    } finally {
      console.log('loading finish');
    }
  }, []);

  useEffect(() => {
    if (dictionaryKey) {
      (async () => {
        try {
          const response = await requestGQL({
            params: { dictionaryKey },
            gql: translationGql,
            render: (res) => readRender(res),
          });

          setRead(() => response);
        } catch (err) {
          console.log({ err });
        } finally {
          console.log('loading finish');
        }
      })();
    }
  }, [dictionaryKey, refresh]);

  useEffect(() => {
    setDefaultLang(read?.[0]);
  }, [read]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['details']],
          rows: ['1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'details' }}>
          <Widget.Group config={{ title: 'Liste des traductions' }}>
            {defaultLang?.list?.length > 0 && (
              <Zone
                config={{
                  zones: [['tabs'], ['table']],
                  rows: ['min-content', '1fr'],
                  columns: ['1fr'],
                }}
              >
                <Zone.Area config={{ area: 'tabs' }}>
                  <Widget>
                    <Tab
                      data={{ items: read, selected: defaultLang?.key }}
                      handleEvent={{
                        option: (value: any) => {
                          const findIndexOption = read.findIndex(
                            (translation: any) => translation?.key === value,
                          );
                          setDefaultLang(read[findIndexOption]);
                        },
                      }}
                    />
                  </Widget>
                </Zone.Area>
                <Zone.Area config={{ area: 'table' }}>
                  <Widget>
                    <Table<any>
                      handleEvent={{
                        update: (value: any) => {
                          const { translationKey, translationValue } = value;

                          UPDATE_TRANSLATION({
                            translations: {
                              [translationKey]: translationValue,
                            },
                            lang: defaultLang?.key,
                            dictionaryKey: dictionaryKey,
                          });
                        },
                      }}
                      data={{
                        list: defaultLang?.list,
                        //TODO To fix as paging is curently required
                        paging: { current: 1, count: 1, limit: 1 },
                      }}
                      config={{
                        pagination: 'none',
                        columns: [
                          {
                            title: 'Etat',
                            key: 'state',
                          },
                          {
                            title: 'Traduction',
                            key: 'translationKey',
                          },
                          {
                            title: 'Valeur',
                            key: 'translationValue',
                          },
                        ],
                        actions: {
                          update: ['translationValue'],
                        },
                      }}
                    />
                  </Widget>
                </Zone.Area>
              </Zone>
            )}
          </Widget.Group>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
