import { Link } from '@gimlite/router';
import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { DateTime } from 'luxon';

export const readFragmentsRender = ({ fragments, ...rest }) => {
  const fragmentsFormated = fragments?.list.map((fragment) => {
    return {
      ...fragment,
      fragmentRef: !!fragment.fragmentRef ? (
        <Link to={`/adm/fragments/${fragment._id}`}>
          {fragment.fragmentRef.split(':')?.[1]}
        </Link>
      ) : (
        fragment.bucketId
      ),
      bucketId:
        !!fragment.bucketId && fragment.bucketId !== 'none' ? (
          <Link to={`/adm/buckets/${fragment.bucketId}`}>
            {fragment.bucketId}
          </Link>
        ) : (
          fragment.bucketId
        ),
      logDate: DateTime.fromISO(fragment?.logDate).toFormat(
        'dd/MM/yy HH:mm:ss',
      ),
      state:
        fragment.state !== 'PROCESSED' ? (
          <Badge config={{ color: 'error', text: fragment.state }} />
        ) : (
          <Badge config={{ color: 'success', text: fragment.state }} />
        ),
    };
  });

  return {
    fragments: fragmentsFormated
      ? {
          list: fragmentsFormated,
          paging: fragments.paging,
        }
      : undefined,
    ...rest,
  };
};
