import { Icon } from '@gimlite/watermelon';
import { NavInline } from '@gimlite/watermelon/components/nav/nav.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useMemo } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

export const ClientsDetails = () => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { getParamsUrlString } = useMyUrl();

  const currentCategory = useMemo(() => {
    const pathnameDecompose = window.location.pathname.split('/');
    return pathnameDecompose[pathnameDecompose.length - 1];
  }, [window.location.pathname]);

  return (
    <Page>
      <NavInline
        config={{ selected: currentCategory }}
        data={[
          {
            label: 'Global',
            key: 'global',
            icon: <Icon config={{ type: 'faGlobeSolid' }} />,
          },
          {
            label: 'Ups',
            key: 'ups',
            icon: <Icon config={{ type: 'faSquareParkingSolid' }} />,
          },
          {
            label: 'Finance',
            key: 'finance',
            icon: <Icon config={{ type: 'faSackDollarSolid' }} />,
          },
        ]}
        handleEvent={{
          navigation: (value: string) => {
            navigate(
              `/onstreet/clients/${clientId}/${value}?${getParamsUrlString}`,
            );
          },
        }}
      />

      <Outlet />
    </Page>
  );
};
