import {
  FilterExpanded,
  Popover,
  Row,
  Space,
  notifService,
  requestGQL,
  useTranslation,
  wizardService,
} from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { controlUsersGql } from '../../../wizards/createPrestoScan/gql/control-users.gql';
import {
  DeleteControlUsersParams,
  DeleteControlUsersResponse,
  deleteControlUsersGql,
} from '../gql/deleteControlUser.gql';

export const ControlUsersSearch = () => {
  const navigate = useNavigate();
  const { t, lang } = useTranslation();
  const { getParamsUrl, setParamsUrl } = useMyUrl();

  const [search, setSearch] = useState<any>();
  const [refresh, setRefresh] = useState<string>();

  const deleteControlUserFn = useCallback(
    async (id: string) => {
      try {
        (await requestGQL({
          params: {
            input: {
              userId: id,
            },
          } as DeleteControlUsersParams,
          gql: deleteControlUsersGql,
        })) as DeleteControlUsersResponse;

        notifService.send('NOTIF', {
          mode: 'success',
          content: t('watermelon-success'),
        });

        setRefresh(() => uuidv4());
      } catch {
        notifService.send('NOTIF', {
          mode: 'error',
          content: t('watermelon-SERVER_PROBLEM'),
        });
      }
    },
    [lang, getParamsUrl],
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { limit: 15, ...getParamsUrl },
          gql: controlUsersGql,
          render: (res) => res,
        });

        setSearch(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, refresh]);

  return (
    <Page>
      <Widget config={{ title: 'Listes des agents' }}>
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields></FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Créer un agent', type: { value: 'button' } }}
                  handleEvent={{
                    click: () =>
                      wizardService.send('SHOW_WIZARD', {
                        id: 'createControlUser',
                      }),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(`/prestoscan/users/${value._id}`);
                },
              }}
              data={
                search
                  ? {
                      list: search.list.map((element: any) => ({
                        ...element,
                        upsLength: element.ups.length,
                        action: (
                          <Row>
                            <Button
                              handleEvent={{
                                click: () => {
                                  wizardService.send('SHOW_WIZARD', {
                                    id: 'updateControlUser',
                                    store: {
                                      user: {
                                        firstName: element.firstName,
                                        lastName: element.lastName,
                                        fpsAgentId: element.fpsAgentId,
                                        fpsAgentName: element.fpsAgentName,
                                        lang: element.lang,
                                        ups: element.ups,
                                        _id: element._id,
                                      },
                                      credentials: {
                                        username: element.username,
                                        password: null,
                                      },
                                    },
                                  });
                                },
                              }}
                              config={{
                                text: 'Modifier',
                                color: 'warn',
                                size: 'small',
                                stopPropagation: true,
                              }}
                            ></Button>
                            <Space config={{ way: 'horizontal' }}></Space>

                            <Popover.Confirm
                              handleEvent={{
                                confirm: () => {
                                  deleteControlUserFn(element._id);
                                },
                              }}
                              config={{
                                title: t('watermelon-importantAction'),
                                description: t(
                                  'watermelon-areYouSureAboutThis?',
                                ),
                              }}
                            >
                              <Button
                                config={{
                                  text: 'Supprimer',
                                  color: 'error',
                                  size: 'small',
                                }}
                              ></Button>
                            </Popover.Confirm>
                          </Row>
                        ),
                      })),
                      paging: search.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  {
                    title: 'username',
                    key: 'username',
                  },
                  {
                    title: 'FirstName',
                    key: 'firstName',
                  },
                  {
                    title: 'LastName',
                    key: 'lastName',
                  },
                  {
                    title: 'Fps AgentId',
                    key: 'fpsAgentId',
                  },
                  {
                    title: 'Fps AgentName',
                    key: 'fpsAgentName',
                  },
                  {
                    title: "Nombre d'UPS",
                    key: 'upsLength',
                  },
                  {
                    key: 'action',
                    title: 'Actions',
                  },
                ],
                actions: {
                  read: true,
                },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
