export const versionsEntity = `
versions {
    _id
    terminalId
    name
    version
    logDate
    siteId
    createdAt
    updatedAt
  }`;
