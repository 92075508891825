import { requestGQL } from '@gimlite/watermelon';
import { Code } from '@gimlite/watermelon/components/code/code.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { bucketGql } from '../gql/bucket.gql';

export const BucketDetails = () => {
  const { bucketId } = useParams();
  const [read, setRead] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { bucketId: bucketId },
          gql: bucketGql,
          render: (res) => res,
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [bucketId]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['code']],
          rows: ['1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'code' }}>
          <Widget config={{ title: 'Bucket debug' }}>
            <Code
              data={{ defaultValue: JSON.stringify(read, null, 4) }}
              config={{ yMax: '100%', lang: 'json', readOnly: true }}
            />
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
