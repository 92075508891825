import { gql } from '@apollo/client';

export const createPmsTechnicalUserGql = gql`
  mutation createTechnicalUsers($input: CreateTechnicalUserDto!) {
    createTechnicalUsers(input: $input) {
      technicalUser {
        _id
        username
        firstName
        lang
        lastName
      }
    }
  }
`;
