import { gql } from '@apollo/client';

export const adminUsersGql = gql`
  query adminUsers($page: Int, $limit: Int, $order: String) {
    adminUsers(page: $page, limit: $limit, order: $order) {
      list {
        _id
        state
        credentials {
          value
          type
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
