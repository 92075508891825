import { requestGQL, Space, Table, useMyUrl } from '@gimlite/watermelon';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { siteEventGql } from '../../gql/tab/event.gql';
import { readEventRender } from '../../projection/event.render';
import { siteDetailsDefaultParams, SitesDetailsFilter } from '../sites.details';

export const SitesDetailsEvent = () => {
  const [read, setRead] = useState<any>();

  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const { siteId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { ...siteDetailsDefaultParams, ...getParamsUrl, siteId },
          gql: siteEventGql,
          render: (res) => readEventRender(res),
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, siteDetailsDefaultParams, siteId]);

  return (
    <Col>
      <SitesDetailsFilter />
      <Space config={{ count: 2 }} />

      <Widget config={{ title: 'Alarmes' }}>
        <Table<any>
          handleEvent={{
            paging: (value: any) => {
              setParamsUrl({
                ...getParamsUrl,
                alarmsPage: value.page,
                alarmsLimit: value.limit,
              });
            },
          }}
          data={read?.alarms}
          config={{
            columns: [
              {
                title: 'Alarm',
                key: 'alarm',
              },
              {
                title: 'Ref.',
                key: 'fragmentRef',
              },
              {
                title: 'StartDate',
                key: 'startDate',
              },
              {
                title: 'StopDate',
                key: 'stopDate',
              },
            ],
          }}
        />
      </Widget>
      <Space config={{ count: 2 }} />

      <Widget config={{ title: 'Modem' }}>
        <Table<any>
          handleEvent={{
            paging: (value: any) => {
              setParamsUrl({
                ...getParamsUrl,
                modemSessionsPage: value.page,
                modemSessionsLimit: value.limit,
              });
            },
          }}
          data={read?.modemSessions}
          config={{
            columns: [
              {
                title: 'Datetime',
                key: 'logDate',
              },
              {
                title: 'Technology',
                key: 'technology',
              },
              {
                title: 'Operator',
                key: 'operator',
              },
              {
                title: 'SIM',
                key: 'sim',
              },
              {
                title: 'Channel',
                key: 'channel',
              },
              {
                title: 'RSRP',
                key: 'rsrp',
              },
              {
                title: 'RSRQ',
                key: 'rsrq',
              },
              {
                title: 'Startup',
                key: 'startup',
              },
              {
                title: 'Transaction',
                key: 'transaction',
              },
              {
                title: 'Suspending',
                key: 'suspending',
              },
              {
                title: 'Registration',
                key: 'registrationStatus',
              },

              {
                title: 'Quality',
                key: 'quality',
              },
            ],
          }}
        />
      </Widget>
      <Space config={{ count: 2 }} />

      <Widget config={{ title: 'Service Card Event' }}>
        <Table<any>
          handleEvent={{
            paging: (value: any) => {
              setParamsUrl({
                ...getParamsUrl,
                serviceCardEventPage: value.page,
                serviceCardEventLimit: value.limit,
              });
            },
          }}
          data={read?.serviceCardEvents}
          config={{
            columns: [
              {
                title: 'Date',
                key: 'logDate',
              },
              {
                title: 'Card UID',
                key: 'UID',
              },
              {
                title: 'Type',
                key: 'Type',
              },
              {
                title: 'Status',
                key: 'Status',
              },
              {
                title: 'Details',
                key: 'Details',
              },
            ],
          }}
        />
      </Widget>
    </Col>
  );
};
