import { gql } from '@apollo/client';

export const translationGql = gql`
  query translation($dictionaryKey: String!) {
    translation(dictionaryKey: $dictionaryKey) {
      _id
      key
      languages
      documents {
        lang
        key
        translations
        createdAt
      }
      createdAt
    }
  }
`;
