import EditorMonaco from '@monaco-editor/react';
import { useEffect } from 'react';
import { useRootStore } from '../../hook/useRootStore.hook';
import { SkeletonBlock } from '../skeleton/skeleton.component';
import './code.component.scss';

export declare namespace CodeType {
  type Props = {
    className?: string;
    handleEvent?: {
      isValid?: (value: boolean) => void;
      input?: (value: string) => void;
    };
    data?: {
      defaultValue?: string;
    };
    config?: {
      lang?: string;
      placeholder?: string;
      minLength?: number;
      maxLength?: number;
      resize?: boolean;
      yMax?: string | number;
      readOnly?: boolean;
    };
  };
}

export const Code = ({
  handleEvent: { isValid, input } = {},
  config: { lang = 'text', yMax = 'initial', readOnly = false } = {},
  data: { defaultValue } = {},
  className = '',
}: CodeType.Props) => {
  const { ThemeStore } = useRootStore();

  useEffect(() => {
    if (input && defaultValue) {
      try {
        if (lang === 'json') JSON.parse(defaultValue);
        isValid?.(true);
        input?.(defaultValue);
      } catch {}
    }
  }, []);

  return (
    <div
      style={{
        height: yMax,
      }}
      className={`code ${className}`}
    >
      <EditorMonaco
        onChange={(value: string | undefined) => {
          try {
            if (value) {
              if (lang === 'json') JSON.parse(value);
              input && input(value);
            }
          } catch (error) {
            console.error(error);
          }
        }}
        onValidate={(valid) => {
          isValid && isValid(valid.length === 0);
        }}
        loading={<SkeletonBlock />}
        options={{
          scrollbar: { verticalScrollbarSize: 4 },
          minimap: { enabled: false },
          disableLayerHinting: true,
          cursorSurroundingLinesStyle: 'all',
          readOnly: readOnly,
          //   lineDecorationsWidth: 8,
          //   cursorBlinking: 'solid',
          //   cursorSmoothCaretAnimation: 'on',
          //   renderOverviewRuler: false,
          //   colorDecoratorsLimit: 80,
          //   cursorWidth: 5,
        }}
        theme={ThemeStore.theme === 'dark' ? 'vs-dark' : 'vs-light'}
        height={yMax}
        language={lang}
        value={defaultValue}
      />
    </div>
  );
};
