import { gql } from '@apollo/client';
import { alarmsEntity } from '../entity/alarms.gql';
import { modemSessionsEntity } from '../entity/modemSessions.gql';
import { serviceCardEventsEntity } from '../entity/serviceCardEvents.gql';

export const siteEventGql = gql`
  query site(
    $siteId: String!
    $alarmsPage: Int
    $alarmsLimit: Int
    $modemSessionsPage: Int
    $modemSessionsLimit: Int
    $serviceCardEventPage: Int
    $serviceCardEventLimit: Int
    $startDate: String
    $stopDate: String
  ) {
    site(siteId: $siteId) {
        _id
        ${alarmsEntity}
        ${modemSessionsEntity}
        ${serviceCardEventsEntity}
    }
  }
`;
