import { gql } from '@apollo/client';

export const readSiteGql = gql`
  query site($siteId: String!) {
    site(siteId: $siteId) {
      _id
      name
      coordinates
      address1
      address2
      zipcode
      city
      country
      terminalId
    }
  }
`;
