import { Button, Col, Empty, Line, Row, Space } from '@gimlite/watermelon';
import { SkeletonBlock } from '@gimlite/watermelon/components/skeleton/skeleton.component';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';

export declare namespace BalanceAveragePowerChartType {
  type Props = {
    data: Array<BalanceAveragePowerChartType.Point>;
    config?: {
      startDate?: Date;
      endDate?: Date;
    };
  };

  type Point = {
    date: Date;
    pribat: number | null;
    secbat: number | null;
    periph: number | null;
    balance: number | null;
  };
}

const empty: Omit<BalanceAveragePowerChartType.Point, 'date'> = {
  pribat: null,
  secbat: null,
  periph: null,
  balance: null,
};

export const BalanceAveragePowerChart = ({
  data,
  config,
}: BalanceAveragePowerChartType.Props) => {
  const [pointRadius, setPointRadius] = useState(0);

  const dataFormatted = useMemo(() => {
    if (!data) return;

    const dataTransform = data.map(({ date, ...rest }) => ({
      ...rest,
      date: date.getTime(),
    }));

    if (config?.startDate) {
      const findSameDateStart = data.find(
        ({ date }) =>
          new Date(date).getTime() ===
          new Date(config.startDate as Date).getTime(),
      );

      if (!findSameDateStart) {
        dataTransform.unshift({
          date: new Date(config?.startDate).getTime(),
          ...empty,
        });
      }
    }

    if (config?.endDate) {
      const findSameDateEnd = data.find(
        ({ date }) =>
          new Date(date).getTime() ===
          new Date(config.endDate as Date).getTime(),
      );

      if (!findSameDateEnd) {
        dataTransform.push({
          date: new Date(config?.endDate).getTime(),
          ...empty,
        });
      }
    }

    return dataTransform.sort((a, b) => a.date - b.date);
  }, [data]);

  if (!data || !dataFormatted) {
    return <SkeletonBlock />;
  }

  if (data.length <= 1)
    return (
      <Empty
        config={{
          mode: {
            name: 'noData',
          },
        }}
      ></Empty>
    );
  return (
    <Col>
      <Row>
        <Button
          handleEvent={{ click: () => setPointRadius(2) }}
          config={{
            text: 'Avec point',
            color: pointRadius ? 'primary' : 'yang',
            size: 'small',
          }}
        ></Button>
        <Space config={{ way: 'horizontal' }} />
        <Button
          handleEvent={{ click: () => setPointRadius(0) }}
          config={{
            text: 'Sans point',
            color: !pointRadius ? 'primary' : 'yang',
            size: 'small',
          }}
        ></Button>
      </Row>
      <Line
        style={{
          maxHeight: '65vh',
          width: '100%',
        }}
        options={{
          plugins: {
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                  modifierKey: 'ctrl',
                },
                drag: {
                  enabled: true,
                  modifierKey: 'ctrl',
                },
                mode: 'x',
                scaleMode: 'y',
              },
              limits: {
                x: {
                  max: dataFormatted[dataFormatted.length - 1].date,
                  min: dataFormatted[0].date,
                },
              },
            },
            tooltip: {
              intersect: false,
              mode: 'index',
              callbacks: {
                label: (context) => {
                  return `${context.dataset.label}: ${
                    context.formattedValue
                  } ${'mW'}`;
                },
              },
              position: 'nearest',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'time',
              ticks: {
                callback: (value, index, ticks) =>
                  DateTime.fromJSDate(new Date(value)).toFormat(
                    'dd/MM/yy HH:mm',
                  ),
              },
              time: {
                tooltipFormat: 'DD/MM/YY HH:mm:ss',
              },
            },
            power: {
              ticks: {
                callback: (value, index, ticks) => `${value} mW`,
              },
              position: 'left',
            },
          },
        }}
        data={{
          labels: dataFormatted.map(({ date }) => date),
          datasets: [
            {
              fill: true,
              yAxisID: 'power',
              label: 'Batterie 1',
              data: dataFormatted.map(({ pribat }) => pribat),
              borderWidth: 1,
              order: 0,
              pointRadius,
            },
            {
              fill: true,
              yAxisID: 'power',
              label: 'Batterie 2',
              data: dataFormatted.map(({ secbat }) => secbat),
              borderWidth: 1,
              order: 1,
              pointRadius,
            },
            {
              fill: true,
              yAxisID: 'power',
              label: 'Périphérique',
              data: dataFormatted.map(({ periph }) => periph),
              borderWidth: 1,
              order: 2,
              pointRadius,
            },
            {
              fill: false,
              yAxisID: 'power',
              label: 'Balance',
              data: dataFormatted.map(({ balance }) => balance),
              borderWidth: 3,
              borderDash: [1, 3],
              order: 3,
              pointRadius,
            },
          ],
        }}
      ></Line>
    </Col>
  );
};
