import {
  AutoComplete,
  Input,
  Select,
  notifService,
  requestGQL,
} from '@gimlite/watermelon';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useCallback } from 'react';
import { CreateCameraWizardContext } from '../createCamera.wizard';
import { UpsResponse, upsGql, upsParams } from '../gql/ups.gql';
import { UpsListResponse, upsListGql, upsListParams } from '../gql/upsList.gql';

export const CameraStep = ({
  context: { camera, listParking },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateCameraWizardContext>) => {
  const searchParkingsFn = useCallback(
    async ({ codePattern }: { codePattern: string }) => {
      try {
        const response = (await requestGQL({
          params: { codePattern } as upsListParams,
          gql: upsListGql,
        })) as UpsListResponse;

        submit({ listParking: response.list });
      } catch (error) {
        notifService.send('NOTIF', {
          content: error,
          mode: 'error',
        });
        submit({ listParking: [] });
      }
    },
    [],
  );

  const readParkingFn = useCallback(async ({ upsId }: { upsId: string }) => {
    try {
      const { _id } = (await requestGQL({
        params: { upsId } as upsParams,
        gql: upsGql,
      })) as UpsResponse;

      submit({
        selectParking: {
          _id,
        },
      });
    } catch (error) {
      notifService.send('NOTIF', {
        content: 'SERVER_PROBLEM',
        mode: 'error',
      });

      submit({
        selectParking: {
          _id: null,
        },
      });
    }
  }, []);

  return (
    <Form
      data={{ value: camera }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit({ camera: { ...value } });
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure>
        <Form.Item
          config={{
            name: 'code',
            label: 'Code',
            rules: [{ required: true }],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          config={{
            name: 'position',
            label: 'Position',
            rules: [{ required: true }],
          }}
        >
          <Select
            data={{
              items: [
                { label: 'Intérieur', value: 'inside' },
                { label: 'Extérieur', value: 'outside' },
              ],
            }}
          />
        </Form.Item>
        <Form.Item
          config={{
            name: 'ups',
            label: 'UPS',
            rules: [{ required: true }],
          }}
        >
          <AutoComplete
            handleEvent={{
              input: (value) => {
                searchParkingsFn({
                  codePattern: value,
                });
              },
              select(item) {
                if (item?.value) {
                  readParkingFn({ upsId: item.value });
                }
              },
            }}
            data={{
              items: listParking.map(({ code, _id }) => ({
                value: _id,
                label: code,
              })),
            }}
            config={{
              search: {
                caseSensitive: false,
                accentSensitive: false,
                pattern: 'start',
              },

              placeholder: 'plan-les-ouates',
            }}
          />
        </Form.Item>
        <Form.Item
          config={{
            name: 'description',
            label: 'Description',
          }}
        >
          <Input />
        </Form.Item>
      </Form.Structure>
    </Form>
  );
};
