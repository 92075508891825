import { Form, requestGQL } from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { FilterExpanded } from '@gimlite/watermelon/components/filter/filter.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Select } from '@gimlite/watermelon/components/select/select.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { monitoringQuery } from '../gql/monitoring.gql';

export const msTypes = new Map([
  ['microservice', 'MS'],
  ['bff', 'BFF'],
  ['api', 'API'],
  ['other', 'Other'],
]);

const typesOptions = [
  { label: 'API', value: 'api' },
  { label: 'MS', value: 'microservice' },
  { label: 'BFF', value: 'bff' },
];

const statusOptions = [
  { label: 'UP', value: 'up' },
  { label: 'DOWN', value: 'down' },
  { label: 'NOT-RESPONDING', value: 'not-responding' },
];

export const Monitoring = () => {
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();
  const [search, setSearch] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: {
            limit: 15,
            ...getParamsUrl,
            order: 'status',
            msDomain: 'yoonite',
          },
          gql: monitoringQuery,
          render: ({ list, paging }: any) => ({
            paging,
            list: list.map(({ type, key, status, lastPing }: any) => ({
              msType: msTypes.get(type),
              msName: `[${msTypes.get(type)}] ${key}`,
              msStatus: status.toUpperCase(),
              msLastPing: DateTime.fromISO(lastPing.toString()).toRelative(),
            })),
          }),
        });

        setSearch(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl]);

  return (
    <Page>
      <Widget config={{ title: 'Etat des microservices' }}>
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'msType',
                    label: 'Type',
                  }}
                >
                  <Select
                    data={{
                      defaultValue: getParamsUrl?.msType,
                      items: typesOptions,
                    }}
                    config={{
                      mode: 'single',
                      width: 'small',
                    }}
                  />
                </Form.Item>

                <Form.Item
                  config={{
                    name: 'msName',
                    label: 'Name',
                  }}
                >
                  <Input
                    data={{ defaultValue: getParamsUrl?.msName }}
                    config={{ width: 'medium' }}
                  />
                </Form.Item>

                <Form.Item
                  config={{
                    name: 'msStatus',
                    label: 'Status',
                  }}
                >
                  <Select
                    data={{
                      defaultValue: getParamsUrl?.status,
                      items: statusOptions,
                    }}
                    config={{
                      mode: 'single',
                      width: 'medium',
                    }}
                  />
                </Form.Item>
              </FilterExpanded.Fields>

              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Rechercher', type: { value: 'submit' } }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
              }}
              data={search}
              config={{
                pagination: 'click',
                columns: [
                  {
                    title: 'Status',
                    key: 'msStatus',
                  },
                  {
                    title: 'Name',
                    key: 'msName',
                  },
                  {
                    title: 'Last ping',
                    key: 'msLastPing',
                  },
                ],
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
