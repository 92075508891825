import { RouterType, useLocation, useNavigate } from '@gimlite/router';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { Card3D } from '@gimlite/watermelon/components/card3D/card3D.component';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { useSelector } from '@xstate/react';
import { useEffect } from 'react';
import './login.page.scss';
import { useRootStore } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { loginService } from '../login.machine';

export const LoginPage = observer(() => {
  const machine = loginService;
  const navigate = useNavigate();
  const location = useLocation();

  const { AuthStore } = useRootStore();
  useEffect(() => {
    if (location.pathname !== '/login') navigate('/login');
  }, [location.pathname]);

  const error = useSelector(machine, ({ context }: any) => context.error);
  const token = useSelector(machine, ({ context }: any) => context.token);

  useEffect(() => {
    if (token) AuthStore.login(token);
  }, [token]);

  return (
    <div className="login">
      <div className="login__present">
        <video
          muted
          autoPlay
          loop
          src="https://www.iemgroup.com/wp-content/uploads/2019/05/timelapse-home-IEM-v4-1.mp4"
        ></video>

        <div className="login__present__logo">
          <Card3D
            config={{
              options: {
                perspective: 2000,
                speed: 100,
                max: 20,
                scale: 1.1,
                transition: true,
                glare: true,
                'max-glare': 1,
              },
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.9 71.4">
              <path d="M0,0V71.4H54.9V0Z" fill="#ffffff75" />
              <g>
                <polygon points="30.7 42.8 36.3 42.8 39.5 56.2 42.7 42.8 48.3 42.8 48.3 60.4 44.8 60.4 44.8 45.7 44.7 45.7 41.4 60.4 37.6 60.4 34.3 45.7 34.2 45.7 34.2 60.4 30.7 60.4 30.7 42.8" />
                <polygon points="14.1 42.8 27.4 42.8 27.4 45.9 17.9 45.9 17.9 49.6 26.6 49.6 26.6 52.7 17.9 52.7 17.9 57.2 27.8 57.2 27.8 60.4 14.1 60.4 14.1 42.8" />
                <rect x="6.6" y="42.8" width="3.9" height="17.6" />
              </g>
            </svg>
          </Card3D>
        </div>
      </div>

      <div className="login__form">
        <div className="login__form__contain">
          <Form
            handleEvent={{
              submit: () => machine.send('SUBMIT'),
            }}
          >
            <Write
              data={{ item: 'Se connecter' }}
              config={{
                mode: 'title-big',
              }}
            ></Write>
            <Space config={{ count: 7 }} />
            <Form.Item
              config={{
                name: 'email',
                label: 'Email',
                way: 'vertical',
              }}
            >
              <Input
                className="loginInput"
                handleEvent={{
                  input: (value: any) => {
                    machine.send('UPDATE', { username: value });
                  },
                }}
                config={{
                  width: 'full',
                  height: 'xlarge',
                  type: { name: 'email' },
                }}
              />
            </Form.Item>
            <Space />
            <Space />
            <Form.Item
              config={{
                name: 'password',
                label: 'Password',
                way: 'vertical',
              }}
            >
              <Input
                className="loginInput"
                handleEvent={{
                  input: (value: any) => {
                    machine.send('UPDATE', { password: value });
                  },
                }}
                config={{
                  width: 'full',
                  height: 'xlarge',
                  type: { name: 'password' },
                }}
              />
            </Form.Item>
            <Space />
            <Space />
            <Form.Item config={{ name: 'submit' }}>
              <Button
                config={{
                  type: { value: 'submit' },
                  text: 'Se connecter',
                  size: 'xlarge',
                }}
                handleEvent={{
                  click: () => machine.send('SUBMIT'),
                }}
              />
            </Form.Item>
            <Space />

            <div className="login__form__contain__error">
              {error && (
                <Write
                  data={{ item: 'Vos identifiants sont incorrectes !' }}
                  config={{
                    mode: 'key-small-light',
                    color: 'error',
                  }}
                ></Write>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
});
