import { useNavigate } from '@gimlite/router';
import { Form, requestGQL } from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { FilterExpanded } from '@gimlite/watermelon/components/filter/filter.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { sitesGql } from '../gql/sites.gql';
import { searchRender } from '../projection/sites.render';
import { siteDetailsDefaultParamsEncode } from './sites.details';

export const SitesSearch = () => {
  const navigate = useNavigate();
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();
  const [search, setSearch] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { limit: 15, ...getParamsUrl },
          gql: sitesGql,
          render: (res) => searchRender(res),
        });

        setSearch(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl]);

  return (
    <Page>
      <Widget config={{ title: 'Liste des sites' }}>
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'terminalId',
                    label: 'UID',
                  }}
                >
                  <Input data={{ defaultValue: getParamsUrl?.test1 }} />
                </Form.Item>
              </FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{
                    text: 'Rechercher',
                    type: { value: 'submit' },
                  }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>
          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(
                    `/onstreet/sites/${value._id}/global?${siteDetailsDefaultParamsEncode}`,
                  );
                },
              }}
              data={search}
              config={{
                pagination: 'click',
                columns: [
                  {
                    title: 'State',
                    key: 'state',
                  },
                  {
                    title: 'Terminal UID',
                    key: 'terminalId',
                  },
                  {
                    title: 'Name',
                    key: 'name',
                  },
                  {
                    title: 'Client',
                    key: 'clientSlug',
                  },
                  {
                    title: 'UPS',
                    key: 'upsCode',
                  },
                ],
                actions: {
                  read: true,
                },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
