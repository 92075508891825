import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { CreateSiteWizardContext } from '../createSite.wizard';

export const SiteStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreateSiteWizardContext>) => {
  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure>
        <Form.Item
          data={{
            defaultValue: context.name ?? '',
          }}
          config={{
            name: 'name',
            label: 'Nom',
            rules: [{ required: true }],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.address1 ?? '',
          }}
          config={{
            name: 'address1',
            label: 'Adresse (1/2)',
            rules: [],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.address2 ?? '',
          }}
          config={{
            name: 'address2',
            label: 'Adresse (2/2)',
            rules: [],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.zipcode ?? '',
          }}
          config={{
            name: 'zipcode',
            label: 'Code postal',
            rules: [],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.city ?? '',
          }}
          config={{
            name: 'city',
            label: 'Ville',
            rules: [],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.country ?? '',
          }}
          config={{
            name: 'country',
            label: 'Pays',
            rules: [],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.terminalId ?? '',
          }}
          config={{
            rules: [],
            name: 'terminalId',
            label: 'Terminal ID',
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.latitude ?? '',
          }}
          config={{
            name: 'latitude',
            label: 'Lat',
            rules: [],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.longitude ?? '',
          }}
          config={{
            name: 'longitude',
            label: 'Lon',
            rules: [],
          }}
        >
          <Input />
        </Form.Item>
      </Form.Structure>
    </Form>
  );
};
