import { requestGQL, useMyUrl, Wizard, wizardService } from "@gimlite/watermelon"
import { useState } from "react"
import { updateAdminUserGql } from "./gql/updateAdminUser.gql"
import { UpdateCredentialsStep } from "./steps/update-creds.step"

export type UpdateAdminUserWizardContext = {
    id: string
    password: string
}

export const UpdateAdminUserWizard = () => {

    const { getParamsUrl } = useMyUrl()
    const { id } = getParamsUrl
    const [result, setResult] = useState<boolean | undefined>(undefined)

    const updateAdminUser = async ({ id, password }: UpdateAdminUserWizardContext) => {
        const resp = await requestGQL({
            gql: updateAdminUserGql,
            params: {
                id,
                password
            }
        })

        if (resp.errors) setResult(false)
        else setResult(true)
    }

    return (
        <Wizard<UpdateAdminUserWizardContext>
            config={{
                title: '',
                withValidation: {
                    state: result,
                    success: 'Ca marche nickel !',
                    error: 'Ca marche pas !',
                }
            }}
            data={{
                defaultContext: {
                    id,
                    password: ''
                }
            }}
            handleEvent={{
                done: (value: UpdateAdminUserWizardContext) => updateAdminUser(value),
                close: () => wizardService.send('SHOW_WIZARD', { id: '' })
            }}
        >
            <Wizard.Item
                config={{ title: 'Credentials update', component: UpdateCredentialsStep }}
            />
        </Wizard>
    )
}