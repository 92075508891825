import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import { assign, createMachine, interpret } from 'xstate';
import { createSiteGql } from './gql/createSite.gql';

const initialContext = {
  clientId: undefined,
  upsId: undefined,
  error: undefined,
};

const machine = createMachine(
  {
    id: 'createSite',
    initial: 'off',
    context: initialContext,
    predictableActionArguments: true,
    states: {
      off: {
        on: {
          WAKEUP: {
            actions: [
              assign({
                clientId: (_, { clientId }) => clientId,
                upsId: (_, { upsId }) => upsId,
              }),
            ],
            target: 'idle',
          },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
          },
          CREATE_SITE: {
            target: 'createSite',
          },
        },
      },
      createSite: {
        invoke: {
          id: 'createSite',
          src: 'createSite',
          onDone: {
            target: 'idle',
            actions: assign({
              result: true,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({
              result: false,
            }),
          },
        },
      },
      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },
  },
  {
    services: {
      createSite: async ({ clientId, upsId }, { payload }) => {
        const { latitude, longitude, ...rest } = payload;

        // Parse coordinates.
        const coordinates =
          latitude && longitude
            ? [parseFloat(latitude), parseFloat(longitude)]
            : undefined;

        // Prepare mutation $input variable
        const input = {
          ...rest,
          coordinates,
          clientId,
          upsId,
        };

        return requestGQL({
          params: {
            input,
          },
          gql: createSiteGql,
          render: (res) => res,
        });
      },
    },
  },
);

export const createSiteService = interpret(machine).start();
