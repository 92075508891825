import { assign, createMachine, interpret } from 'xstate';

const initialContext = {
  error: undefined,
  name: undefined,
};

const machine = createMachine({
  predictableActionArguments: true,
  id: 'createCamera',
  initial: 'off',
  context: initialContext,
  states: {
    off: {
      on: {
        WAKEUP: { target: 'idle' },
      },
    },
    idle: {
      on: {
        KILL: {
          target: 'off',
        },
      },
    },

    failure: {
      on: {
        RETRY: {
          actions: assign({ error: undefined }),
          target: 'idle',
        },
      },
    },
  },
});

export const createCameraService = interpret(machine).start();
