import { DateTime } from 'luxon';

export const readRender = ({ ups, paymentRecords, ...rest }) => {
  const paymentRecordsFromated = paymentRecords.list.map(
    ({ paymentDate, ...rest }) => ({
      ...rest,
      paymentDate: DateTime.fromISO(paymentDate).toFormat('dd/MM/yy HH:mm:ss'),
    }),
  );

  const upsFromated = ups.list.map(({ code, config, ...rest }) => ({
    ...rest,
    code,
    maximumAmount: config?.onstreet?.maximumAmount,
    maximumDuration: config?.onstreet.maximumDuration,
  }));

  return {
    ...rest,
    ups: { list: upsFromated, paging: ups.paging },
    paymentRecords: {
      list: paymentRecordsFromated,
      paging: paymentRecords.paging,
    },
  };
};
