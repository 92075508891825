import { requestGQL, wizardService } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useState } from 'react';
import {
  CreateCameraParams,
  CreateCameraResponse,
  createCameraGql,
} from './gql/createCamera.gql';
import { UpsListResponse } from './gql/upsList.gql.js';
import { CameraStep } from './steps/camera.step';

export type CreateCameraWizardContext = {
  camera: {
    code: string | null;
    position: 'inside' | 'outside';
    ups: string | null;
    description: string | null;
  };
  listParking: UpsListResponse['list'];
  selectParking: {
    _id: string | null;
  };
};

export const CreateCameraWizard = () => {
  const [result, setResult] = useState<boolean>();

  return (
    <Wizard<CreateCameraWizardContext>
      config={{
        title: "Création d'une caméra",
        withValidation: {
          state: result,
          success: 'Ca marche nickel !',
          error: 'Ca marche pas !',
        },
      }}
      data={{
        defaultContext: {
          camera: {
            code: null,
            position: 'inside',
            ups: null,
            description: null,
          },
          listParking: [],
          selectParking: {
            _id: null,
          },
        },
      }}
      handleEvent={{
        done: async ({ camera, listParking, selectParking }) => {
          try {
            (await requestGQL({
              params: {
                input: {
                  code: camera.code!,
                  description: camera.description!,
                  position: camera.position,
                  ups: selectParking._id,
                },
              } as CreateCameraParams,
              gql: createCameraGql,
            })) as CreateCameraResponse;

            setResult(() => true);
          } catch (error) {
            setResult(() => false);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item config={{ title: 'Camera', component: CameraStep }} />
    </Wizard>
  );
};
