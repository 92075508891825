import { Link } from '@gimlite/router';
import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { DateTime } from 'luxon';

export const fragmentsRender = ({ list, paging }) => {
  return {
    list: list.map(({ logDate, state, terminalId, ...rest }) => {
      return {
        ...rest,
        terminalId: (
          <Link to={`/adm/fragments?terminalId=${terminalId}`}>
            {terminalId}
          </Link>
        ),
        logDate: new Date(logDate).toLocaleString(DateTime.DATE_SHORT),
        state:
          state !== 'PROCESSED' ? (
            <Badge config={{ color: 'error', text: state }} />
          ) : (
            <Badge config={{ color: 'success', text: state }} />
          ),
      };
    }),
    paging,
  };
};
