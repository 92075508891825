import { assign, createMachine, interpret } from 'xstate';

const initialContext = {
  error: undefined,
};

const machine = createMachine({
  id: 'createControlUser',
  initial: 'off',
  context: initialContext,
  predictableActionArguments: true,
  states: {
    off: {
      on: {
        WAKEUP: {
          target: 'idle',
        },
      },
    },
    idle: {
      on: {
        KILL: {
          target: 'off',
        },
      },
    },
    failure: {
      on: {
        RETRY: {
          actions: assign({ error: undefined }),
          target: 'idle',
        },
      },
    },
  },
});

export const createControlUsersService = interpret(machine).start();
