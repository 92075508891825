import { gql } from '@apollo/client';

export const serviceCardEventsEntity = `
  serviceCardEvents(
    page: $serviceCardEventPage
    limit: $serviceCardEventLimit
    startDate: $startDate, 
    stopDate: $stopDate
  ) {
    list {
      _id
      fragmentRef
      terminalId
      siteId
      logDate
      UID
      Type
      Status
      Details
      comment
      createdAt
    }
    paging {
      current
      count
      limit
    }
  }`;

export const serviceCardEventsPaging = gql`
  query site(
    $siteId: String!
    $serviceCardEventPage: Int
    $serviceCardEventLimit: Int,
    $startDate: String, 
    $stopDate: String
    ) {
    site(siteId: $siteId) {
      ${serviceCardEventsEntity}
    }
  }
`;
