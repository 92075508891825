import { gql } from '@apollo/client';
export type UpdateControlUserResponse = {
  controlUser: {
    _id: string;
  };
};

export type UpdateControlUserParams = {
  input: {
    userId: string;
    firstName: string | null;
    lastName: string | null;
    username: string | null;
    lang: string | null;
    password: string | null;
    fpsAgentId: number | null;
    fpsAgentName: string | null;
    ups: string[];
  };
};
export const updateControlUsersGql = gql`
  mutation updateControlUsers($input: UpdateControlUserDto!) {
    updateControlUsers(input: $input) {
      controlUser {
        _id
      }
    }
  }
`;
