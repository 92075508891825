import { gql } from '@apollo/client';

export const createSiteGql = gql`
  mutation createSite($input: CreateSiteDto!) {
    createSite(input: $input) {
      site {
        _id
      }
    }
  }
`;
