import { ApolloError } from '@apollo/client';
import {
  UseCascadeResult,
  requestGQL,
  toStringIfValue,
  useCascade,
  wizardService,
} from '@gimlite/watermelon';
import { SelectType } from '@gimlite/watermelon/components/select/select.component';
import {
  Wizard,
  WizardType,
} from '@gimlite/watermelon/components/wizard/wizard.component';
import { useSelector } from '@xstate/react';
import { useEffect, useMemo, useState } from 'react';
import { createPrestoScanService } from './createPrestoScan.machine';
import { ClientsResponse } from './gql/clients.gql';
import { ControlUsersResponse } from './gql/control-users.gql';
import {
  CreateCameraParams,
  CreateCameraResponse,
  createCameraGql,
} from './gql/createCamera.gql';
import {
  CreateClientParams,
  CreateClientResponse,
  createClientGql,
} from './gql/createClient.gql';
import {
  CreateControlUserParams,
  CreateControlUserResponse,
  createControlUsersGql,
} from './gql/createControlUsers.gql';
import {
  CreateUpsParams,
  CreateUpsResponse,
  createUpsGql,
} from './gql/createUps.gql';
import {
  DeleteCameraParams,
  DeleteCameraResponse,
  deleteCameraGql,
} from './gql/deleteCamera.gql';
import {
  UpdateCameraParams,
  UpdateCameraResponse,
  updateCameraGql,
} from './gql/updateCamera.gql';
import {
  UpdateControlUserParams,
  UpdateControlUserResponse,
  updateControlUsersGql,
} from './gql/updateControlUsers.gql';
import {
  UpdateUpsParams,
  UpdateUpsResponse,
  updateUpsGql,
} from './gql/updateUps.gql';
import { UpsListResponse } from './gql/upsList.gql';
import { AgentsStep } from './steps/agents.step';
import { CamerasStep } from './steps/cameras.step';
import { ClientStep } from './steps/client.step';
import { FpsStep } from './steps/fps.step';
import { UpsStep } from './steps/ups.step';
import { VaoStep } from './steps/vao.step';

export const spaceBeetwenInput = 2;
export const spaceBeetwenTitle = 1;
export const timeoutOnSearch = 200;

export type CreatePrestoScanWizardContext = {
  modeClient: 'search' | 'create';
  searchClient: {
    _id: string | null;
    siret: string | null;
    slug: string | null;
  };
  createClient: {
    name: string | null;
    slug: string | null;
    ticketsHub: string | null;
    code: string | null;
    defaultContractTag: string | null;
    siret: string | null;
    timezone: string | null;
    locale: string | null;
    defaultCurrency: string | null;
    maximumDuration: number | null;
    maximumAmount: number | null;
    translation: Array<{
      description: string | null;
      lang: string | null;
      name: string | null;
    }>;
  };
  listClient: ClientsResponse['list'];
  modeParking: 'search' | 'create';
  searchParking: {
    _id: string | null;
    code: string | null;
    streetNumber: string | null;
    streetNumberBis: string | null;
    streetType: string | null;
    streetName: string | null;
    specialPlace: string | null;
    postOfficeBoxNumber: string | null;
    postalCode: string | null;
    addressLocality: string | null;
    addressSubRegion: string | null;
    addressCountry: string | null;
    latitude: number | null;
    longitude: number | null;
    capacity: number | null;
    occupancy: number | null;
    translation: Array<{
      description: string | null;
      lang: string | null;
      name: string | null;
    }>;
  };
  createParking: {
    latitude: number | null;
    longitude: number | null;
    capacity: number | null;
    occupancy: number | null;
    addressCountry: string | null;
    addressLocality: string | null;
    addressSubRegion: string | null;
    code: string | null;
    postOfficeBoxNumber: string | null;
    postalCode: string | null;
    specialPlace: string | null;
    streetName: string | null;
    streetNumber: string | null;
    streetNumberBis: string | null;
    streetType: string | null;
    translation: Array<{
      description: string | null;
      lang: string | null;
      name: string | null;
    }>;
  };
  listParking: UpsListResponse['list'];
  createVAO: {
    sitesLegacyId: string | null;
    rightsLegacyId: string | null;
    deletionDelay_complete: number | null;
    deletionDelay_fined: number | null;
    deletionDelay_incomplete: number | null;
    deletionDelay_toCheck: number | null;
    deletionDelay_unfined: number | null;
    entryTolerancePeriod: number | null;
    exitTolerancePeriod: number | null;
    isVerbalizationAllowed: boolean | null;
    outrageTreshold_danger: number | null;
    outrageTreshold_warning: number | null;
    showOccupancy: boolean | null;
  };
  FPS: {
    addressCountry: string | null;
    addressLocality: string | null;
    addressSubRegion: string | null;
    finePrice: number | null;
    name: string | null;
    notificationAuthority: string | null;
    organizationId: string | null;
    postOfficeBoxNumber: string | null;
    postalCode: string | null;
    reducedFinePrice: number | null;
    reducedPriceDuration: number | null;
    specialPlace: string | null;
    streetName: string | null;
    streetNumber: string | null;
    streetNumberBis: string | null;
    streetType: string | null;
    url: string | null;
  };
  cameras: Array<{
    _id?: string;
    position: string | null;
    code: string | null;
    description: string | null;
  }>;
  camerasSave: ReadonlyArray<{
    _id: string;
    position: string | null;
    code: string | null;
    description: string | null;
  }>;
  searchAgent: { _id: string | null; username: string | null; ups: string[] };
  listAgent: ControlUsersResponse['list'];
  modeAgent: 'search' | 'create';
  createAgent: {
    firstName: string | null;
    fpsAgentId: number | null;
    fpsAgentName: string | null;
    lang: string | null;
    lastName: string | null;
    password: string | null;
    username: string | null;
  };
};

export const langsItemsSelect: SelectType.Data.Item[] = [
  {
    value: 'fr_FR',
    label: 'Français',
  },
  {
    value: 'en_EN',
    label: 'Anglais',
  },
  {
    value: 'de_DE',
    label: 'Allemand',
  },
  {
    value: 'it_IT',
    label: 'Italien',
  },
];

type ResultPosibility =
  | CreateClientResponse
  | CreateUpsResponse
  | UpdateUpsResponse
  | CreateCameraResponse
  | UpdateCameraResponse
  | DeleteCameraResponse
  | CreateControlUserResponse
  | UpdateControlUserResponse;

export const CreatePrestoScanWizard = () => {
  const [result, setResult] = useState<boolean>();
  const [resultError, setResultError] = useState<string>(
    "L'inscription n'a pas été réalisée !",
  );
  const [resultDetails, setResultDetails] = useState<
    Array<WizardType.Config.Details>
  >([]);

  const data = useSelector(
    createPrestoScanService,
    ({ context }: any) =>
      context.data as CreatePrestoScanWizardContext | undefined,
  );

  const promises = useMemo((): Array<
    (
      results: Array<UseCascadeResult<ResultPosibility>>,
      index: number,
    ) => Promise<UseCascadeResult<ResultPosibility>>
  > => {
    if (!data) return [];

    const getUpsId = (
      prevResult: Array<UseCascadeResult<ResultPosibility>>,
    ): string | null => {
      if (data.modeParking === 'search') {
        return data.searchParking._id;
      }

      const findIndexResult =
        data.modeClient === 'create' && data.modeParking === 'create'
          ? prevResult[1]
          : prevResult[0];

      return findIndexResult &&
        !(findIndexResult instanceof Error) &&
        'ups' in findIndexResult?.response &&
        findIndexResult?.response?.ups?._id
        ? findIndexResult?.response?.ups._id
        : null;
    };

    const getClientId = (
      prevResult: Array<UseCascadeResult<ResultPosibility>>,
    ): string | null => {
      if (data.modeClient === 'search') {
        return data.searchClient._id;
      }

      const findIndexResult = prevResult[0];

      return findIndexResult &&
        !(findIndexResult instanceof Error) &&
        'client' in findIndexResult?.response &&
        findIndexResult?.response?.client?._id
        ? findIndexResult?.response?.client._id
        : null;
    };

    return [
      ...(data.modeClient === 'create'
        ? [
            async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
              const { createClient } = data;
              try {
                const createClientExe = (await requestGQL({
                  params: {
                    input: {
                      name: createClient.name,
                      slug: createClient.slug,
                      ticketsHub: createClient.ticketsHub,
                      code: createClient.code,
                      defaultContractTag: createClient.defaultContractTag,
                      siret: createClient.siret,
                      timezone: createClient.timezone,
                      locale: createClient.locale,
                      defaultCurrency: createClient.defaultCurrency,
                      maximumDuration: createClient.maximumDuration,
                      maximumAmount: createClient.maximumAmount,
                      translation: createClient.translation,
                    },
                  } as CreateClientParams,
                  gql: createClientGql,
                })) as CreateClientResponse;

                return { title: 'createClient', response: createClientExe };
              } catch (error: unknown) {
                return {
                  title: 'createClient',
                  response: new Error(
                    error instanceof ApolloError
                      ? `${error.message}`
                      : `${error}`,
                  ),
                };
              }
            },
          ]
        : []),
      ...(data.modeParking === 'create'
        ? [
            async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
              const { createParking, searchClient, FPS, createVAO } = data;
              try {
                const createParkingExe = (await requestGQL({
                  params: {
                    input: {
                      code: createParking.code!,
                      center: [createParking.latitude, createParking.longitude],
                      address: {
                        streetNumber:
                          toStringIfValue(createParking.streetNumber) || null,
                        streetNumberBis:
                          toStringIfValue(createParking.streetNumberBis) ||
                          null,
                        streetType:
                          toStringIfValue(createParking.streetType) || null,
                        streetName:
                          toStringIfValue(createParking.streetName) || null,
                        postOfficeBoxNumber:
                          toStringIfValue(createParking.postOfficeBoxNumber) ||
                          null,
                        specialPlace:
                          toStringIfValue(createParking.specialPlace) || null,
                        postalCode: toStringIfValue(createParking.postalCode)!,
                        addressLocality: toStringIfValue(
                          createParking.addressLocality,
                        )!,
                        addressSubRegion: toStringIfValue(
                          createParking.addressSubRegion,
                        )!,
                        addressCountry: toStringIfValue(
                          createParking.addressCountry,
                        )!,
                      },
                      clientId: getClientId(prevResult)!,
                      shape: null,
                      translation: createParking.translation,
                      type: 'PARK',
                      config: {
                        fps: {
                          system: 'iem',
                          notificationAuthority: FPS.notificationAuthority,
                          reducedPriceDuration: FPS.reducedPriceDuration,
                          finePrice: FPS.finePrice,
                          reducedFinePrice: FPS.reducedFinePrice,
                          recourseOrganization: {
                            address: {
                              addressCountry: toStringIfValue(
                                FPS.addressCountry,
                              ),
                              addressLocality: toStringIfValue(
                                FPS.addressLocality,
                              ),
                              addressSubRegion: toStringIfValue(
                                FPS.addressSubRegion,
                              ),
                              postOfficeBoxNumber: toStringIfValue(
                                FPS.postOfficeBoxNumber,
                              ),
                              specialPlace: toStringIfValue(FPS.specialPlace),
                              streetName: toStringIfValue(FPS.streetName),
                              streetType: toStringIfValue(FPS.streetType),
                              streetNumberBis: toStringIfValue(
                                FPS.streetNumberBis,
                              ),
                              streetNumber: toStringIfValue(FPS.streetNumber),
                              postalCode: toStringIfValue(FPS.postalCode),
                            },
                            name: FPS.name,
                            organizationId: FPS.organizationId,
                            url: FPS.url,
                          },
                        },
                        prestoScan: {
                          sitesLegacyId: createVAO.sitesLegacyId,
                          rightsLegacyId: createVAO.rightsLegacyId,
                          entryTolerancePeriod: createVAO.entryTolerancePeriod,
                          exitTolerancePeriod: createVAO.exitTolerancePeriod,
                          isVerbalizationAllowed:
                            !!createVAO.isVerbalizationAllowed,
                          showOccupancy: !!createVAO.showOccupancy,
                          deletionDelay: {
                            incomplete: createVAO.deletionDelay_incomplete,
                            complete: createVAO.deletionDelay_complete,
                            toCheck: createVAO.deletionDelay_toCheck,
                            unfined: createVAO.deletionDelay_unfined,
                            fined: createVAO.deletionDelay_fined,
                          },
                          outrageTreshold: {
                            danger: createVAO.outrageTreshold_danger,
                            warning: createVAO.outrageTreshold_warning,
                            valid: 0,
                          },
                        },
                      },
                      occupancy: createParking.occupancy,
                      capacity: createParking.capacity,
                    },
                  } as CreateUpsParams,
                  gql: createUpsGql,
                })) as CreateUpsResponse;

                return { title: 'createParking', response: createParkingExe };
              } catch (error: unknown) {
                return {
                  title: 'createParking',
                  response: new Error(
                    error instanceof ApolloError
                      ? `${error.message}`
                      : `${error}`,
                  ),
                };
              }
            },
          ]
        : [
            async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
              const { searchParking, searchClient, FPS, createVAO } = data;
              try {
                const updateParkingExe = (await requestGQL({
                  params: {
                    input: {
                      upsId: searchParking._id,
                      occupancy: searchParking.occupancy,
                      capacity: searchParking.capacity,
                      center: [searchParking.latitude, searchParking.longitude],
                      address: {
                        streetNumber: toStringIfValue(
                          searchParking.streetNumber,
                        ),
                        streetNumberBis: toStringIfValue(
                          searchParking.streetNumberBis,
                        ),
                        streetType: toStringIfValue(searchParking.streetType),
                        streetName: toStringIfValue(searchParking.streetName),
                        postOfficeBoxNumber: toStringIfValue(
                          searchParking.postOfficeBoxNumber,
                        ),
                        specialPlace: toStringIfValue(
                          searchParking.specialPlace,
                        ),
                        postalCode: toStringIfValue(searchParking.postalCode),
                        addressLocality: toStringIfValue(
                          searchParking.addressLocality,
                        ),
                        addressSubRegion: toStringIfValue(
                          searchParking.addressSubRegion,
                        ),
                        addressCountry: toStringIfValue(
                          searchParking.addressCountry,
                        ),
                      },
                      config: {
                        fps: {
                          system: 'iem',
                          notificationAuthority: FPS.notificationAuthority,
                          reducedPriceDuration: FPS.reducedPriceDuration,
                          finePrice: FPS.finePrice,
                          reducedFinePrice: FPS.reducedFinePrice,
                          recourseOrganization: {
                            address: {
                              addressCountry: toStringIfValue(
                                FPS.addressCountry,
                              ),
                              addressLocality: toStringIfValue(
                                FPS.addressLocality,
                              ),
                              addressSubRegion: toStringIfValue(
                                FPS.addressSubRegion,
                              ),
                              postOfficeBoxNumber: toStringIfValue(
                                FPS.postOfficeBoxNumber,
                              ),
                              specialPlace: toStringIfValue(FPS.specialPlace),
                              streetName: toStringIfValue(FPS.streetName),
                              streetType: toStringIfValue(FPS.streetType),
                              streetNumberBis: toStringIfValue(
                                FPS.streetNumberBis,
                              ),
                              streetNumber: toStringIfValue(FPS.streetNumber),
                              postalCode: toStringIfValue(FPS.postalCode),
                            },
                            name: FPS.name,
                            organizationId: FPS.organizationId,
                            url: FPS.url,
                          },
                        },
                        prestoScan: {
                          sitesLegacyId: createVAO.sitesLegacyId,
                          rightsLegacyId: createVAO.rightsLegacyId,
                          entryTolerancePeriod: createVAO.entryTolerancePeriod,
                          exitTolerancePeriod: createVAO.exitTolerancePeriod,
                          isVerbalizationAllowed:
                            !!createVAO.isVerbalizationAllowed,
                          deletionDelay: {
                            incomplete: createVAO.deletionDelay_incomplete,
                            complete: createVAO.deletionDelay_complete,
                            toCheck: createVAO.deletionDelay_toCheck,
                            unfined: createVAO.deletionDelay_unfined,
                            fined: createVAO.deletionDelay_fined,
                          },
                          outrageTreshold: {
                            danger: createVAO.outrageTreshold_danger,
                            warning: createVAO.outrageTreshold_warning,
                            valid: 0,
                          },
                        },
                      },
                      translation: searchParking.translation.filter(Boolean),
                    },
                  } as UpdateUpsParams,
                  gql: updateUpsGql,
                })) as UpdateUpsResponse;

                console.log({ updateParkingExe });
                return { title: 'updateParking', response: updateParkingExe };
              } catch (error: unknown) {
                return {
                  title: 'updateParking',
                  response: new Error(
                    error instanceof ApolloError
                      ? `${error.message}`
                      : `${error}`,
                  ),
                };
              }
            },
          ]),
      ...[...data.cameras, ...data.camerasSave]
        .reduce((acc: CreatePrestoScanWizardContext['cameras'], camera) => {
          if (!('_id' in camera)) {
            return [...acc, camera];
          } else if (acc.find((element) => element._id === camera._id)) {
            return acc;
          } else {
            return [...acc, camera];
          }
        }, [])
        .map(
          (camera) =>
            async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
              if (!('_id' in camera)) {
                try {
                  const createCamera = (await requestGQL({
                    params: {
                      input: {
                        code: camera.code,
                        position: camera.position,
                        description: camera.description,
                        ups: getUpsId(prevResult)!,
                      },
                    } as CreateCameraParams,
                    gql: createCameraGql,
                  })) as CreateCameraResponse;

                  return { title: 'createCamera', response: createCamera };
                } catch (error: unknown) {
                  return {
                    title: 'createCamera',
                    response: new Error(
                      error instanceof ApolloError
                        ? `${error.message}`
                        : `${error}`,
                    ),
                  };
                }
              } else if (
                data.camerasSave.find(
                  (cameraSave) => cameraSave._id === camera._id,
                ) &&
                data.cameras.find((cameraSave) => cameraSave._id === camera._id)
              ) {
                try {
                  const updateCamera = (await requestGQL({
                    params: {
                      input: {
                        code: camera.code,
                        position: camera.position,
                        description: camera.description,
                        ups: getUpsId(prevResult)!,
                        cameraId: camera._id,
                      },
                    } as UpdateCameraParams,
                    gql: updateCameraGql,
                  })) as UpdateCameraResponse;

                  return { title: 'updateCamera', response: updateCamera };
                } catch (error: unknown) {
                  return {
                    title: 'createCamera',
                    response: new Error(
                      error instanceof ApolloError
                        ? `${error.message}`
                        : `${error}`,
                    ),
                  };
                }
              } else {
                try {
                  const deleteCamera = (await requestGQL({
                    params: {
                      input: {
                        cameraId: camera._id,
                      },
                    } as DeleteCameraParams,
                    gql: deleteCameraGql,
                  })) as DeleteCameraResponse;

                  return { title: 'deleteCamera', response: deleteCamera };
                } catch (error: unknown) {
                  return {
                    title: 'deleteCamera',
                    response: new Error(
                      error instanceof ApolloError
                        ? `${error.message}`
                        : `${error}`,
                    ),
                  };
                }
              }
            },
        ),
      data.modeAgent === 'create'
        ? async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
            const { createAgent } = data;

            try {
              const createAgentExe = (await requestGQL({
                params: {
                  input: {
                    firstName: createAgent.firstName!,
                    lastName: createAgent.lastName!,
                    username: createAgent.username!,
                    lang: createAgent.lang!,
                    password: createAgent.password!,
                    fpsAgentId: `${createAgent.fpsAgentId!}`,
                    fpsAgentName: createAgent.fpsAgentName!,
                    upsId: getUpsId(prevResult)!,
                  },
                } as CreateControlUserParams,
                gql: createControlUsersGql,
              })) as CreateControlUserResponse;

              console.log({ createAgentExe });
              return { title: 'createAgent', response: createAgentExe };
            } catch (error: unknown) {
              return {
                title: 'createAgent',
                response: new Error(
                  error instanceof ApolloError
                    ? `${error.message}`
                    : `${error}`,
                ),
              };
            }
          }
        : async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
            const { searchAgent } = data;

            const ups = [...searchAgent.ups];

            const parkingId = getUpsId(prevResult);

            if (parkingId) ups.push(parkingId);

            try {
              const updateAgentExe = (await requestGQL({
                params: {
                  input: {
                    userId: searchAgent._id,
                    ups,
                  },
                } as UpdateControlUserParams,
                gql: updateControlUsersGql,
              })) as UpdateControlUserResponse;

              console.log({ updateAgentExe });
              return { title: 'updateAgent', response: updateAgentExe };
            } catch (error: unknown) {
              throw {
                title: 'updateAgent',
                response: new Error(
                  error instanceof ApolloError
                    ? `${error.message}`
                    : `${error}`,
                ),
              };
            }
          },
    ];
  }, [data]);

  const { isFinish, results } = useCascade<ResultPosibility>(
    promises,
    promises.length > 0,
  );

  useEffect(() => {
    if (isFinish && promises.length > 0) {
      if (results.every((item) => !(item.response instanceof Error))) {
        setResult(() => true);
      } else {
        setResult(() => false);
        setResultError(() =>
          results.every((item) => item.response instanceof Error)
            ? "L'inscription n'a pas été réalisée !"
            : "L'inscription a été partiellement réalisée !",
        );

        setResultDetails(() =>
          results.map((item) => {
            if (item.response instanceof Error) {
              return {
                type: 'error',
                title: item.title,
                description: item.response.message,
              };
            } else {
              return { type: 'success', title: item.title };
            }
          }),
        );
      }
    }
  }, [isFinish, results]);

  return (
    <Wizard<CreatePrestoScanWizardContext>
      config={{
        title: 'Inscription à PrestoScan',
        withValidation: {
          state: result,
          success: "L'inscription a été réalisée !",
          error: resultError,
          details: resultDetails,
        },
      }}
      data={{
        defaultContext: {
          createClient: {
            code: null,
            defaultContractTag: null,
            defaultCurrency: null,
            locale: null,
            maximumAmount: null,
            maximumDuration: null,
            name: null,
            siret: null,
            slug: null,
            ticketsHub: null,
            timezone: null,
            translation: [],
          },
          modeClient: 'search',
          searchClient: {
            _id: null,
            slug: null,
            siret: null,
          },
          listClient: [],
          createParking: {
            addressCountry: null,
            addressLocality: null,
            addressSubRegion: null,
            capacity: null,
            code: null,
            latitude: null,
            longitude: null,
            occupancy: null,
            postOfficeBoxNumber: null,
            postalCode: null,
            specialPlace: null,
            streetName: null,
            streetNumber: null,
            streetNumberBis: null,
            streetType: null,
            translation: [],
          },
          listParking: [],
          modeParking: 'search',
          searchParking: {
            _id: null,
            code: null,
            addressCountry: null,
            addressLocality: null,
            addressSubRegion: null,
            capacity: null,
            latitude: null,
            longitude: null,
            occupancy: null,
            postalCode: null,
            postOfficeBoxNumber: null,
            specialPlace: null,
            streetName: null,
            streetNumber: null,
            streetNumberBis: null,
            streetType: null,
            translation: [],
          },
          createVAO: {
            sitesLegacyId: null,
            rightsLegacyId: null,
            deletionDelay_complete: 3,
            deletionDelay_fined: 90,
            deletionDelay_incomplete: 3,
            deletionDelay_toCheck: 10,
            deletionDelay_unfined: 3,
            entryTolerancePeriod: null,
            exitTolerancePeriod: null,
            isVerbalizationAllowed: false,
            outrageTreshold_danger: null,
            outrageTreshold_warning: null,
            showOccupancy: false,
          },
          FPS: {
            addressCountry: null,
            addressLocality: null,
            addressSubRegion: null,
            finePrice: null,
            name: null,
            notificationAuthority: null,
            organizationId: null,
            postOfficeBoxNumber: null,
            postalCode: null,
            reducedFinePrice: null,
            reducedPriceDuration: null,
            specialPlace: null,
            streetName: null,
            streetNumber: null,
            streetNumberBis: null,
            streetType: null,
            url: null,
          },
          cameras: [],
          camerasSave: [],
          searchAgent: { _id: null, username: null, ups: [] },
          listAgent: [],
          modeAgent: 'search',
          createAgent: {
            firstName: null,
            fpsAgentId: null,
            fpsAgentName: null,
            lang: null,
            lastName: null,
            password: null,
            username: null,
          },
        },
      }}
      handleEvent={{
        done: async (value) => {
          createPrestoScanService.send('REGISTER', { payload: value });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item config={{ title: 'Client', component: ClientStep }} />
      <Wizard.Item config={{ title: 'Parking', component: UpsStep }} />
      <Wizard.Item config={{ title: 'PrestoScan', component: VaoStep }} />
      <Wizard.Item config={{ title: 'FPS', component: FpsStep }} />
      <Wizard.Item config={{ title: 'Cameras', component: CamerasStep }} />
      <Wizard.Item config={{ title: 'Agent', component: AgentsStep }} />
    </Wizard>
  );
};
