import { useNavigate } from '@gimlite/router';
import { FilterExpanded, requestGQL, wizardService } from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { operatorsGql } from '../gql/operators.gql';
import { searchRender } from '../projection/operators.render';

export const OperatorsSearch = () => {
  const navigate = useNavigate();
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const [search, setSearch] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: {},
          gql: operatorsGql,
          render: (res) => searchRender(res),
        });

        setSearch(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl]);

  return (
    <Page>
      <Widget config={{ title: 'Liste des opérateurs' }}>
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded data={{ value: getParamsUrl }}>
              <FilterExpanded.Fields></FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  handleEvent={{
                    click: () => {
                      wizardService.send('SHOW_WIZARD', {
                        id: 'createOperator',
                      });
                    },
                  }}
                  config={{
                    text: 'Créer un opérateur',
                    type: { value: 'button' },
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(`/offstreet/operators/${value._id}`);
                },
              }}
              data={search}
              config={{
                columns: [
                  {
                    title: 'Logo',
                    key: 'logo',
                  },
                  {
                    title: 'Name',
                    key: 'name',
                  },
                  {
                    title: 'Code',
                    key: 'code',
                  },
                  {
                    title: 'Operator code',
                    key: 'operatorCode',
                  },
                  {
                    title: 'Contract count',
                    key: 'operatorContractCount',
                  },
                ],
                actions: {
                  read: true,
                },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
