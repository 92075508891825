import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import { assign, createMachine, interpret } from 'xstate';
import { readSiteGql } from './gql/readSite.gql';
import { updateSiteGql } from './gql/updateSite.gql';
import { read as readRender } from './projection/site.render';

const initialContext = {
  siteId: undefined,
  site: undefined,
  error: undefined,
};

const machine = createMachine(
  {
    predictableActionArguments: true,
    id: 'updateSite',
    initial: 'off',
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            actions: assign({ siteId: (_, { siteId }) => siteId }),
            target: 'read',
          },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
          },
          UPDATE_SITE: {
            target: 'updateSite',
          },
        },
      },
      read: {
        invoke: {
          id: 'read',
          src: 'read',
          onDone: {
            target: 'idle',
            actions: assign({ site: (_, { data }) => data }),
          },
          onError: {
            target: 'failure',
            actions: assign({ error: (_, { data }) => data }),
          },
        },
      },
      updateSite: {
        invoke: {
          id: 'updateSite',
          src: 'updateSite',
          onDone: {
            target: 'idle',
            actions: assign({
              result: true,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({
              result: false,
            }),
          },
        },
      },
      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },
  },
  {
    services: {
      read: async (context, params) =>
        requestGQL({
          params,
          gql: readSiteGql,
          render: (res) => readRender(context, res),
        }),
      updateSite: async (context, { payload }) => {
        const { siteId, latitude, longitude, ...rest } = payload;

        // Parse coordinates.
        const coordinates =
          latitude && longitude
            ? [parseFloat(latitude), parseFloat(longitude)]
            : undefined;

        const input = {
          ...rest,
          siteId,
          coordinates,
        };

        return requestGQL({
          params: { input },
          gql: updateSiteGql,
          render: (res) => res,
        });
      },
    },
  },
);

export const updateSiteService = interpret(machine).start();
