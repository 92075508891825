import { gql } from '@apollo/client';

const operatorContractGqlProps = `{
  _id
  startDate
  stopDate
  isEnabled
  category
  ospContractId
}`;

const operatorPoolContractGqlProps = `{
  _id
  name1
  name2
  spacesAllocated
  spacesOccupied
  ospContractId
  category
}`;

export const operatorGql = gql`
  query operator(
    $operatorId: String!
    $contractsPage: Int
    $contractsLimit: Int
    $poolContractsPage: Int
    $poolContractsLimit: Int
  ) {
    operator(operatorId: $operatorId) {
      _id
      operatorCode
      operatorContractCount
      name
      email
      phone
      code
      display {
        logoMain
        logoSecondary
      }
      translation
      contracts(page: $contractsPage, limit: $contractsLimit) {
        list ${operatorContractGqlProps}
        paging {
          current
          count
          limit
        }
      }
      poolContracts(page: $poolContractsPage, limit: $poolContractsLimit) {
        list ${operatorPoolContractGqlProps}
        paging {
          current
          count
          limit
        }
      }
    }
  }
  `;
