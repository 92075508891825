import { gql } from '@apollo/client';

export const feedbacksEntity = `
    feedbacksStats(
        startDate: $startDate,
        stopDate: $stopDate
    ) {
    list {
      title
      items{
        good 
        average
        bad
      }
    }
    }
`;

export const feedbacksPaging = gql`
  query site($siteId: String!, $startDate: String!, $stopDate: String!) {
    site(siteId: $siteId) {
      ${feedbacksEntity}
    }
  }
`;
