export const parkingsRender = ({ list, paging }) => {
  return {
    list: list.map(({ coordinates, ...rest }) => {
      const [coordX, coordY] = coordinates;
      return {
        ...rest,
        coordX,
        coordY,
      };
    }),
    paging,
  };
};
