import { gql } from '@apollo/client';

export const updateOperatorGql = gql`
  mutation updateOperator($input: UpdateOperatorDto!) {
    updateOperator(input: $input) {
      operator {
        _id
      }
    }
  }
`;
