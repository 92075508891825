import { gql } from '@apollo/client';

export const sessionsGql = gql`
  query sessions(
    $limit: Int
    $page: Int
    $order: String
    $states: [String!]
    $beginEnd: [DateTime!]
  ) {
    sessions(
      limit: $limit
      page: $page
      order: $order
      states: $states
      beginEnd: $beginEnd
    ) {
      list {
        _id
        consumer
        state
        logicalId
        segment
        entryDate
        exitDate
      }

      paging {
        current
        count
        limit
      }
    }
  }
`;
