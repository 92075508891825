import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Select } from '@gimlite/watermelon/components/select/select.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTimeout } from '@gimlite/watermelon/hook/useTimeout.hook';
import { useSelector } from '@xstate/react';
import { createPmsTechnicalUsersService } from '../createPmsTechnicalUser.machine';
import { CreatePmsTechnicalUserWizardContext } from '../createPmsTechnicalUser.wizard';

export const DetailsStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreatePmsTechnicalUserWizardContext>) => {
  const { timeout } = useTimeout();

  const upsList = useSelector(
    createPmsTechnicalUsersService,
    ({ context }: any) => context.upsList,
  );

  return (
    <>
      <Zone
        config={{
          gap: {
            y: 2,
          },
          zones: [['form']],
          rows: ['1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'form' }}>
          <Form
            data={{ value: context }}
            config={{ validateOnChange: true }}
            handleEvent={{
              change: (value: any) => {
                submit(value);
              },
              validate: (value: any) => {
                validate(value);
              },
            }}
          >
            <Form.Structure
              config={{ layout: 'column', width: 'initial', fill: true }}
            >
              <Row>
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'firstName',
                    label: 'firstName',
                    rules: [{ required: true }],
                  }}
                >
                  <Input config={{ placeholder: 'Jhon' }} />
                </Form.Item>
                <Space config={{ way: 'horizontal' }} />
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'lastName',
                    label: 'lastName',
                    rules: [{ required: true }],
                  }}
                >
                  <Input config={{ placeholder: 'Doe' }} />
                </Form.Item>
              </Row>
              <Row>
                <Space config={{ way: 'horizontal' }} />
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'lang',
                    label: 'lang',
                    rules: [{ required: true }],
                  }}
                >
                  <Select
                    data={{
                      items: [
                        { label: 'fr', value: 'fr_FR' },
                        { label: 'en', value: 'en_EN' },
                      ],
                    }}
                  />
                </Form.Item>
              </Row>
            </Form.Structure>
          </Form>
        </Zone.Area>
      </Zone>
    </>
  );
};
