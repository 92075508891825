import { gql } from '@apollo/client';
import { energiesEntity } from '../entity/energies.gql';

export const siteEnergieGql = gql`
 query site($siteId: String!, $startDate: String, $stopDate: String) {
    site(siteId: $siteId) {
     ${energiesEntity}
    }
  }
`;
