import { assign, createMachine, interpret } from 'xstate';

const initialContext = {
  error: undefined,
  result: undefined,
  data: null,
};

const machine = createMachine(
  {
    id: 'createPrestoScan',
    initial: 'off',
    context: initialContext,
    predictableActionArguments: true,
    states: {
      off: {
        on: {
          WAKEUP: {
            target: 'idle',
            actions: assign({
              result: () => undefined,
            }),
          },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
          },
          REGISTER: {
            actions: assign({
              data: (_, { payload }) => payload,
            }),
          },
        },
      },

      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },
  },
  {
    services: {},
  },
);

export const createPrestoScanService = interpret(machine).start();
