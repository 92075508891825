import { Form, requestGQL } from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { DatePicker } from '@gimlite/watermelon/components/datepicker/datepicker.component';
import { FilterExpanded } from '@gimlite/watermelon/components/filter/filter.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Select } from '@gimlite/watermelon/components/select/select.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { sessionsGql } from '../gql/sessions.gql';
import { sessionsRender } from '../projection/sessions.render';

const selectOptionsSessions = [
  { label: 'CREATED', value: 'CREATED' },
  {
    label: 'ENTRY_AUTHORIZED',
    value: 'ENTRY_AUTHORIZED',
  },
  { label: 'ENTRY_DENIED', value: 'ENTRY_DENIED' },
  { label: 'ENTERED', value: 'ENTERED' },
  {
    label: 'EXIT_AUTHORIZE_PENDING',
    value: 'EXIT_AUTHORIZE_PENDING',
  },
  {
    label: 'EXIT_AUTHORIZED',
    value: 'EXIT_AUTHORIZED',
  },
  { label: 'EXIT_DENIED', value: 'EXIT_DENIED' },
  { label: 'EXITED', value: 'EXITED' },
  {
    label: 'AUTHORIZE_PEDESTRIAN',
    value: 'AUTHORIZE_PEDESTRIAN',
  },
  {
    label: 'PAYMENT_NOTIFIED',
    value: 'PAYMENT_NOTIFIED',
  },
];

export const SessionsSearch = () => {
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();

  const [search, setSearch] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { limit: 15, ...getParamsUrl },
          gql: sessionsGql,
          render: (res) => sessionsRender(res),
        });

        setSearch(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl]);

  return (
    <Page>
      <Widget config={{ title: 'Liste des sessions' }}>
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'states',
                    label: 'States',
                  }}
                >
                  <Select
                    data={{
                      defaultValue: getParamsUrl?.states,
                      items: selectOptionsSessions,
                    }}
                    config={{
                      mode: 'multiple',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  config={{
                    name: 'beginEnd',
                    label: 'Période de stationnement',
                  }}
                >
                  <DatePicker
                    config={{
                      mode: 'range',
                    }}
                    data={{ defaultValue: getParamsUrl?.beginEnd }}
                  />
                </Form.Item>
              </FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Rechercher', type: { value: 'submit' } }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>
          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
              }}
              data={search}
              config={{
                columns: [
                  {
                    title: 'Consumer',
                    key: 'consumer',
                  },
                  {
                    title: 'State',
                    key: 'state',
                  },
                  {
                    title: 'LogicalId',
                    key: 'logicalId',
                  },
                  {
                    title: 'Segment',
                    key: 'segment',
                  },
                  {
                    title: 'EntryDate',
                    key: 'entryDate',
                  },
                  {
                    title: 'ExitDate',
                    key: 'exitDate',
                  },
                ],
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
