import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Upload } from '@gimlite/watermelon/components/upload/upload.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import {
  isValidEmail,
  isValidPhoneNumber,
} from '@gimlite/watermelon/functions/validator.function';
import { useSelector } from '@xstate/react';
import { updateOperatorService } from '../updateOperator.machine';
import { UpdateOperatorWizardContext } from '../updateOperator.wizard';

export const OperatorStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<UpdateOperatorWizardContext>) => {
  const operator = useSelector(
    updateOperatorService,
    ({ context }: any) => context.operator,
  );

  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      {operator && (
        <Form.Structure>
          <Form.Item
            data={{
              defaultValue: operator.name ?? '',
            }}
            config={{
              name: 'name',
              label: 'Nom',
              rules: [{ required: true }],
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            data={{
              defaultValue: operator.code ?? '',
            }}
            config={{
              name: 'code',
              label: 'Code',
              rules: [{ required: true }],
            }}
          >
            <Input config={{ disabled: true }} />
          </Form.Item>
          <Form.Item
            data={{
              defaultValue: operator.operatorCode ?? '',
            }}
            config={{
              name: 'operatorCode',
              label: 'Operator code',
              rules: [{ required: true }],
            }}
          >
            <Input config={{ disabled: true }} />
          </Form.Item>
          <Form.Item
            data={{
              defaultValue: operator.email ?? '',
            }}
            config={{
              name: 'email',
              label: 'Email',
              rules: [{ validator: isValidEmail }],
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            data={{
              defaultValue: operator.phone ?? '',
            }}
            config={{
              name: 'phone',
              label: 'Phone',
              rules: [{ validator: isValidPhoneNumber }],
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            config={{
              name: 'logoMain',
              label: 'Logo principal',
              rules: [],
            }}
          >
            <Upload
              data={{
                defaultValue: operator.display?.logoMain
                  ? [operator.display.logoMain]
                  : [],
              }}
              config={{
                disabled: true,
              }}
            />
          </Form.Item>
          <Form.Item
            config={{
              name: 'logoSecondary',
              label: 'Logo secondaire',
              rules: [],
            }}
          >
            <Upload
              data={{
                defaultValue: operator.display?.logoSecondary
                  ? [operator.display.logoSecondary]
                  : [],
              }}
              config={{
                disabled: true,
              }}
            />
          </Form.Item>
        </Form.Structure>
      )}
    </Form>
  );
};
