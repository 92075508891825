import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import {
  digitRule,
  isValidEmail,
  lowercaseLetterRule,
  minLengthPasswordRule,
  specialCharRule,
  uppercaseLetterRule,
} from '@gimlite/watermelon/functions/validator.function';
import { CreateControlUserWizardContext } from '../createControlUser.wizard';

export const CredentialsStep = ({
  context: { credentials },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateControlUserWizardContext>) => {
  return (
    <Form
      data={{ value: credentials }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit({ credentials: value });
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'username',
            label: 'Adresse email',
            rules: [{ required: true }, { validator: isValidEmail }],
          }}
        >
          <Input config={{ placeholder: 'jhon@snow.ch' }} />
        </Form.Item>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'password',
            label: 'Mot de passe',
            rules: [
              { required: true },
              {
                validator: minLengthPasswordRule,
              },
              {
                validator: uppercaseLetterRule,
              },
              {
                validator: lowercaseLetterRule,
              },
              {
                validator: digitRule,
              },
              {
                validator: specialCharRule,
              },
            ],
          }}
        >
          <Input
            config={{
              placeholder: 'password',
              type: { name: 'password' },
            }}
          />
        </Form.Item>
      </Form.Structure>
    </Form>
  );
};
