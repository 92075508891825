import { gql } from "@apollo/client";

export const updateAdminUserGql = gql`
    mutation updateAdminUser($id: String!, $password: String) {
    
        updateAdminUser(id: $id, password: $password) {
            _id
            state
            credentials {
                value
                type
            }
        }

    }
`