import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Select } from '@gimlite/watermelon/components/select/select.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import {
  isValidIANA,
  isValidLocale,
} from '@gimlite/watermelon/functions/validator.function';
import { CreateClientWizardContext } from '../createClient.wizard';

export const ClientStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreateClientWizardContext>) => {
  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'name',
            label: 'Name',
            rules: [{ required: true }],
          }}
        >
          <Input config={{ placeholder: 'Plan les Ouates' }} />
        </Form.Item>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'slug',
            label: 'Slug',
            rules: [{ required: true }],
          }}
        >
          <Input config={{ placeholder: 'plan-les-ouates' }} />
        </Form.Item>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'ticketsHub',
            label: 'Tickets Hub',
            rules: [{ required: true }],
          }}
        >
          <Select
            data={{
              items: [
                { label: 'Iem', value: 'iem' },
                { label: 'Prestopark', value: 'prestopark' },
              ],
            }}
          />
        </Form.Item>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'code',
            label: 'Code',
            rules: [{ required: true, max: 4 }],
          }}
        >
          <Input config={{ placeholder: 'PLO1' }} />
        </Form.Item>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'defaultContractTag',
            label: 'Contract Tag',
            rules: [{ required: true }],
          }}
        >
          <Input config={{ placeholder: 'iem/city' }} />
        </Form.Item>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'siret',
            label: 'Siret',
            rules: [{ required: true, min: 14 }],
          }}
        >
          <Input config={{ placeholder: '12345678987654321' }} />
        </Form.Item>

        <Form.Item
          config={{
            way: 'vertical',
            name: 'timezone',
            label: 'Timezone',
            rules: [{ required: true }, { validator: isValidIANA }],
          }}
        >
          <Input config={{ placeholder: 'Europe/Paris' }} />
        </Form.Item>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'locale',
            label: 'Locale',
            rules: [{ required: true }, { validator: isValidLocale }],
          }}
        >
          <Input config={{ placeholder: 'fr_FR' }} />
        </Form.Item>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'defaultCurrency',
            label: 'Currency',
            rules: [{ required: true }],
          }}
        >
          <Input config={{ placeholder: 'chf' }} />
        </Form.Item>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'maximumDuration',
            label: 'Maximum duration',
            rules: [{ required: true }],
          }}
        >
          <Input
            config={{
              placeholder: '120',
              type: { name: 'number' },
            }}
          />
        </Form.Item>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'maximumAmount',
            label: 'Maximum amount',
            rules: [{ required: true }],
          }}
        >
          <Input
            config={{
              placeholder: '600',
              type: { name: 'number' },
            }}
          />
        </Form.Item>
      </Form.Structure>
    </Form>
  );
};
