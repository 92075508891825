import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { Duration } from 'luxon';

export const searchRender = ({ list, paging }) => {
  return {
    list: list.map(({ client, maximumDuration, shape, ...rest }) => {
      return {
        client: client?.slug,
        maximumDuration: !!maximumDuration
          ? Duration.fromMillis(maximumDuration * 1000).toFormat('hh:mm')
          : null,
        shape: !!!shape ? (
          <Badge config={{ color: 'error', text: 'Indisponible' }} />
        ) : (
          <Badge config={{ color: 'success', text: 'Disponible' }} />
        ),
        ...rest,
      };
    }),
    paging,
  };
};

export const readRender = ({ code, client, ...rest }) => {
  const details = [
    { key: 'code', label: 'Code', value: code },
    { key: 'client', label: 'Client', value: client.slug },
  ];

  return { details, code, client, ...rest };
};
