import { gql } from '@apollo/client';

export const createAdminUserGql = gql`
  mutation createAdminUsers($input: CreateAdminUserDto!) {
    createAdminUsers(input: $input) {
      adminUser {
        _id
      }
    }
  }
`;
