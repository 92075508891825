import { DateTime } from 'luxon';

export const searchRender = ({ list, paging }) => {
  return {
    list: list.map(({ updatedAt, ...rest }) => {
      return {
        updatedAt: DateTime.fromISO(updatedAt).toFormat('dd/MM/yy HH:mm:ss'),
        ...rest,
      };
    }),
    paging,
  };
};
