import { CloudDownloadOutlined } from '@ant-design/icons';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { statsEnergie } from './energie.render';

export const readGlobalRender = ({
  versions,
  monitoring,
  peripherals,
  energies,
  ...rest
}) => {
  const energiesGraph = energies?.graph;

  const status = {
    up: {
      status: 'success',
      title: 'La machine fonctionne correctement',
    },
    degraded: {
      status: 'warning',
      title: 'La machine est dans état dégradé',
    },
    down: {
      status: 'error',
      title: 'La machine est hors service',
    },
    'not-responding': {
      status: 'warning',
      title: 'La machine ne répond pas',
    },
  };

  const stateFormatted = monitoring
    ? status[monitoring?.status]
    : { status: 'error', title: 'Aucun terminal assigné' };

  const peripheralsFormatted = peripherals?.map(
    ({ kind, swvers, mfg, mdl, hwvers, bootvers }) => {
      return {
        title: kind,
        description: swvers,
        $expandable: (
          <Description
            data={[
              {
                key: `mfg`,
                label: 'Manufacturer',
                value: mfg,
              },
              {
                key: `mdl+hwvers`,
                label: 'Model',
                value: `${mdl ?? ''}${hwvers ? `-${hwvers}` : ''}`,
              },
              {
                key: `swvers`,
                label: 'Firmware',
                value: swvers,
              },
              {
                key: `bootvers`,
                label: 'Bootloader',
                value: bootvers,
              },
            ]}
          />
        ),
      };
    },
  );

  const currentAlarms = Object.values(monitoring?.currentAlarms || []).reduce(
    (acc, { alarm, effect, extra }) => {
      const errorMapper = {
        infos: 'basic',
        'action-requested': 'warn',
        degraded: 'warn',
        'out-of-order': 'error',
      };
      return [
        ...acc,
        {
          title: alarm.charAt(0).toUpperCase() + alarm.slice(1),
          badge: {
            text: effect ? effect.toUpperCase() : 'PROBLEME',
            color: errorMapper[effect] || 'error',
          },
          $expandable: extra ? (
            <Description
              data={extra.map((data, index) => {
                return {
                  key: `extra-${index}`,
                  label: '-',
                  value: data,
                };
              })}
            />
          ) : undefined,
        },
      ];
    },
    [],
  );

  const versionsFormatted = versions?.map(({ name, version }) => {
    return {
      title: name,
      description: version,
      icon: <CloudDownloadOutlined />,
    };
  });

  return {
    state: stateFormatted,
    peripherals: peripheralsFormatted,
    currentAlarms,
    versions: versionsFormatted,
    energies: energiesGraph ? statsEnergie(energiesGraph) : null,
    ...rest,
  };
};
