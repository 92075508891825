import { useNavigate } from '@gimlite/router';
import { requestGQL, wizardService } from '@gimlite/watermelon';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import {
  Table,
  TableType,
} from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { adminUsersGql } from '../gql/admin-users.gql';
import { searchRender } from '../projection/admin-users.render';

type UserRow = {
  _id: string;
  state: string;
  username: string;
};

export const AdminUsersSearch = () => {
  const navigate = useNavigate();
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const [search, setSearch] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { limit: 15, ...getParamsUrl },
          gql: adminUsersGql,
          render: (res) => searchRender(res),
        });

        setSearch(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl]);

  return (
    <Page>
      <Widget config={{ title: 'Listes des utilisateurs' }}>
        <Zone
          config={{
            zones: [['table']],
            rows: ['1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'table' }}>
            <Table<UserRow>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: TableType.Data.Line<UserRow>) => {
                  setParamsUrl({ ...getParamsUrl, id: value._id });
                  wizardService.send('SHOW_WIZARD', { id: 'updateAdminUser' });
                },
              }}
              data={search}
              config={{
                columns: [
                  { title: 'username', key: 'username' },
                  { title: 'state', key: 'state' },
                ],
                actions: { read: true },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
