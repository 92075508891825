import { MinusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Select,
  SelectType,
  notifService,
  requestGQL,
} from '@gimlite/watermelon';
import { AutoComplete } from '@gimlite/watermelon/components/autocomplete/autocomplete.component';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useCallback, useMemo } from 'react';
import {
  CreatePrestoScanWizardContext,
  langsItemsSelect,
  spaceBeetwenInput,
  spaceBeetwenTitle,
} from '../createPrestoScan.wizard';
import { UpsResponse, upsGql, upsParams } from '../gql/ups.gql';
import { UpsListResponse, upsListGql, upsListParams } from '../gql/upsList.gql';
import './general.scss';

export const UpsStep = ({
  context: {
    listParking,
    modeParking,
    searchParking,
    createParking,
    searchClient,
    createVAO,
  },
  validate,
  submit,
}: WizardItemType.Config.Component<CreatePrestoScanWizardContext>) => {
  const formattedTranslateLang = useMemo(
    () =>
      langsItemsSelect.map(
        ({ value: langBase, label }: SelectType.Data.Item) => ({
          value: langBase,
          label,
          disabled: searchParking?.translation
            ? searchParking?.translation
                .filter(Boolean)
                .map((translate) => translate.lang)
                .includes(langBase as string)
            : false,
        }),
      ),
    [searchParking],
  );

  const searchParkingsFn = useCallback(
    async ({
      codePattern,
      clientId,
    }: {
      codePattern: string;
      clientId: string | null;
    }) => {
      try {
        const response = (await requestGQL({
          params: { codePattern, clientId } as upsListParams,
          gql: upsListGql,
        })) as UpsListResponse;

        submit({ listParking: response.list });
      } catch (error) {
        notifService.send('NOTIF', {
          content: error,
          mode: 'error',
        });
        submit({ listParking: [] });
      }
    },
    [],
  );

  const readParkingFn = useCallback(async ({ upsId }: { upsId: string }) => {
    try {
      const {
        code,
        _id,
        address,
        center,
        config,
        translation,
        cameras,
        occupancy,
        capacity,
      } = (await requestGQL({
        params: { upsId } as upsParams,
        gql: upsGql,
      })) as UpsResponse;

      submit({
        searchParking: {
          _id: _id,
          code: code,
          addressCountry: address?.addressCountry || null,
          addressLocality: address?.addressLocality || null,
          addressSubRegion: address?.addressSubRegion || null,
          postOfficeBoxNumber: address?.postOfficeBoxNumber || null,
          streetName: address?.streetName || null,
          specialPlace: address?.specialPlace || null,
          streetNumber: address?.streetNumber || null,
          streetNumberBis: address?.streetNumberBis || null,
          postalCode: address?.postalCode || null,
          streetType: address?.streetType || null,
          capacity,
          occupancy,
          latitude: center ? center[0] : null,
          longitude: center ? center[1] : null,
          translation,
        },
        cameras: cameras.map(({ _id, position, code, description }) => ({
          _id,
          position,
          code,
          description,
        })),
        camerasSave: Object.freeze(
          cameras.map(({ _id, position, code, description }) => ({
            _id,
            position,
            code,
            description,
          })),
        ),
        createVAO: {
          sitesLegacyId:
            config?.prestoScan?.sitesLegacyId ||
            createVAO.sitesLegacyId ||
            null,
          rightsLegacyId:
            config?.prestoScan?.rightsLegacyId ||
            createVAO.rightsLegacyId ||
            null,
          deletionDelay_complete:
            config?.prestoScan?.deletionDelay.complete ||
            createVAO.deletionDelay_complete ||
            null,
          deletionDelay_fined:
            config?.prestoScan?.deletionDelay.fined ||
            createVAO.deletionDelay_fined ||
            null,
          deletionDelay_incomplete:
            config?.prestoScan?.deletionDelay.incomplete ||
            createVAO.deletionDelay_incomplete ||
            null,
          deletionDelay_toCheck:
            config?.prestoScan?.deletionDelay.toCheck ||
            createVAO.deletionDelay_toCheck ||
            null,
          deletionDelay_unfined:
            config?.prestoScan?.deletionDelay.unfined ||
            createVAO.deletionDelay_unfined ||
            null,
          entryTolerancePeriod:
            config?.prestoScan?.entryTolerancePeriod || null,
          exitTolerancePeriod: config?.prestoScan?.exitTolerancePeriod || null,
          isVerbalizationAllowed:
            config?.prestoScan?.isVerbalizationAllowed || null,
          outrageTreshold_danger:
            config?.prestoScan?.outrageTreshold.danger || null,
          outrageTreshold_warning:
            config?.prestoScan?.outrageTreshold.warning || null,
          showOccupancy: config?.prestoScan?.showOccupancy || null,
        },
        FPS: {
          addressCountry:
            config?.fps?.recourseOrganization?.address?.addressCountry || null,
          addressLocality:
            config?.fps?.recourseOrganization?.address?.addressLocality || null,
          addressSubRegion:
            config?.fps?.recourseOrganization?.address?.addressLocality || null,
          postOfficeBoxNumber:
            config?.fps?.recourseOrganization?.address?.postOfficeBoxNumber ||
            null,
          streetName:
            config?.fps?.recourseOrganization?.address?.streetName || null,
          postalCode:
            config?.fps?.recourseOrganization?.address?.postalCode || null,
          streetNumber:
            config?.fps?.recourseOrganization?.address?.streetNumber || null,
          streetType:
            config?.fps?.recourseOrganization?.address?.streetType || null,
          streetNumberBis:
            config?.fps?.recourseOrganization?.address?.streetNumberBis || null,
          specialPlace:
            config?.fps?.recourseOrganization?.address?.specialPlace || null,
          reducedPriceDuration: config?.fps?.reducedPriceDuration || null,
          reducedFinePrice: config?.fps?.reducedFinePrice || null,
          finePrice: config?.fps?.finePrice || null,
          organizationId:
            config?.fps?.recourseOrganization?.organizationId || null,
          url: config?.fps?.recourseOrganization?.url || null,
          name: config?.fps?.recourseOrganization?.name || null,
          notificationAuthority: config?.fps?.notificationAuthority || null,
        },
      });
    } catch (error) {
      notifService.send('NOTIF', {
        content: 'SERVER_PROBLEM',
        mode: 'error',
      });
      submit({
        searchParking: {
          _id: null,
          code: null,
          addressCountry: null,
          addressLocality: null,
          addressSubRegion: null,
          postOfficeBoxNumber: null,
          streetName: null,
          specialPlace: null,
          streetNumber: null,
          streetNumberBis: null,
          postalCode: null,
          streetType: null,
          capacity: null,
          latitude: null,
          longitude: null,
          occupancy: null,
          translation: [],
        },
      });
    }
  }, []);

  return (
    <>
      <Zone
        config={{
          gap: {
            y: 2,
          },
          zones: [['filter'], ['form']],
          rows: ['min-content', '1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'filter' }}>
          {/* <Segmented
            handleEvent={{
              option: (value) => {
                validate(false);

                submit({
                  modeParking: value as 'create' | 'search',
                  searchParking: {
                    _id: null,
                    code: null,
                    addressCountry: null,
                    addressLocality: null,
                    addressSubRegion: null,
                    postOfficeBoxNumber: null,
                    streetName: null,
                    specialPlace: null,
                    streetNumber: null,
                    streetNumberBis: null,
                    postalCode: null,
                    streetType: null,
                    capacity: null,
                    latitude: null,
                    longitude: null,
                    occupancy: null,
                    translation: [],
                  },
                  createParking: {
                    addressCountry: null,
                    addressLocality: null,
                    addressSubRegion: null,
                    capacity: null,
                    code: null,
                    latitude: null,
                    longitude: null,
                    occupancy: null,
                    postOfficeBoxNumber: null,
                    postalCode: null,
                    specialPlace: null,
                    streetName: null,
                    streetNumber: null,
                    streetNumberBis: null,
                    streetType: null,
                    translation: [],
                  },
                });
              },
            }}
            data={{ selected: modeParking }}
            config={{
              options: [
                ...(!searchClient._id
                  ? []
                  : [
                      {
                        value: 'search',
                        label: 'Trouver un parking',
                      },
                    ]),
                {
                  value: 'create',
                  label: 'Créer un parking',
                },
              ],
            }}
          /> */}
          <></>
        </Zone.Area>

        <Zone.Area config={{ area: 'form' }}>
          {modeParking === 'search' && (
            <Form
              data={{ value: searchParking }}
              config={{
                validateOnChange: true,
              }}
              handleEvent={{
                change: (
                  value: CreatePrestoScanWizardContext['searchParking'],
                ) => {
                  submit({
                    searchParking: { ...value, _id: searchParking._id },
                  });
                },
                validate: (value) => {
                  console.log({ validate: value });
                  validate(value);
                },
              }}
            >
              <Form.Structure
                config={{ layout: 'column', width: 'full', fill: true }}
              >
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'code',
                    label: 'Code du parking',
                    rules: [{ required: true }],
                  }}
                >
                  <AutoComplete
                    handleEvent={{
                      input: (value) => {
                        searchParkingsFn({
                          codePattern: value,
                          clientId: searchClient._id,
                        });
                      },
                      select(item) {
                        if (item?.value) {
                          readParkingFn({ upsId: item.value });
                        }
                      },
                    }}
                    data={{
                      items: listParking.map(({ code, _id }) => ({
                        value: _id,
                        label: code,
                      })),
                    }}
                    config={{
                      search: {
                        caseSensitive: false,
                        accentSensitive: false,
                        pattern: 'start',
                      },
                      height: 'xlarge',
                      width: 'full',
                      placeholder: 'plan-les-ouates-a1',
                    }}
                  />
                </Form.Item>

                {searchParking._id && (
                  <Space
                    config={{ count: spaceBeetwenTitle, way: 'vertical' }}
                  ></Space>
                )}

                {searchParking._id && (
                  <Write
                    data={{ item: 'Nom du parking' }}
                    config={{
                      mode: 'title-small',
                    }}
                  ></Write>
                )}

                {searchParking._id && (
                  <Form.List
                    config={{
                      name: 'translation',
                    }}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map(
                          ({ name, key, ...restOfField }: any, index) => {
                            console.log('🚀 ~ restOfField:', {
                              restOfField,
                              name,
                              key,
                            });
                            return (
                              <div key={key}>
                                {index !== 0 && (
                                  <Space
                                    config={{ count: 2, way: 'vertical' }}
                                  ></Space>
                                )}

                                <Row
                                  config={{
                                    vertical: 'end',
                                    horizontal: 'end',
                                  }}
                                >
                                  <Form.Item
                                    {...restOfField}
                                    config={{
                                      way: 'vertical',
                                      label: 'Langue',
                                      name: [name, 'lang'],
                                      rules: [{ required: true }],
                                    }}
                                  >
                                    <Select
                                      data={{
                                        items: formattedTranslateLang,
                                      }}
                                      config={{
                                        height: 'xlarge',
                                        width: 'medium',
                                      }}
                                    ></Select>
                                  </Form.Item>
                                  <Space
                                    config={{
                                      count: spaceBeetwenInput,
                                      way: 'horizontal',
                                    }}
                                  ></Space>
                                  <Form.Item
                                    {...restOfField}
                                    config={{
                                      way: 'vertical',
                                      label: 'Nom du parking',
                                      name: [name, 'name'],
                                      rules: [{ required: true }],
                                    }}
                                  >
                                    <Input
                                      config={{
                                        height: 'xlarge',
                                        width: 'full',
                                        placeholder: 'Parking du Vélodrome',
                                      }}
                                    />
                                  </Form.Item>
                                  <Space
                                    config={{
                                      count: spaceBeetwenInput,
                                      way: 'horizontal',
                                    }}
                                  ></Space>
                                  <Form.Item
                                    {...restOfField}
                                    config={{
                                      way: 'vertical',
                                      label: 'Description du parking',
                                      name: [name, 'description'],
                                    }}
                                  >
                                    <Input
                                      config={{
                                        height: 'xlarge',
                                        width: 'full',
                                        placeholder: '',
                                      }}
                                    />
                                  </Form.Item>
                                  <Space
                                    config={{
                                      count: spaceBeetwenInput,
                                      way: 'horizontal',
                                    }}
                                  ></Space>
                                  <div className="upsTranslateDeleteContain">
                                    <MinusCircleOutlined
                                      style={{ fontSize: '25px' }}
                                      onClick={() => remove(name)}
                                    />
                                  </div>
                                </Row>
                              </div>
                            );
                          },
                        )}
                        <Space config={{ count: 2, way: 'vertical' }}></Space>
                        {createParking.translation.length <
                          formattedTranslateLang.length && (
                          <Row config={{ horizontal: 'center' }}>
                            <Button
                              handleEvent={{
                                click: () => add(),
                              }}
                              config={{ text: 'Ajouter une traduction' }}
                            />
                          </Row>
                        )}
                      </>
                    )}
                  </Form.List>
                )}

                {searchParking._id && (
                  <Write
                    data={{ item: 'Adresse du parking' }}
                    config={{
                      mode: 'title-small',
                    }}
                  ></Write>
                )}

                {searchParking._id && (
                  <Row>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'streetNumber',
                        label: 'N° de voie',
                      }}
                    >
                      <Input
                        config={{
                          type: { name: 'number', min: 1 },
                          height: 'xlarge',
                          width: 'xsmall',
                          placeholder: '7',
                        }}
                      />
                    </Form.Item>
                    <Space
                      config={{
                        count: spaceBeetwenInput,
                        way: 'horizontal',
                      }}
                    ></Space>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'streetNumberBis',
                        label: 'N° de voie bis',
                      }}
                    >
                      <Input
                        config={{
                          type: { name: 'number' },
                          height: 'xlarge',
                          width: 'xsmall',
                          placeholder: '',
                        }}
                      />
                    </Form.Item>
                    <Space
                      config={{
                        count: spaceBeetwenInput,
                        way: 'horizontal',
                      }}
                    ></Space>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'streetType',
                        label: 'Type de voie',
                      }}
                    >
                      <Input
                        config={{
                          height: 'xlarge',
                          width: 'xsmall',
                          placeholder: 'Avenue',
                        }}
                      />
                    </Form.Item>
                    <Space
                      config={{
                        count: spaceBeetwenInput,
                        way: 'horizontal',
                      }}
                    ></Space>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'streetName',
                        label: 'Nom de la voie',
                      }}
                    >
                      <Input
                        config={{
                          height: 'xlarge',
                          width: 'full',
                          placeholder: 'Montaigne',
                        }}
                      />
                    </Form.Item>
                  </Row>
                )}

                {searchParking._id && (
                  <Row>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'specialPlace',
                        label: 'Dénomination spéciale',
                      }}
                    >
                      <Input
                        config={{
                          height: 'xlarge',
                          width: 'full',
                          placeholder: 'La Tour Eiffel',
                        }}
                      />
                    </Form.Item>
                    <Space
                      config={{
                        count: spaceBeetwenInput,
                        way: 'horizontal',
                      }}
                    ></Space>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'postOfficeBoxNumber',
                        label: 'Boîte postale',
                      }}
                    >
                      <Input
                        config={{
                          height: 'xlarge',
                          width: 'full',
                          placeholder: '',
                        }}
                      />
                    </Form.Item>
                  </Row>
                )}

                {searchParking._id && (
                  <Row>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'postalCode',
                        label: 'Code postal',
                        rules: [{ required: true }],
                      }}
                    >
                      <Input
                        config={{
                          height: 'xlarge',
                          width: 'small',
                          placeholder: '75008',
                        }}
                      />
                    </Form.Item>
                    <Space
                      config={{
                        count: spaceBeetwenInput,
                        way: 'horizontal',
                      }}
                    ></Space>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'addressLocality',
                        label: 'Ville',
                        rules: [{ required: true }],
                      }}
                    >
                      <Input
                        config={{
                          height: 'xlarge',
                          width: 'full',
                          placeholder: '8ème arrondissement',
                        }}
                      />
                    </Form.Item>
                    <Space
                      config={{
                        count: spaceBeetwenInput,
                        way: 'horizontal',
                      }}
                    ></Space>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'addressSubRegion',
                        label: 'Département',
                        rules: [{ required: true }],
                      }}
                    >
                      <Input
                        config={{
                          height: 'xlarge',
                          width: 'full',
                          placeholder: 'Paris',
                        }}
                      />
                    </Form.Item>
                    <Space
                      config={{
                        count: spaceBeetwenInput,
                        way: 'horizontal',
                      }}
                    ></Space>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'addressCountry',
                        label: 'Pays ISO 3166-1 alpha-2',
                        rules: [{ required: true, max: 2, min: 2 }],
                      }}
                    >
                      <Input
                        config={{
                          height: 'xlarge',
                          width: 'full',
                          placeholder: 'FR',
                        }}
                      />
                    </Form.Item>
                  </Row>
                )}

                {searchParking._id && (
                  <Space
                    config={{ count: spaceBeetwenTitle, way: 'vertical' }}
                  ></Space>
                )}
                {searchParking._id && (
                  <Write
                    data={{ item: 'Coordonnées du parking' }}
                    config={{
                      mode: 'title-small',
                    }}
                  ></Write>
                )}

                {searchParking._id && (
                  <Row>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'latitude',
                        label: 'Latitude',
                        rules: [{ required: true }],
                      }}
                    >
                      <Input
                        config={{
                          type: { name: 'number' },
                          height: 'xlarge',
                          width: 'full',
                          placeholder: '47,423557938',
                        }}
                      />
                    </Form.Item>
                    <Space
                      config={{
                        count: spaceBeetwenInput,
                        way: 'horizontal',
                      }}
                    ></Space>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'longitude',
                        label: 'Longitude',
                        rules: [{ required: true }],
                      }}
                    >
                      <Input
                        config={{
                          type: { name: 'number' },
                          height: 'xlarge',
                          width: 'full',
                          placeholder: '9,3732261657',
                        }}
                      />
                    </Form.Item>
                  </Row>
                )}
                {searchParking._id && (
                  <Space
                    config={{ count: spaceBeetwenTitle, way: 'vertical' }}
                  ></Space>
                )}
                {searchParking._id && (
                  <Write
                    data={{ item: 'Caractéristique du parking' }}
                    config={{
                      mode: 'title-small',
                    }}
                  ></Write>
                )}

                {searchParking._id && (
                  <Row>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'capacity',
                        label: 'Capacité',
                        rules: [{ required: true }],
                      }}
                    >
                      <Input
                        config={{
                          type: { name: 'number' },
                          height: 'xlarge',
                          width: 'full',
                          placeholder: '180',
                        }}
                      />
                    </Form.Item>
                    <Space
                      config={{
                        count: spaceBeetwenInput,
                        way: 'horizontal',
                      }}
                    ></Space>
                    <Form.Item
                      config={{
                        way: 'vertical',
                        name: 'occupancy',
                        label: 'Occupation',
                        rules: [{ required: true }],
                      }}
                    >
                      <Input
                        config={{
                          type: { name: 'number' },
                          height: 'xlarge',
                          width: 'full',
                          placeholder: '54',
                        }}
                      />
                    </Form.Item>
                  </Row>
                )}
              </Form.Structure>
            </Form>
          )}

          {/* 
          {modeParking === 'create' && (
            <Form
              data={{ value: createParking }}
              config={{ validateOnChange: true }}
              handleEvent={{
                change: (
                  value: CreatePrestoScanWizardContext['createParking'],
                ) => {
                  submit({ createParking: value });
                },
                validate: (value) => {
                  validate(value);
                },
              }}
            >
              <Form.Structure
                config={{ layout: 'column', width: 'full', fill: true }}
              >
                <Write
                  data={{ item: 'Identité du parking' }}
                  config={{
                    mode: 'title-small',
                  }}
                ></Write>

                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'code',
                      label: 'Code',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'plan-les-ouates-velodrome',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Space
                  config={{ count: spaceBeetwenTitle, way: 'vertical' }}
                ></Space>
                <Write
                  data={{ item: 'Traduction du nom' }}
                  config={{
                    mode: 'title-small',
                  }}
                ></Write>

                <Form.List
                  data={{
                    defaultValues: [
                      { lang: null, name: null, description: null },
                    ],
                  }}
                  config={{
                    name: 'translation',
                  }}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(
                        ({ name, key, ...restOfField }: any, index) => (
                          <div key={key}>
                            {index !== 0 && (
                              <Space
                                config={{ count: 2, way: 'vertical' }}
                              ></Space>
                            )}

                            <Row
                              config={{ vertical: 'end', horizontal: 'end' }}
                            >
                              <Form.Item
                                {...restOfField}
                                config={{
                                  way: 'vertical',
                                  label: 'Langue',
                                  name: [name, 'lang'],
                                  rules: [{ required: true }],
                                }}
                              >
                                <Select
                                  data={{
                                    items: formattedTranslateLang,
                                  }}
                                  config={{
                                    height: 'xlarge',
                                    width: 'medium',
                                  }}
                                ></Select>
                              </Form.Item>
                              <Space
                                config={{
                                  count: spaceBeetwenInput,
                                  way: 'horizontal',
                                }}
                              ></Space>
                              <Form.Item
                                {...restOfField}
                                config={{
                                  way: 'vertical',
                                  label: 'Nom du parking',
                                  name: [name, 'name'],
                                  rules: [{ required: true }],
                                }}
                              >
                                <Input
                                  config={{
                                    height: 'xlarge',
                                    width: 'full',
                                    placeholder: 'Parking du Vélodrome',
                                  }}
                                />
                              </Form.Item>
                              <Space
                                config={{
                                  count: spaceBeetwenInput,
                                  way: 'horizontal',
                                }}
                              ></Space>
                              <Form.Item
                                {...restOfField}
                                config={{
                                  way: 'vertical',
                                  label: 'Description du parking',
                                  name: [name, 'description'],
                                }}
                              >
                                <Input
                                  config={{
                                    height: 'xlarge',
                                    width: 'full',
                                    placeholder: '',
                                  }}
                                />
                              </Form.Item>
                              <Space
                                config={{
                                  count: spaceBeetwenInput,
                                  way: 'horizontal',
                                }}
                              ></Space>
                              <div className="upsTranslateDeleteContain">
                                <MinusCircleOutlined
                                  style={{ fontSize: '25px' }}
                                  onClick={() => remove(name)}
                                />
                              </div>
                            </Row>
                          </div>
                        ),
                      )}
                      <Space config={{ count: 2, way: 'vertical' }}></Space>
                      {createParking.translation.length <
                        formattedTranslateLang.length && (
                        <Row config={{ horizontal: 'center' }}>
                          <Button
                            handleEvent={{
                              click: () => add(),
                            }}
                            config={{ text: 'Ajouter une traduction' }}
                          />
                        </Row>
                      )}
                    </>
                  )}
                </Form.List>

                <Space
                  config={{ count: spaceBeetwenTitle, way: 'vertical' }}
                ></Space>
                <Write
                  data={{ item: 'Adresse du parking' }}
                  config={{
                    mode: 'title-small',
                  }}
                ></Write>

                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'streetNumber',
                      label: 'N° de voie',
                    }}
                  >
                    <Input
                      config={{
                        type: { name: 'number' },
                        height: 'xlarge',
                        width: 'xsmall',
                        placeholder: '7',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'streetNumberBis',
                      label: 'N° de voie bis',
                    }}
                  >
                    <Input
                      config={{
                        type: { name: 'number' },
                        height: 'xlarge',
                        width: 'xsmall',
                        placeholder: '',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'streetType',
                      label: 'Type de voie',
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'xsmall',
                        placeholder: 'Avenue',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'streetName',
                      label: 'Nom de la voie',
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'Montaigne',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'specialPlace',
                      label: 'Dénomination spéciale',
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'La Tour Eiffel',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'postOfficeBoxNumber',
                      label: 'Boîte postale',
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: '',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'postalCode',
                      label: 'Code postal',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        type: { name: 'number' },
                        height: 'xlarge',
                        width: 'small',
                        placeholder: '75008',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'addressLocality',
                      label: 'Ville',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: '8ème arrondissement',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'addressSubRegion',
                      label: 'Département',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'Paris',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'addressCountry',
                      label: 'Pays ISO 3166-1 alpha-2',
                      rules: [{ required: true, max: 2, min: 2 }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'FR',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Space
                  config={{ count: spaceBeetwenTitle, way: 'vertical' }}
                ></Space>
                <Write
                  data={{ item: 'Coordonnées du parking' }}
                  config={{
                    mode: 'title-small',
                  }}
                ></Write>

                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'latitude',
                      label: 'Latitude',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        type: { name: 'number' },
                        height: 'xlarge',
                        width: 'full',
                        placeholder: '9,3732261657',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'longitude',
                      label: 'Longitude',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        type: { name: 'number' },
                        height: 'xlarge',
                        width: 'full',
                        placeholder: '47,423557938',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Space
                  config={{ count: spaceBeetwenTitle, way: 'vertical' }}
                ></Space>
                <Write
                  data={{ item: 'Caractéristique du parking' }}
                  config={{
                    mode: 'title-small',
                  }}
                ></Write>

                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'capacity',
                      label: 'Capacité',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        type: { name: 'number' },
                        height: 'xlarge',
                        width: 'full',
                        placeholder: '180',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'occupancy',
                      label: 'Occupation',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        type: { name: 'number' },
                        height: 'xlarge',
                        width: 'full',
                        placeholder: '54',
                      }}
                    />
                  </Form.Item>
                </Row>
              </Form.Structure>
            </Form>
          )} */}
        </Zone.Area>
      </Zone>
    </>
  );
};
