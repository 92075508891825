import { gql } from '@apollo/client';
import { collectsEntity } from '../entity/collects.gql';
import { infosEntity } from '../entity/infos.gql';
import { safeInfosEntity } from '../entity/safeInfos.gql';

export const siteFinanceGql = gql`
  query site($siteId: String!, $collectsPage: Int, $collectsLimit: Int, $startDate:String, $stopDate: String) {
    site(siteId: $siteId) {
      _id
      ${infosEntity}
      ${collectsEntity}
      ${safeInfosEntity}
    }
  }
`;
