import { gql } from '@apollo/client';

export const bucketGql = gql`
  query bucket($bucketId: String!) {
    bucket(bucketId: $bucketId) {
      _id
      terminalId
      bucketId
      fragments
      processed
    }
  }
`;
