import { FilterExpanded, requestGQL } from '@gimlite/watermelon';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { parkingsGql } from '../gql/parkings.gql';
import { parkingsRender } from '../projection/parkings.render';

export const ParkingsSearch = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const [search, setSearch] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { limit: 15, ...getParamsUrl },
          gql: parkingsGql,
          render: (res) => parkingsRender(res),
        });

        setSearch(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl]);

  return (
    <Page>
      <Widget config={{ title: 'Liste des parkings' }}>
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded data={{ value: getParamsUrl }}>
              <FilterExpanded.Fields></FilterExpanded.Fields>
              <FilterExpanded.Actions></FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  console.log({ value });
                },
              }}
              data={search}
              config={{
                columns: [
                  {
                    title: 'Nom',
                    key: 'name',
                  },
                  {
                    title: 'Code',
                    key: 'code',
                  },
                  {
                    title: 'Coordonnée X',
                    key: 'coordX',
                  },
                  {
                    title: 'Coordonnée Y',
                    key: 'coordY',
                  },
                ],
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
