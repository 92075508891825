import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import axios from 'axios';
import { assign, createMachine, interpret } from 'xstate';
import config from '../../config';
import { createOperatorGql } from './gql/createOperator.gql';

const initialContext = {
  error: undefined,
  name: undefined,
};

const dataToUrl = async ([fileName, dataUrl]) => {
  const base64 = await fetch(dataUrl);
  const blob = await base64.blob();

  const form = new FormData();
  form.append('file', blob, fileName);

  const { logoUrl, logoId } = await axios
    .post(`${config.BFF_HOST}/operators/logo`, form)
    .then((response) => {
      return response.data;
    });

  return { logoUrl, logoId };
};

const machine = createMachine(
  {
    predictableActionArguments: true,
    id: 'createOperator',
    initial: 'off',
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: { target: 'idle' },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
          },
          CREATE_OPERATOR: {
            target: 'createOperator',
          },
        },
      },
      createOperator: {
        invoke: {
          id: 'createOperator',
          src: 'createOperator',
          onDone: {
            target: 'idle',
            actions: assign({
              result: true,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({
              result: false,
            }),
          },
        },
      },
      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },
  },
  {
    services: {
      createOperator: async (context, { payload }) => {
        // Upload logos if sent.
        const { display, email, phone } = payload;

        const displayBucket = {};

        if (display.logoMain && display.logoMain.length) {
          const { logoUrl, logoId } = await dataToUrl(display.logoMain[0]);

          display.logoMain = logoUrl;
          displayBucket.logoMain = logoId;
        } else if (display.logoMain) {
          delete display.logoMain;
        }

        if (display.logoSecondary && display.logoSecondary.length) {
          const { logoUrl, logoId } = await dataToUrl(display.logoSecondary[0]);

          display.logoSecondary = logoUrl;
          displayBucket.logoSecondary = logoId;
        } else if (display.logoSecondary) {
          delete display.logoSecondary;
        }

        return requestGQL({
          params: {
            input: {
              ...payload,
              display,
              displayBucket,
              // Remove nullable fields.
              email: email ? email : undefined,
              phone: phone ? phone : undefined,
            },
          },
          gql: createOperatorGql,
          render: (res) => res,
        });
      },
    },
  },
);

export const createOperatorService = interpret(machine).start();
