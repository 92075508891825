import { Link } from '@gimlite/router';
import { DateTime } from 'luxon';

export const bucketsRender = ({ list, paging }) => {
  return {
    list: list.map(({ bucketId, processed, ...rest }) => ({
      ...rest,
      bucketId: (
        <Link to={`/adm/fragments?fragmentRef=${bucketId}`}>{bucketId}</Link>
      ),
      processed: new Date(processed).toLocaleString(DateTime.DATE_SHORT),
    })),
    paging,
  };
};
