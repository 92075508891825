import { gql } from '@apollo/client';

export type UpdateCameraResponse = {
  camera: {
    _id: string;
  };
};

export type UpdateCameraParams = {
  input: {
    cameraId: string;
    ups: string | null;
    code: string | null;
    position: string | null;
    description: string | null;
  };
};

export const updateCameraGql = gql`
  mutation updateCamera($input: UpdateCameraDto!) {
    updateCamera(input: $input) {
      camera {
        _id
      }
    }
  }
`;
