import { requestGQL } from '@gimlite/watermelon';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { readRender } from '../../projection/clients.render';
import { clientGql } from '../../gql/client.gql';

export const ClientsDetailsGlobal = () => {
  const { clientId } = useParams();
  const { getParamsUrl } = useMyUrl();
  const [read, setRead] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { ...getParamsUrl, clientId },
          gql: clientGql,
          render: (res) => readRender(res),
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, clientId]);

  return (
    <Zone
      config={{
        zones: [['description']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'description' }}>
        <Widget config={{ title: 'Global' }}>
          <Description
            data={[
              {
                key: 'slug',
                label: 'Slug',
                value: read?.slug,
              },
            ]}
          ></Description>
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
