import { Link } from '@gimlite/router';
import { DateTime } from 'luxon';

export const searchRender = ({ list, paging }) => {
  return {
    list: list.map(
      ({
        amount,
        currency,
        contractTag,
        psp,
        pspTransactionDate,
        reference,
        ...rest
      }) => {
        let amountFormatted = undefined;

        try {
          amountFormatted = new Intl.NumberFormat(currency, {
            style: 'currency',
            currency: currency,
          }).format(amount / 100);
        } catch {}

        return {
          ...rest,
          referenceLink: (
            <Link to={`/payments/transactions?reference=${reference}`}>
              {reference}
            </Link>
          ),
          contract: `${contractTag} (${psp})`,
          pspDate: DateTime.fromISO(pspTransactionDate).toRelative(),
          amount: amountFormatted,
        };
      },
    ),
    paging,
  };
};
