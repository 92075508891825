import { Button, requestGQL, useMyUrl, Zone } from '@gimlite/watermelon';
import { Code } from '@gimlite/watermelon/components/code/code.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { siteConfigGql } from '../../gql/tab/config.gql';
import { readConfigurationRender } from '../../projection/config.render';
import { siteDetailsDefaultParams } from '../sites.details';

export const SitesDetailsConfig = () => {
  const [read, setRead] = useState<any>();
  const { getParamsUrl } = useMyUrl();
  const { siteId } = useParams();
  const [editConfigValueSave, setEditConfigValueSave] = useState('');
  const [disabledButtonConfigSubmit, setDisabledButtonConfigSubmit] =
    useState(true);

  const UPDATE_SITE_CONFIG = useCallback(async (params: unknown) => {
    try {
      const response = await requestGQL({
        params: { ...siteDetailsDefaultParams, ...getParamsUrl, siteId },
        gql: siteConfigGql,
        render: (res) => readConfigurationRender(res),
      });

      setRead(() => response);
    } catch (err) {
      console.log({ err });
    } finally {
      console.log('loading finish');
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { ...siteDetailsDefaultParams, ...getParamsUrl, siteId },
          gql: siteConfigGql,
          render: (res) => readConfigurationRender(res),
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, siteDetailsDefaultParams, siteId]);

  return (
    <Zone
      config={{
        zones: [['code'], ['action']],
        rows: ['1fr', 'min-content'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'code' }}>
        <Widget>
          <Code
            handleEvent={{
              input: (value: any) => {
                setEditConfigValueSave(() => value);
              },
              isValid: (value: any) => {
                setDisabledButtonConfigSubmit(() => !value);
              },
            }}
            data={{ defaultValue: read?.computedConfig ?? undefined }}
            config={{ yMax: '70vh', lang: 'json' }}
          />
        </Widget>
      </Zone.Area>
      <Zone.Area config={{ area: 'action' }}>
        <Widget>
          <Button
            handleEvent={{
              click: (value: any) => {
                UPDATE_SITE_CONFIG({
                  siteId,
                  config: JSON.parse(editConfigValueSave),
                });
              },
            }}
            config={{
              text: 'Modifier',
              disabled: disabledButtonConfigSubmit,
            }}
          />
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
