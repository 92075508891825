import { Tabs as TabsAnt } from 'antd';
import React from 'react';
import './tab.component.scss';

export declare namespace TabType {
  type Props = {
    className?: string;
    handleEvent?: {
      option?: (value: string) => void;
    };
    data: {
      items: TabType.Data.Item[];
      selected?: string;
    };
  };

  namespace Data {
    type Item = {
      key: string;
      label: string;
      children?: React.ReactNode;
    };
  }
}

export const Tab = ({
  handleEvent: { option } = {},
  data: { items = [], selected },
  className = '',
}: TabType.Props) => {
  return (
    <TabsAnt
      className={`
        tab
        ${className}
      `}
      defaultActiveKey={selected}
      items={items}
      onChange={option}
    />
  );
};
