import { gql } from '@apollo/client';

export const createOperatorGql = gql`
  mutation createOperator($input: CreateOperatorDto!) {
    createOperator(input: $input) {
      operator {
        _id
      }
    }
  }
`;
