import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Upload } from '@gimlite/watermelon/components/upload/upload.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import {
  isValidCode,
  isValidEmail,
  isValidOperatorCode,
  isValidPhoneNumber,
} from '@gimlite/watermelon/functions/validator.function';
import { CreateOperatorWizardContext } from '../createOperator.wizard';

export const OperatorStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreateOperatorWizardContext>) => {
  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure>
        <Form.Item
          data={{
            defaultValue: context.name ?? '',
          }}
          config={{
            name: 'name',
            label: 'Nom',
            rules: [{ required: true }],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.code ?? '',
          }}
          config={{
            name: 'code',
            label: 'Code',
            rules: [{ required: true }, { validator: isValidCode }],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.operatorCode ?? '',
          }}
          config={{
            name: 'operatorCode',
            label: 'Operator code',
            rules: [{ required: true }, { validator: isValidOperatorCode }],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.email ?? '',
          }}
          config={{
            name: 'email',
            label: 'Email',
            rules: [{ validator: isValidEmail }],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.phone ?? '',
          }}
          config={{
            name: 'phone',
            label: 'Phone',
            rules: [{ validator: isValidPhoneNumber }],
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.logoMain ?? [],
          }}
          config={{
            name: 'logoMain',
            label: 'Logo principal',
            rules: [],
          }}
        >
          <Upload />
        </Form.Item>
        <Form.Item
          data={{
            defaultValue: context.logoSecondary ?? [],
          }}
          config={{
            name: 'logoSecondary',
            label: 'Logo secondaire',
            rules: [],
          }}
        >
          <Upload />
        </Form.Item>
      </Form.Structure>
    </Form>
  );
};
