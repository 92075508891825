import { wizardService } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useSelector } from '@xstate/react';
import { createPmsTechnicalUsersService } from './createPmsTechnicalUser.machine';
import { CredentialsStep } from './steps/credentials.step';
import { DetailsStep } from './steps/details.step';

export const timeoutOnSearch = 200;

export type CreatePmsTechnicalUserWizardContext = any;

export const CreatePmsTechnicalUserWizard = () => {
  const result = useSelector(
    createPmsTechnicalUsersService,
    ({ context }: any) => context.result,
  );

  const error = useSelector(
    createPmsTechnicalUsersService,
    ({ context }: any) => context.error,
  );

  return (
    <Wizard<CreatePmsTechnicalUserWizardContext>
      config={{
        title: '',
        withValidation: {
          state: result,
          success: 'Ça marche nickel !',
          error: error || 'Ça marche pas !',
        },
      }}
      data={{
        defaultContext: undefined,
      }}
      handleEvent={{
        done: async (value: any) => {
          createPmsTechnicalUsersService.send('CREATE_PMS_TECHNICAL_USER', {
            payload: value,
          });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item
        config={{ title: 'Credentials', component: CredentialsStep }}
      />
      <Wizard.Item config={{ title: 'Details', component: DetailsStep }} />
    </Wizard>
  );
};
