import { requestGQL, Space, useMyUrl } from '@gimlite/watermelon';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Statistic } from '@gimlite/watermelon/components/statistic/statistic.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AveragePowerChart } from '../../../../charts/averagePower.chart';
import { BalanceAveragePowerChart } from '../../../../charts/balanceAveragePower.chart';
import { PeripheralTimeChart } from '../../../../charts/peripheralTime.chart';
import { TensionChart } from '../../../../charts/tension.chart';
import { WeatherChart } from '../../../../charts/weather.chart';
import { siteEnergieGql } from '../../gql/tab/energie.gql';
import { readEnergieRender } from '../../projection/energie.render';
import { siteDetailsDefaultParams, SitesDetailsFilter } from '../sites.details';

export const SitesDetailsEnergy = () => {
  const [read, setRead] = useState<any>();
  const { getParamsUrl } = useMyUrl();
  const { siteId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { ...siteDetailsDefaultParams, ...getParamsUrl, siteId },
          gql: siteEnergieGql,
          render: (res) => readEnergieRender(res),
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, siteDetailsDefaultParams, siteId]);

  return (
    <Col config={{ scrollY: true }}>
      <SitesDetailsFilter />
      <Space config={{ count: 2 }} />

      <Row>
        <Widget config={{ title: 'Charge' }}>
          <Statistic
            config={{
              precision: 2,
              way: true,
              suffix: 'mW',
              explain: 'Puissance moyenne sur la période sélectionnée',
            }}
            data={{ value: read?.stats?.prod }}
          />
        </Widget>
        <Space config={{ count: 2, way: 'horizontal' }} />
        <Widget config={{ title: 'Consommation' }}>
          <Statistic
            config={{
              precision: 2,
              way: true,
              suffix: 'mW',
              explain: 'Puissance moyenne sur la période sélectionnée',
            }}
            data={{ value: read?.stats?.conso }}
          />
        </Widget>
        <Space config={{ count: 2, way: 'horizontal' }} />

        <Widget config={{ title: 'Balance' }}>
          <Statistic
            config={{
              precision: 2,
              way: true,
              suffix: 'mW',
              explain: 'Puissance moyenne sur la période sélectionnée',
            }}
            data={{ value: read?.stats?.balance }}
          />
        </Widget>
      </Row>
      <Space config={{ count: 2 }} />

      <Widget config={{ title: 'Météo' }}>
        <WeatherChart
          config={{
            startDate: getParamsUrl?.startDate,
            endDate: getParamsUrl?.stopDate,
          }}
          data={read?.meteo}
        ></WeatherChart>
      </Widget>

      <Space config={{ count: 2 }} />

      <Widget config={{ title: 'Bilan de consommation (puissance moyenne)' }}>
        <BalanceAveragePowerChart
          config={{
            startDate: getParamsUrl?.startDate,
            endDate: getParamsUrl?.stopDate,
          }}
          data={read?.tMachine}
        ></BalanceAveragePowerChart>
      </Widget>

      <Space config={{ count: 2 }} />
      <Widget config={{ title: 'Tension' }}>
        <TensionChart
          config={{
            startDate: getParamsUrl?.startDate,
            endDate: getParamsUrl?.stopDate,
          }}
          data={read?.tensions}
        />
      </Widget>

      <Space config={{ count: 2 }} />

      <Widget config={{ title: 'Puissance moyenne' }}>
        <AveragePowerChart
          config={{
            startDate: getParamsUrl?.startDate,
            endDate: getParamsUrl?.stopDate,
          }}
          data={read?.puissance}
        ></AveragePowerChart>
      </Widget>

      <Space config={{ count: 2 }} />

      <Widget config={{ title: 'Temps périphériques' }}>
        <PeripheralTimeChart
          config={{
            startDate: getParamsUrl?.startDate,
            endDate: getParamsUrl?.stopDate,
          }}
          data={read?.tPeriph}
        />
      </Widget>
    </Col>
  );
};
