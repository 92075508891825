import { gql } from '@apollo/client';

export type UpsEntity = {
  _id: string;
  code: string;
  center: [number, number] | null;
  translation: Array<{
    lang: string;
    name: string;
    description: string | null;
  }>;
  address: {
    streetNumber: string | null;
    streetNumberBis: string | null;
    streetType: string | null;
    streetName: string | null;
    specialPlace: string | null;
    postalCode: string | null;
    addressLocality: string | null;
    addressSubRegion: string | null;
    addressCountry: string | null;
    postOfficeBoxNumber: string | null;
  } | null;
  config: {
    fps: {
      maximumDuration: number | null;
      maximumAmount: number | null;
      system: string | null;
      notificationAuthority: string;
      reducedPriceDuration: number | null;
      finePrice: number | null;
      reducedFinePrice: number | null;
      recourseOrganization: {
        address: {
          streetNumber: string | null;
          streetNumberBis: string | null;
          streetType: string | null;
          streetName: string | null;
          specialPlace: string | null;
          postalCode: string | null;
          addressLocality: string | null;
          addressSubRegion: string | null;
          addressCountry: string | null;
          postOfficeBoxNumber: string | null;
        } | null;
        name: string | null;
        organizationId: string | null;
        url: string | null;
      } | null;
    };
    prestoScan: {
      entryTolerancePeriod: number | null;
      exitTolerancePeriod: number | null;
      isVerbalizationAllowed: boolean | null;
      showOccupancy: boolean | null;
      deletionDelay: {
        incomplete: number | null;
        complete: number | null;
        toCheck: number | null;
        unfined: number | null;
        fined: number | null;
      };
      outrageTreshold: {
        danger: number | null;
        warning: number | null;
        valid: number | null;
      };
    };
  };
};

export type UpsListResponse = {
  list: Array<UpsEntity>;
  paging: {
    current: number;
    count: number;
    limit: number;
  };
};

export type upsListParams = {
  page: number | null;
  limit: number | null;
  order: string | null;
  codePattern: string | null;
};

export const upsListGql = gql`
  query upsList($page: Int, $limit: Int, $order: String, $codePattern: String) {
    upsList(
      page: $page
      limit: $limit
      order: $order
      codePattern: $codePattern
    ) {
      list {
        _id
        code
        center
        translation {
          lang
          name
          description
        }
        address {
          streetNumber
          streetNumberBis
          streetType
          streetName
          specialPlace
          postalCode
          addressLocality
          addressSubRegion
          addressCountry
          postOfficeBoxNumber
        }
        config {
          fps {
            maximumDuration
            maximumAmount
            system
            notificationAuthority
            reducedPriceDuration
            finePrice
            reducedFinePrice
            recourseOrganization {
              address {
                streetNumber
                streetNumberBis
                streetType
                streetName
                specialPlace
                postalCode
                addressLocality
                addressSubRegion
                addressCountry
                postOfficeBoxNumber
              }
              name
              organizationId
              url
            }
          }
          prestoScan {
            entryTolerancePeriod
            exitTolerancePeriod
            isVerbalizationAllowed
            showOccupancy
            deletionDelay {
              incomplete
              complete
              toCheck
              unfined
              fined
            }
            outrageTreshold {
              danger
              warning
              valid
            }
          }
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
