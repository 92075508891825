import { requestGQL, wizardService } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useSelector } from '@xstate/react';
import { useState } from 'react';
import {
  UpdateControlUsersParams,
  UpdateControlUsersResponse,
  updateControlUsersGql,
} from './gql/updateControlUser.gql';
import { UpsEntity } from './gql/upsList.gql';
import { CredentialsStep } from './steps/credentials.step';
import { DetailsStep } from './steps/details.step';
import {
  UpdateControlUserServiceContextType,
  updateControlUserService,
} from './updateControlUser.machine';

export type UpdateControlUserWizardContext = {
  credentials: {
    username: string | null;
    password: string | null;
  };
  user: {
    _id: string | null;
    firstName: string | null;
    lastName: string | null;
    fpsAgentId: number | null;
    fpsAgentName: string | null;
    ups: string[];
    lang: string;
  };
  listParking: Array<{
    code: UpsEntity['code'];
    _id: UpsEntity['_id'];
  }>;
  selectParking: {
    _id: string | null;
  };
};

export const UpdateControlUserWizard = () => {
  const [result, setResult] = useState<boolean>();

  const { credentials, user } = useSelector(
    updateControlUserService,
    ({ context }: { context: UpdateControlUserServiceContextType }) => context,
  );

  return (
    <Wizard<UpdateControlUserWizardContext>
      config={{
        title: 'Modifier un agent',
        withValidation: {
          state: result,
          success: 'Ca marche nickel !',
          error: 'Ca marche pas !',
        },
      }}
      data={{
        defaultContext: {
          credentials: {
            username: credentials.username,
            password: credentials.password,
          },
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            fpsAgentId: user.fpsAgentId,
            fpsAgentName: user.fpsAgentName,
            lang: user.lang,
            ups: user.ups,
            _id: user._id,
          },
          listParking: [],
          selectParking: {
            _id: null,
          },
        },
      }}
      handleEvent={{
        done: async ({ user, selectParking, credentials, listParking }) => {
          const input: UpdateControlUsersParams['input'] = {
            firstName: user.firstName,
            fpsAgentId: `${user.fpsAgentId}`,
            fpsAgentName: user.fpsAgentName,
            lang: user.lang,
            lastName: user.lastName,
            username: credentials.username!,
            ups: user.ups!,
            userId: user._id!,
          };

          if (!!credentials.password) input.password = credentials.password;

          try {
            (await requestGQL({
              params: {
                input,
              } as UpdateControlUsersParams,
              gql: updateControlUsersGql,
            })) as UpdateControlUsersResponse;

            setResult(() => true);
          } catch (error) {
            setResult(() => false);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item
        config={{ title: 'Credentials', component: CredentialsStep }}
      />
      <Wizard.Item config={{ title: 'Details', component: DetailsStep }} />
    </Wizard>
  );
};
