import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import { assign, createMachine, interpret } from 'xstate';
import loginGql from './gql/login.gql';

const initialContext = {
  user: {},
  error: undefined,
  token: undefined,
};

const machine = createMachine(
  {
    id: 'login',
    initial: 'idle',
    predictableActionArguments: true,
    context: initialContext,
    states: {
      idle: {
        on: {
          UPDATE: { actions: 'update' },
          SUBMIT: { target: 'authenticate' },
        },
      },
      authenticate: {
        invoke: {
          src: 'authenticate',
          onDone: {
            actions: ['success', 'emitUser', 'reset'],
            target: 'idle',
          },
          onError: {
            actions: ['declareError'],
            target: 'idle',
          },
        },
      },
    },
    on: {
      LOGOUT: { actions: assign(initialContext) },
    },
  },
  {
    actions: {
      update: assign({
        error: () => undefined,
        user: ({ user }, { type, ...rest }) => ({ ...user, ...rest }),
      }),
      success: assign({
        token: (_, { data }) => data.token,
      }),
      reset: assign({ user: {} }),
      token: ({ token }) => token,
      declareError: assign({ error: () => true }),
    },
    services: {
      authenticate: async ({ user: { username, password } }) =>
        requestGQL({
          params: { username, password },
          gql: loginGql,
        }),
    },
  },
);

export const loginService = interpret(machine).start();
