import { gql } from '@apollo/client';

export const controlUserGql = gql`
  query controlUser($userId: String!) {
    controlUser(userId: $userId) {
      _id
      username
      firstName
      lastName
      fpsAgentId
      fpsAgentName
      lang
      upsList {
        list {
          _id
          code
        }
        paging {
          current
          count
          limit
        }
      }
      ups
      state
    }
  }
`;
