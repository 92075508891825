import { notifService, requestGQL } from '@gimlite/watermelon';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Select } from '@gimlite/watermelon/components/select/select.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTimeout } from '@gimlite/watermelon/hook/useTimeout.hook';
import { useCallback } from 'react';
import { UpsResponse, upsGql, upsParams } from '../gql/ups.gql';
import { UpsListResponse, upsListGql, upsListParams } from '../gql/upsList.gql';
import { UpdateControlUserWizardContext } from '../updateControlUser.wizard';

export const DetailsStep = ({
  context: { user, listParking },
  validate,
  submit,
}: WizardItemType.Config.Component<UpdateControlUserWizardContext>) => {
  const { timeout } = useTimeout();

  const searchParkingsFn = useCallback(
    async ({ codePattern }: { codePattern: string }) => {
      try {
        const response = (await requestGQL({
          params: { codePattern } as upsListParams,
          gql: upsListGql,
        })) as UpsListResponse;

        submit({ listParking: response.list });
      } catch (error) {
        notifService.send('NOTIF', {
          content: error,
          mode: 'error',
        });
        submit({ listParking: [] });
      }
    },
    [],
  );

  const readParkingFn = useCallback(async ({ upsId }: { upsId: string }) => {
    try {
      const { _id } = (await requestGQL({
        params: { upsId } as upsParams,
        gql: upsGql,
      })) as UpsResponse;

      submit({
        selectParking: {
          _id,
        },
      });
    } catch (error) {
      notifService.send('NOTIF', {
        content: 'SERVER_PROBLEM',
        mode: 'error',
      });

      submit({
        selectParking: {
          _id: null,
        },
      });
    }
  }, []);

  return (
    <Form
      data={{ value: user }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit({ user: { ...value, _id: user._id } });
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure>
        <Form.Item
          config={{
            way: 'vertical',
            name: 'firstName',
            label: 'Prénom',
            rules: [{ required: true }],
          }}
        >
          <Input config={{ placeholder: 'Jhon' }} />
        </Form.Item>

        <Form.Item
          config={{
            way: 'vertical',
            name: 'lastName',
            label: 'Nom de famille',
            rules: [{ required: true }],
          }}
        >
          <Input config={{ placeholder: 'Doe' }} />
        </Form.Item>

        <Form.Item
          config={{
            way: 'vertical',
            name: 'fpsAgentId',
            label: 'fpsAgentId',
            rules: [
              {
                required: true,
                pattern: /^\d{3}$/,
              },
            ],
          }}
        >
          <Input
            config={{ placeholder: '245', type: { name: 'number', min: 100 } }}
          />
        </Form.Item>

        <Form.Item
          config={{
            way: 'vertical',
            name: 'fpsAgentName',
            label: 'fpsAgentName',
            rules: [{ required: true }],
          }}
        >
          <Input config={{ placeholder: 'Mr smith' }} />
        </Form.Item>

        {/* <Form.Item
          config={{
            way: 'vertical',
            name: 'upsId',
            label: 'UPS',
            rules: [{ required: true }],
          }}
        > */}
        <></>
        {/* <AutoComplete
            handleEvent={{
              input: (value) => {
                searchParkingsFn({
                  codePattern: value,
                });
              },
              select(item) {
                if (item?.value) {
                  readParkingFn({ upsId: item.value });
                }
              },
            }}
            data={{
              items: listParking.map(({ code, _id }) => ({
                value: _id,
                label: code,
              })),
            }}
            config={{
              search: {
                caseSensitive: false,
                accentSensitive: false,
                pattern: 'start',
              },

              placeholder: 'plan-les-ouates',
            }}
          /> */}
        {/* </Form.Item> */}

        <Form.Item
          config={{
            way: 'vertical',
            name: 'lang',
            label: 'Langue',
            rules: [{ required: true }],
          }}
        >
          <Select
            data={{
              items: [
                { label: 'FR', value: 'fr_FR' },
                { label: 'EN', value: 'en_EN' },
                { label: 'DE', value: 'de_DE' },
                { label: 'IT', value: 'it_IT' },
              ],
            }}
          />
        </Form.Item>
      </Form.Structure>
    </Form>
  );
};
