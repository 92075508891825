import { gql } from '@apollo/client';

export const modemSessionsEntity = `
    modemSessions(page: $modemSessionsPage, limit: $modemSessionsLimit, startDate: $startDate, stopDate: $stopDate) {
    list {
        _id
        logDate
        net {
        rat
        opn
        oper
        band
        chan
        rssi
        rsrq
        rsrp
        iccid
        reg
        }
        t {
        conn
        trx
        susp
        }
    }
    paging {
        current
        count
        limit
    }
}`;

export const modemSessionsPaging = gql`
  query site($siteId: String!, $modemSessionsPage: Int, $modemSessionsLimit: Int, $startDate: String, $stopDate: String) {
    site(siteId: $siteId) {
      ${modemSessionsEntity}
    }
  }
`;
