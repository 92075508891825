import { gql } from '@apollo/client';

export const cameraGql = gql`
  query camera($cameraId: String!) {
    camera(cameraId: $cameraId) {
      _id
      code
      position
      ups
      upsCode
      description
    }
  }
`;
