import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { isValidEmail } from '@gimlite/watermelon/functions/validator.function';
import { CreatePmsTechnicalUserWizardContext } from '../createPmsTechnicalUser.wizard';

export const CredentialsStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreatePmsTechnicalUserWizardContext>) => {
  return (
    <>
      <Zone
        config={{
          gap: {
            y: 2,
          },
          zones: [['form']],
          rows: ['1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'form' }}>
          <Form
            data={{ value: context }}
            config={{ validateOnChange: true }}
            handleEvent={{
              change: (value: any) => {
                submit(value);
              },
              validate: (value: any) => {
                validate(value);
              },
            }}
          >
            <Form.Structure
              config={{ layout: 'column', width: 'initial', fill: true }}
            >
              <Form.Item
                config={{
                  way: 'vertical',
                  name: 'username',
                  label: 'username',
                  rules: [{ required: true }, { validator: isValidEmail }],
                }}
              >
                <Input config={{ placeholder: 'technical@user.ch' }} />
              </Form.Item>
              <Form.Item
                config={{
                  way: 'vertical',
                  name: 'password',
                  label: 'password',
                  rules: [
                    { required: true },
                    {
                      validator: (rules, value) =>
                        new Promise((resolve, reject) => {
                          8 <= value.length
                            ? resolve(true)
                            : reject('Au moins 8 caractères');
                        }),
                    },
                  ],
                }}
              >
                <Input
                  config={{
                    placeholder: 'password',
                    type: { name: 'password' },
                  }}
                />
              </Form.Item>
            </Form.Structure>
          </Form>
        </Zone.Area>
      </Zone>
    </>
  );
};
