import { gql } from '@apollo/client';

export const translationsGql = gql`
  query translations($page: Int, $limit: Int, $order: String) {
    translations(page: $page, limit: $limit, order: $order) {
      list {
        _id
        key
        languages
        documents {
          lang
          key
          translations
          createdAt
        }
        createdAt
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
