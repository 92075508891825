import {
  Button,
  FilterExpanded,
  Popover,
  Row,
  languages,
  notifService,
  requestGQL,
  useTranslation,
  wizardService,
} from '@gimlite/watermelon';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { controlUserGql } from '../gql/control-user.gql';
import {
  UpdateControlUsersParams,
  UpdateControlUsersResponse,
  updateControlUsersGql,
} from '../gql/updateControlUser.gql';

export const ControlUsersDetails = () => {
  const { userId } = useParams();
  const { getParamsUrl } = useMyUrl();
  const { t, lang } = useTranslation();
  const [read, setRead] = useState<any>();
  const [refresh, setRefresh] = useState<string>();

  const removeUPSInControlUserFn = useCallback(
    async (id: string, upsId: string, ups: string[]) => {
      try {
        (await requestGQL({
          params: {
            input: {
              userId: id,
              ups: ups.filter((value) => value !== upsId),
            },
          } as UpdateControlUsersParams,
          gql: updateControlUsersGql,
        })) as UpdateControlUsersResponse;

        notifService.send('NOTIF', {
          mode: 'success',
          content: t('watermelon-success'),
        });

        setRefresh(() => uuidv4());
      } catch {
        notifService.send('NOTIF', {
          mode: 'error',
          content: t('watermelon-SERVER_PROBLEM'),
        });
      }
    },
    [lang, getParamsUrl],
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { userId },
          gql: controlUserGql,
          render: (res) => res,
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [userId, getParamsUrl, refresh]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['details'], ['table']],
          rows: ['min-content', '1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'details' }}>
          <Widget config={{ title: 'Details' }}>
            <Description
              data={[
                {
                  key: 'email',
                  label: 'Adresse mail',
                  value: read?.username,
                },
                {
                  key: 'firstName',
                  label: 'Prénom',
                  value: read?.firstName,
                },
                {
                  key: 'lastName',
                  label: 'Nom de famille',
                  value: read?.lastName,
                },
                {
                  key: 'fpsAgentId',
                  label: 'fpsAgentId',
                  value: read?.fpsAgentId,
                },
                {
                  key: 'fpsAgentName',
                  label: 'fpsAgentName',
                  value: read?.fpsAgentName,
                },
                {
                  key: 'lang',
                  label: 'Langue',
                  value: read?.lang
                    ? languages.fr_FR.find(({ value }) => value === read?.lang)
                        ?.label
                    : undefined,
                },
              ]}
            ></Description>
          </Widget>
        </Zone.Area>
        <Zone.Area config={{ area: 'table' }}>
          <Widget config={{ title: 'Ups list' }}>
            <Zone
              config={{
                zones: [['filter'], ['list']],
                rows: ['min-content', '1fr'],
                columns: ['1fr'],
              }}
            >
              <Zone.Area config={{ area: 'filter' }}>
                <FilterExpanded>
                  <FilterExpanded.Fields></FilterExpanded.Fields>
                  <FilterExpanded.Actions>
                    <Button
                      config={{
                        text: 'Ajouter un UPS',
                        type: { value: 'button' },
                      }}
                      handleEvent={{
                        click: () => {
                          wizardService.send('SHOW_WIZARD', {
                            id: 'addParkingInControlUser',
                            store: {
                              user: {
                                _id: read?._id,
                                ups: read?.ups,
                              },
                            },
                          });
                        },
                      }}
                    />
                  </FilterExpanded.Actions>
                </FilterExpanded>
              </Zone.Area>

              <Zone.Area config={{ area: 'list' }}>
                <Table<any>
                  data={
                    read?.upsList
                      ? {
                          list: read?.upsList.list.map((element: any) => ({
                            ...element,
                            action: (
                              <Row>
                                <Popover.Confirm
                                  handleEvent={{
                                    confirm: () => {
                                      removeUPSInControlUserFn(
                                        userId!,
                                        element._id,
                                        read.ups,
                                      );
                                    },
                                  }}
                                  config={{
                                    title: t('watermelon-importantAction'),
                                    description: t(
                                      'watermelon-areYouSureAboutThis?',
                                    ),
                                  }}
                                >
                                  <Button
                                    config={{
                                      text: 'Supprimer',
                                      color: 'error',
                                      size: 'small',
                                    }}
                                  ></Button>
                                </Popover.Confirm>
                              </Row>
                            ),
                          })),
                          paging: read?.upsList.paging,
                        }
                      : undefined
                  }
                  config={{
                    columns: [
                      {
                        title: 'ID',
                        key: '_id',
                      },
                      {
                        title: 'Code',
                        key: 'code',
                      },
                      {
                        key: 'action',
                        title: 'Actions',
                      },
                    ],
                  }}
                />
              </Zone.Area>
            </Zone>
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
