import { gql } from '@apollo/client';

export const camerasGql = gql`
  query cameras($page: Int, $limit: Int, $order: String) {
    cameras(page: $page, limit: $limit, order: $order) {
      list {
        _id
        code
        position
        upsCode
        ups
        description
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
