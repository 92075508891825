import { requestGQL } from '@gimlite/watermelon';
import { Code } from '@gimlite/watermelon/components/code/code.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { parkingRightGql } from '../gql/parkingRight.gql';

export const ParkingRightsDetails = () => {
  const { parkingRightId } = useParams();
  const [ticket, setTicket] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { parkingRightId },
          gql: parkingRightGql,
          render: (res) => res,
        });

        setTicket(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [parkingRightId]);

  return (
    <Page>
      <Widget config={{ title: 'Details du ticket' }}>
        <Code
          data={{ defaultValue: JSON.stringify(ticket, null, 4) }}
          config={{ yMax: '80vh', lang: 'json', readOnly: true }}
        />
      </Widget>
    </Page>
  );
};
