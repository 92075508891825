import { EventObject, assign, createMachine, interpret } from 'xstate';
import { AddParkingInControlUserWizardContext } from './addParkingInControlUser.wizard';

export type AddParkingInControlUserContextType = {
  user: AddParkingInControlUserWizardContext['user'];
  error: string | null;
};

const initialContext: AddParkingInControlUserContextType = {
  user: {
    _id: null,
    ups: [],
  },
  error: null,
};

const machine = createMachine({
  predictableActionArguments: true,
  id: 'addParkingInControlUser',
  initial: 'off',
  context: initialContext,
  states: {
    off: {
      on: {
        WAKEUP: {
          target: 'idle',
          actions: assign({
            user: (
              _,
              {
                user,
              }: EventObject & {
                user: AddParkingInControlUserWizardContext['user'];
              },
            ) => user,
          }),
        },
      },
    },
    idle: {
      on: {
        KILL: {
          target: 'off',
        },
      },
    },

    failure: {
      on: {
        RETRY: {
          actions: assign({ error: undefined }),
          target: 'idle',
        },
      },
    },
  },
});

export const addParkingInControlUserService = interpret(machine).start();
