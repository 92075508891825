import { useNavigate } from '@gimlite/router';
import { requestGQL, wizardService } from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { upsGql } from '../gql/ups.gql';
import { readRender } from '../projection/ups.render';

export const UpsDetails = () => {
  const navigate = useNavigate();
  const [read, setRead] = useState<any>();
  const { upsId } = useParams();
  const { getParamsUrl, setParamsUrl } = useMyUrl();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL({
          params: { ...getParamsUrl, upsId },
          gql: upsGql,
          render: (res) => readRender(res),
        });

        setRead(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl, upsId]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['temp'], ['description'], ['sites']],
          rows: ['min-content', 'min-content', 'min-content'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'temp' }}>
          <Widget config={{ title: 'Actions (temporaire)' }}>
            <Button
              config={{ text: 'Add site', type: { value: 'button' } }}
              handleEvent={{
                click: () => {
                  wizardService.send('SHOW_WIZARD', {
                    id: 'createSite',
                    store: {
                      clientId: read.client._id,
                      upsId,
                    },
                  });
                },
              }}
            />
          </Widget>
        </Zone.Area>
        <Zone.Area config={{ area: 'description' }}>
          <Widget config={{ title: 'Details' }}>
            {read && <Description data={read?.details} />}
          </Widget>
        </Zone.Area>
        <Zone.Area config={{ area: 'sites' }}>
          <Widget config={{ title: 'Site list' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({
                    ...getParamsUrl,
                    sitesPage: value.page,
                    sitesLimit: value.limit,
                  });
                },
                read: (value: any) => {
                  navigate(`/onstreet/sites/${value._id}`);
                },
              }}
              data={read?.sites}
              config={{
                actions: { read: true },
                columns: [
                  {
                    title: 'Name',
                    key: 'name',
                  },
                ],
              }}
            />
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
