import ColorFn from 'color';
import { gsap } from 'gsap';
import React, { useCallback, useMemo, useRef } from 'react';
import { useLocation, useMatches } from 'react-router-dom';
import { getCSS } from '../../functions/css.function';
import { useMyUrl } from '../../hook/useMyUrl.hook';
import { useTranslation } from '../../hook/useTranslation.hook';
import { Placement2Axis } from '../../types/component.type';
import { Drawer } from '../drawer/drawer.component';
import { Icon } from '../icon/icon.component';
import { Menu, MenuType } from '../menu/menu.component';
import { Write } from '../write/write.component';
import './nav.component.scss';

class Static {
  static formatItem = ({
    parent,
    key,
    items,
    params,
  }: Pick<MenuType.Data.Item, 'key' | 'items' | 'params'> & {
    parent?: string;
  }): Array<
    Pick<MenuType.Data.Item, 'key' | 'params'> & {
      parent?: string;
    }
  > => {
    if (Array.isArray(items)) {
      return items.flatMap((item: MenuType.Data.Item) =>
        Static.formatItem({
          parent: parent || key,
          key: item.key,
          items: item?.items,
          params: item?.params,
        }),
      );
    } else {
      return [{ key, parent, params }];
    }
  };
}

export declare namespace NavDesktopType {
  type Props = {
    className?: string;
    handleEvent?: { navigation?: (value: string) => void };
    data?: NavDesktopType.Data[];
    config?: {
      logo?: NavDesktopType.Config.Logo;
      mode?: NavDesktopType.Config.Mode;
    };
  };

  namespace Config {
    type Logo = React.ReactNode | React.ReactNode[];
    type Mode = 'panel' | 'drop';
  }

  type Data = {
    key: string;
    position: Extract<Placement2Axis, 'top' | 'bottom'>;
    title: string;
    items: MenuType.Data.Item[];
  };
}

export const NavDesktop = ({
  handleEvent: { navigation } = {},
  data = [],
  className = '',
  config: { mode = 'drop', logo } = {},
}: NavDesktopType.Props) => {
  const { pathname } = useLocation();
  const navRef = useRef(null);
  const menuRef = useRef(null);
  const toggleOpen = useRef(null);
  const toggleClose = useRef(null);
  const { buildParamsUrl } = useMyUrl();
  const navigationMatches = useMatches();

  const dataFormatted = useMemo(
    () =>
      mode === 'drop'
        ? data.reduce(
            (
              acc: NavDesktopType.Data[],
              { items, ...rest }: NavDesktopType.Data,
            ) => {
              const path: Record<string, any> = { ...rest, items };

              if (!items.length)
                path.items = items.map(({ key = null, ...rest }) => ({
                  ...rest,
                }));

              return [...acc, path as NavDesktopType.Data];
            },
            [],
          )
        : data,
    [data],
  );

  //! Liste des path utilisables
  const registerPathIndex: Record<
    string,
    Array<
      Pick<MenuType.Data.Item, 'key' | 'params'> & {
        parent?: string;
      }
    >
  > = useMemo(
    () =>
      dataFormatted
        .flatMap((item) => item.items)
        .flatMap((item: MenuType.Data.Item) =>
          Static.formatItem({
            key: item.key,
            items: item.items,
            params: item.params,
          }),
        )
        .reduce(
          (acc, { key, ...rest }) => ({ ...acc, [key]: { ...rest, key } }),
          {},
        ),
    [dataFormatted],
  );

  const selectedPath = useMemo((): Pick<
    MenuType.Data.Item,
    'key' | 'params'
  > & {
    parent?: string;
    deep: boolean;
  } => {
    const selectedSitemapItem: any = navigationMatches
      .reverse()
      .find(({ pathname: itemPath, handle }: any) => {
        return (
          pathname === itemPath ||
          (handle && handle?.navKey && handle.navKey === pathname)
        );
      });

    // get fist 2 element of url like /111/222/333 => /111/222
    const trimedPathname = selectedSitemapItem?.pathname
      ? selectedSitemapItem?.pathname.split('/').slice(0, 3).join('/')
      : '/';

    const keyToUse = selectedSitemapItem?.handle?.navKey || trimedPathname;

    const itemToUse: any = registerPathIndex?.[keyToUse];

    if (!itemToUse) {
      return { key: '/', parent: '/', deep: false };
    } else {
      return { ...itemToUse, deep: pathname === itemToUse.key };
    }
  }, [dataFormatted, pathname]);

  const topPath = useMemo(
    () => data.filter(({ position }) => position === 'top'),
    [dataFormatted],
  );

  const bottomPath = useMemo(
    () => data.filter(({ position }) => position === 'bottom'),
    [dataFormatted],
  );

  const handleNavigate = useCallback(
    (key: string) => {
      const itemToUse: any = registerPathIndex[key];
      const path =
        key + (itemToUse?.params ? '?' + buildParamsUrl(itemToUse.params) : '');
      navigation?.(path);
    },
    [dataFormatted],
  );

  const getPanelChildren = useCallback(() => {
    if (!selectedPath || mode !== 'panel' || !data.length) return <></>;

    const children = data.reduce((acc: any, { items }) => {
      const parent = items.find(({ key }) => key === selectedPath.parent);
      return parent ? parent.items : acc;
    }, []);

    return (
      <Menu
        handleEvent={{
          navigation: handleNavigate,
        }}
        data={{
          items: children,
          selected: {
            value: selectedPath.key,
            type: selectedPath.deep ? 'exact' : 'deep',
          },
        }}
        config={{ mode: 'vertical' }}
      />
    );
  }, [selectedPath]);

  const navDropAll = useCallback(
    (elements: NavDesktopType.Data[], mode: MenuType.Config.Mode) => {
      return (
        <>
          {elements.map(({ title, items }, index) => {
            const selectedValue = [selectedPath.key];
            // if (selectedPath?.parent) selectedValue.push(selectedPath.parent);

            return (
              <Menu
                key={index}
                config={{ mode: mode }}
                handleEvent={{ navigation: handleNavigate }}
                data={{
                  title,
                  items,
                  selected: { value: selectedValue },
                }}
              />
            );
          })}
        </>
      );
    },
    [selectedPath],
  );

  const navPanelParent = useCallback(
    (elements: NavDesktopType.Data[]) => {
      if (!selectedPath) return <></>;

      return (
        <>
          {elements.map(({ title, items }) => {
            return (
              <Menu
                handleEvent={{
                  navigation: handleNavigate,
                }}
                data={{
                  items,
                  selected: { value: selectedPath.parent },
                  title,
                }}
              />
            );
          })}
        </>
      );
    },
    [selectedPath],
  );

  const toggle = useCallback((action: boolean) => {
    if (mode === 'panel') {
      const timeline = gsap.timeline({
        defaults: {
          ease: 'power1.inOut',
        },
      });

      timeline.addLabel('menu');
      timeline.to(
        menuRef.current,
        {
          opacity: action ? 0 : 1,
          duration: 0.15,
        },
        'menu',
      );
      timeline.addLabel('nav', '<=0.1');
      timeline.to(
        navRef.current,
        {
          width: action ? '0px' : '200px',
          duration: 0.4,
        },
        'nav',
      );

      timeline.to(
        toggleOpen.current,
        {
          opacity: action ? 1 : 0,
          pointerEvents: action ? 'visible' : 'none',
          duration: 0.15,
        },
        'nav',
      );
    }
  }, []);

  return (
    <div className={`navdesktop navdesktop--${mode} ${className}`}>
      <nav className="navdesktop__parent">
        <div className="navdesktop__parent__logo">{logo}</div>
        <div className={`navdesktop__parent__menu`}>
          {mode === 'drop' && navDropAll(topPath, 'horizontal')}
          {mode === 'panel' && navPanelParent(topPath)}
        </div>
        <div className={`navdesktop__parent__more`}>
          {mode === 'drop' && navDropAll(bottomPath, 'horizontal')}
          {mode === 'panel' && navPanelParent(bottomPath)}
        </div>
      </nav>

      {mode === 'panel' && (
        <nav ref={navRef} className="navdesktop__child">
          <div className="navdesktop__child__contain">
            <div
              onClick={() => toggle(true)}
              ref={toggleClose}
              className="navdesktop__child__contain__toggle"
            >
              <Icon
                config={{
                  type: 'faAnglesLeftSolid',
                  color: new ColorFn(getCSS(`--color-primary`)).isLight()
                    ? 'black'
                    : 'white',
                }}
              ></Icon>
            </div>
            <div ref={menuRef} className="navdesktop__child__contain__menu">
              {getPanelChildren()}
            </div>
          </div>
          <div
            ref={toggleOpen}
            onClick={() => toggle(false)}
            className="navdesktop__child__open"
          >
            <div className="navdesktop__child__open__line"></div>
          </div>
        </nav>
      )}
    </div>
  );
};

export declare namespace NavMobileTopType {
  type Props = {
    className?: string;
    handleEvent?: { navigation?: (value: string) => void };
    data?: NavDesktopType.Data[];
    config?: {
      logo?: NavDesktopType.Config.Logo;
      mode?: NavDesktopType.Config.Mode;
    };
  };

  namespace Config {
    type Logo = React.ReactNode;
    type Mode = 'panel' | 'drop';
  }

  type Data = {
    key: string;
    title: string;
    position: Extract<Placement2Axis, 'top' | 'bottom'>;
    items: MenuType.Data.Item[];
  };
}

export const NavMobileTop = ({
  handleEvent: { navigation } = { navigation: undefined },
  data = [],
  className = '',
}: NavMobileTopType.Props) => {
  const { pathname } = useLocation();
  const { buildParamsUrl } = useMyUrl();
  const navigationMatches = useMatches();
  const { t } = useTranslation();

  const dataFormatted = useMemo(
    () =>
      data.reduce(
        (
          acc: NavDesktopType.Data[],
          { items, ...rest }: NavDesktopType.Data,
        ) => {
          const path: Record<string, any> = { ...rest, items };

          if (!items.length)
            path.items = items.map(({ key = null, ...rest }) => ({
              ...rest,
            }));

          return [...acc, path as NavDesktopType.Data];
        },
        [],
      ),
    [data],
  );

  //! Liste des path utilisables
  const registerPathIndex: Record<
    string,
    Array<
      Pick<MenuType.Data.Item, 'key' | 'params'> & {
        parent?: string;
      }
    >
  > = useMemo(
    () =>
      dataFormatted
        .flatMap((item) => item.items)
        .flatMap((item: MenuType.Data.Item) =>
          Static.formatItem({
            key: item.key,
            items: item.items,
            params: item.params,
          }),
        )
        .reduce(
          (acc, { key, ...rest }) => ({ ...acc, [key]: { ...rest, key } }),
          {},
        ),
    [dataFormatted],
  );

  const selectedPath = useMemo((): Pick<
    MenuType.Data.Item,
    'key' | 'params'
  > & {
    parent?: string;
    deep: boolean;
  } => {
    const selectedSitemapItem: any = navigationMatches
      .reverse()
      .find(({ pathname: itemPath, handle }: any) => {
        return (
          pathname === itemPath ||
          (handle && handle?.navKey && handle.navKey === pathname)
        );
      });

    const keyToUse =
      selectedSitemapItem?.handle?.navKey || selectedSitemapItem?.pathname;

    const itemToUse: any = registerPathIndex?.[keyToUse];

    if (!itemToUse) {
      return { key: '/', parent: '/', deep: false };
    } else {
      return { ...itemToUse, deep: pathname === itemToUse.key };
    }
  }, [dataFormatted, pathname]);

  const handleNavigate = useCallback(
    (key: string) => {
      const itemToUse: any = registerPathIndex[key];
      const path =
        key + (itemToUse?.params ? '?' + buildParamsUrl(itemToUse.params) : '');
      navigation?.(path);
    },
    [dataFormatted],
  );

  const topPath = useMemo(
    () => data.filter(({ position }) => position === 'top'),
    [dataFormatted],
  );

  const itemsFormatted = useMemo(() => {
    return data.reduce(
      (acc: MenuType.Data.Item[], element: NavDesktopType.Data) => [
        ...acc,
        ...element?.items,
      ],
      [],
    );
  }, [data]);

  const navDropAll = useCallback(
    (elements: NavDesktopType.Data[], mode: MenuType.Config.Mode) => {
      return (
        <>
          {elements.map(({ title, items }, index) => {
            const selectedValue = [selectedPath.key];

            return (
              <Menu
                key={index}
                config={{ mode: mode }}
                handleEvent={{ navigation: handleNavigate }}
                data={{
                  title,
                  items,
                  selected: { value: selectedValue },
                }}
              />
            );
          })}
        </>
      );
    },
    [selectedPath, itemsFormatted],
  );

  const navTitle = useMemo((): string => {
    if (!data) return '';

    for (let i = 0; i < itemsFormatted.length; i++) {
      const tryFindSubTitle = itemsFormatted[i]?.items?.find(
        (item) => item.key === selectedPath.key,
      );

      if (
        tryFindSubTitle &&
        itemsFormatted[i]?.label &&
        tryFindSubTitle?.label
      ) {
        return `${itemsFormatted[i].label} - ${tryFindSubTitle.label}`;
      }
      const tryFindTitle = itemsFormatted[i]?.key === selectedPath.key;

      if (tryFindTitle) {
        return `${itemsFormatted[i].label}`;
      }
    }

    return '';
  }, [selectedPath, data]);

  return (
    <nav className={`navmobile  ${className}`}>
      <div className="navmobile__part">
        <Drawer
          config={{ placement: 'bottom', size: 'xlarge' }}
          data={
            <div className="navmobile__part__menu">
              <div className="navmobile__part__menu__text">
                <Write
                  className="navmobile__part__menu__text__title"
                  data={{ item: t('watermelon-navigation') }}
                  config={{ mode: 'title-small' }}
                ></Write>
              </div>
              {navDropAll(topPath, 'horizontal')}
            </div>
          }
        >
          <Icon
            config={{ type: 'faBarsSolid', size: 'large', color: 'primary' }}
          ></Icon>
        </Drawer>
      </div>
      <div className="navmobile__part">
        <Write
          className="navmobile__part__title"
          data={{ item: navTitle }}
          config={{
            mode: 'title-small',
            color: 'primary',
          }}
        ></Write>
      </div>
      <div className="navmobile__part"></div>
    </nav>
  );
};

export declare namespace NavInlineType {
  type Props = {
    className?: string;
    handleEvent?: { navigation?: (value: string) => void };
    data?: NavInlineType.Data[];
    config?: {
      selected?: string;
      extra?: React.ReactNode;
    };
  };

  type Data = {
    label: string;
    key: string;
    icon?: React.ReactNode;
  };
}

export const NavInline = ({
  handleEvent: { navigation } = { navigation: undefined },
  data = [],
  className = '',
  config: { selected = '', extra = undefined } = {},
}: NavInlineType.Props) => {
  return (
    <nav className={`navinline ${className}`}>
      <div className="navinline__contain">
        {data.map(({ key, icon, label }) => {
          return (
            <div
              key={key}
              onClick={() => {
                navigation && navigation(key);
              }}
              className={`${
                selected === key ? 'selected' : ''
              } navinline__contain__item`}
            >
              {icon}
              <span className="navinline__contain__item__text">{label}</span>
            </div>
          );
        })}
      </div>
      {extra && <div className="navinline__extra">{extra}</div>}
    </nav>
  );
};
