import { gql } from '@apollo/client';

export type ClientResponse = {
  _id: string;
  slug: string;
  siret: string | null;
};

export type ClientParams = {
  clientId: string;
};

export const clientGql = gql`
  query client($clientId: String!) {
    client(clientId: $clientId) {
      _id
      slug
      siret
    }
  }
`;
