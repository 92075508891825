import { gql } from '@apollo/client';

export type CreateClientResponse = {
  client: {
    _id: string;
  };
};

export type CreateClientParams = {
  input: {
    name: string;
    slug: string;
    ticketsHub: string;
    code: string;
    defaultContractTag: string;
    siret: string;
    timezone: string;
    locale: string;
    defaultCurrency: string;
    maximumDuration: number;
    maximumAmount: number;
    translation: Array<{
      lang: string;
      name: string;
      description: string | null;
      pricing: string | null;
      opening: string | null;
      hint: string | null;
    }>;
  };
};

export const createClientGql = gql`
  mutation createClient($input: CreateClientDto!) {
    createClient(input: $input) {
      client {
        _id
      }
    }
  }
`;
