import { wizardService } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useSelector } from '@xstate/react';
import { useEffect } from 'react';
import { createSiteService } from './createSite.machine';
import { SiteStep } from './steps/site.step';

export type CreateSiteWizardContext = any;

export const CreateSiteWizard = () => {
  const result = useSelector(
    createSiteService,
    ({ context }: any) => context.result,
  );

  useEffect(() => {
    if (result) {
      console.log('RESULT', { result });
    }
  }, [result]);

  return (
    <Wizard<CreateSiteWizardContext>
      config={{
        title: '',
        withValidation: {
          state: result,
          success: 'Ca marche nickel !',
          error: 'Ca marche pas !',
        },
      }}
      data={{
        defaultContext: undefined,
      }}
      handleEvent={{
        done: async (payload: any) => {
          createSiteService.send('CREATE_SITE', {
            payload,
          });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item config={{ title: 'Site', component: SiteStep }} />
    </Wizard>
  );
};
