export const peripheralsEntity = `
peripherals {
    terminalId
    mfg
    mdl
    sn
    present
    kind
    logDate
    hwvers
    swvers
    bootvers
    createdAt
  }`;
